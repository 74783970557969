/**
 * Import React libraries.
 */
import React from "react";

/**
 * Import third-party libraries.
 */
import {Banner, BREAKPOINTS, COLORS, Size} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {ErrorToastOptions, SuccessToastOptions} from "../../../constants";
import Api from "../../../utils/api";
import {useMediaQuery} from "react-responsive";

import {ConfirmationModal} from "../../../components/modals/ConfirmationModal";
import {useToastContext} from "../../../userContext";
import moment from "moment";
import {Organization, OrganizationService, PaymentType, Service, Subscription} from "../../../types";

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmitted?: () => void;

  organization: Organization | undefined;
  subscription: Subscription | undefined;
}

const CancelSubscriptionModal = ({
                                   visible,
                                   onClose,
                                   onSubmitted,
                                   organization,
                                   subscription
                                 }: Props) => {
  const {t} = useTranslation("OrganizationServices");
  const {addToast} = useToastContext();


  const cancelSubscription = (): Promise<any> => {
    return Api.CancelSubscription(organization!.id, subscription!.id)
      .then(() => addToast(t("Service license successfully canceled"), SuccessToastOptions))
      .then(() => onSubmitted && onSubmitted())
      .catch(() => addToast(t("There was a problem cancelling subscription"), ErrorToastOptions))
      .finally(() => onClose());
  };


  return (
    <ConfirmationModal
      visible={visible}
      cancel={onClose}
      confirm={cancelSubscription}
      modalSize={Size.Large}
      modalTitle={t("Cancel subscription")}
      cancelTitle={t("Close")}
      confirmTitle={t("Confirm action")}
      banner={{type: 'critical', text: t('This action cannot be undone.')}}
      confirmButtonVariant="critical">
      <p>
        Are you sure you want to cancel this subscription?
        <br/>
        <br/>
        The customer will continue to have access up until the end of the current term.
      </p>
    </ConfirmationModal>
  );
};

export default CancelSubscriptionModal;


