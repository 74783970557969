import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RadioButton, DatepickerField, InputLabel, BREAKPOINTS, ModalDialog, Size } from '@laerdal/life-react-components';
import { ScenarioAccessDetails } from './ScenarioCloudSettings';
import { useMediaQuery } from 'react-responsive';

interface ScenarioAccessSelectorModal {
  close: () => void;
  selectAccess: (accessDetails: ScenarioAccessDetails) => Promise<void>;
  initialAccessDetails?: ScenarioAccessDetails;
  label: string;
}

const AccessEndDateContainer = styled.div`
  margin: 10px;
`;

export const ScenarioAccessSelectorModal = ({ close, selectAccess, label, initialAccessDetails }: ScenarioAccessSelectorModal) => {
  const { t } = useTranslation('Settings');
  const [loading, setLoading] = useState<boolean>(false);
  const [accessDetails, setAccessDetails] = useState<ScenarioAccessDetails | undefined>(initialAccessDetails);
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace('@media ', '') });
  const closeModal = () => {
    if (loading) return;
    setAccessDetails(undefined);
    close();
  };

  const handleSelectAccess = async () => {
    setLoading(true);
    await selectAccess(accessDetails as ScenarioAccessDetails);
  };
  const setAsUnlimited = () => setAccessDetails({ unlimited: true, validTo: undefined });
  const setAsLimited = () => setAccessDetails({ unlimited: false, validTo: undefined });
  const handleAccessEndDateChange = (value: Date) => setAccessDetails({ unlimited: false, validTo: value });

  return (
    <ModalDialog
      isModalOpen={true}
      submitAction={() => {}}
      closeAction={closeModal}
      title={label}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={
        [
          {
            id: 'cancel',
            variant: 'tertiary',
            text: t('Close'),
            action: closeModal,
          },
          {
            id: 'next',
            action: handleSelectAccess,
            disabled: !Boolean(accessDetails && (accessDetails.unlimited || accessDetails.validTo)) || loading,
            variant: 'primary',
            text: t('Next'),
            loading: loading,
          },
        ] as any
      }>
      <p>{t('Please select access duration')}</p>

      <RadioButton id="UnlimitedAccess" label="Unlimited access" selected={Boolean(accessDetails && accessDetails.unlimited)} select={setAsUnlimited} />
      <RadioButton id="LimitedAccess" label="Timed access" selected={Boolean(accessDetails && !accessDetails.unlimited)} select={setAsLimited} />

      {accessDetails && !accessDetails.unlimited && (
        <AccessEndDateContainer>
          <InputLabel inputId="DemoDatepicker" text={t('Access End Date')} />
          <DatepickerField yearPicker id="AccessEndDate" value={accessDetails.validTo} onChange={handleAccessEndDateChange} />{' '}
        </AccessEndDateContainer>
      )}
    </ModalDialog>
  );
};
