import React, { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import styled from 'styled-components';
import {
  BackButton,
  BasicDropdown,
  Button,
  COLORS,
  Checkbox,
  ComponentM,
  ComponentTextStyle,
  DropdownItem,
  InputLabel,
  LoadingIndicator,
  ModalContainer,
  PageWidth,
  Size,
  SystemIcons,
  ToastActionType,
  ToastColor,
  ToastPosition,
} from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Organization, OrganizationMergeRequest, OrganizationMergeValidationResponse } from '../../types';
import OrganizationMergeDetails from './Components/OrganizationMergeDetails';
import Api from '../../utils/api';
import OrganizationMergeConfirmModal from './Components/OrganizationMergeConfirmModal';
import { BuildMergeRequestObject, FormatMergeErrors, OrganizationMergeInstanceLimitError } from './OrganizationMergeHelper';
import OrganizationMergeResolveModal from './Components/OrganizationMergeResolveModal';
import { useToastContext, useUserContext } from '../../userContext';
import { EditPermissionsModal } from '../../components/modals';
import { memberFullAccessPermission } from '../../constants';

const Wrapper = styled(PageWidth)`
  margin-top: 16px;
  margin-bottom: 40px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const SubHeader = styled.h6`
  margin-bottom: 8px;
`;

const OrganizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const OrganizationsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const MergeIconWrapper = styled.div`
  padding-top: 48px;
  align-self: center;
  color: ${COLORS.neutral_600};
`;

const MergeOptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OrganizationMergePage = () => {
  const { t } = useTranslation('Organization');
  const { addToast } = useToastContext();

  const navigate = useNavigate();

  const [sourceOrganization, setSourceOrganization] = React.useState<Organization>();
  const [targetOrganization, setTargetOrganization] = React.useState<Organization>();
  const [mergeErrors, setMergeErrors] = React.useState<OrganizationMergeInstanceLimitError[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [keepSourceOwners, setKeepSourceOwners] = React.useState<boolean>(true);
  const [sourceOwnerPermissions, setSourceOwnerPermissions] = React.useState<string[]>([]);
  const [editSourceOwnerPermissions, setEditSourceOwnerPermissions] = React.useState(false);

  const [isValid, setIsValid] = React.useState(false);
  const { memberPermissions } = useUserContext();

  const [query, setQuery] = useQueryParams({ source: StringParam, target: StringParam });

  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  useEffect(() => {
    const abort = new AbortController();
    const source = query.source;
    const target = query.target;

    setLoading(true);

    const requests = [];
    if (source && sourceOrganization?.id !== source) {
      requests.push(Api.GetOrganization(source, abort.signal).then(setSourceOrganization));
    }

    if (target && targetOrganization?.id !== target) {
      requests.push(Api.GetOrganization(target, abort.signal).then(setTargetOrganization));
    }



    Promise.all(requests).then(() => setLoading(false));

    return () => abort.abort();
  }, [query]);

  useEffect(() => {
    if (sourceOrganization && targetOrganization) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [sourceOrganization, targetOrganization]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('loader');
      if (element) {
        element.style.border = 'initial';
        element.style.background = 'transparent';
        element.style.boxShadow = 'unset';
      }
    }, 0);
  }, [loading]);

  const setOrganization = (id: string | undefined, setOrganization: (org: Organization | undefined) => void, param: 'source' | 'target') => {
    if (!!id) {
      setLoading(true);
      Api.GetOrganization(id)
        .then(setOrganization)
        .finally(() => {
          setLoading(false);
          setQuery({ [param]: id });
        });
    } else {
      setOrganization(undefined);
      setQuery({ [param]: undefined });
    }
  };

  const notifyError = (msg: string) => {
    addToast(msg, {
      color: ToastColor.RED,
      showCloseButton: true,
      autoClose: true,
      position: ToastPosition.TOPMIDDLE,
    });
  };

  const load = () => {
    const requests = [];

    requests.push(
      Api.GetOrganization(sourceOrganization!.id!)
        .then((source) => {
          setSourceOrganization(source);
          return Promise.resolve(source);
        })
        .then((org: Organization) => {
          const serviceRequests = org.services?.map((service) =>
            Api.GetOrganizationService(org.id, service.id!).then((os) => {
              service.invitations = os.invitations;
              service.members = os.members;
              service.subscription = os.subscription;
            }),
          );

          return Promise.all(serviceRequests ?? []).then(() => Promise.resolve(org));
        }),
    );

    requests.push(
      Api.GetOrganization(targetOrganization!.id!)
        .then((target) => {
          setTargetOrganization(target);
          return Promise.resolve(target);
        })
        .then((org: Organization) => {
          const serviceRequests = org.services?.map((service) =>
            Api.GetOrganizationService(org.id, service.id!).then((os) => {
              service.invitations = os.invitations;
              service.members = os.members;
              service.subscription = os.subscription;
            }),
          );

          return Promise.all(serviceRequests ?? []).then(() => Promise.resolve(org));
        }),
    );

    return Promise.all(requests);
  };

  const validate = (source: Organization, target: Organization) => {
    const requests = [];
    let orgDefaultPermissions: string[] = [];
    requests.push(
      Api.CheckOrganizationCreatedFromSalesforce(target!.customerNo!)
        .then((inSF) => {
          !inSF && notifyError(t('Cannot merge - target organization not found in Salesforce'));
          return !inSF ? Promise.reject() : Promise.resolve();
        })
        .catch(() => {
          notifyError(t('Cannot merge - target organization not found in Salesforce'));
          return Promise.reject();
        }),
    );

    requests.push(
      Api.GetOrganizationMemberDefaultPermissions(target.id)
        .then((permissions) => {
          orgDefaultPermissions = permissions;
          return Promise.resolve();
        })
        .catch(() => {
          notifyError(t('Cannot merge - cannot load default permissions for organization'));
          return Promise.reject();
        }),
    );

    return Promise.all(requests).then(() => {
      const request = BuildMergeRequestObject(
        source,
        target,
        keepSourceOwners ? [memberFullAccessPermission] : (sourceOwnerPermissions.length > 0 ? sourceOwnerPermissions : orgDefaultPermissions),
        orgDefaultPermissions,
      );
      return Api.ValidateOrganizationMerge(request)
        .then((response) => {
          if (!response.success) {
            const message = response.errors.map((a) => a.message).join('; ');
            notifyError(`Cannot merge - ${message}`);
            return Promise.reject();
          }

          return Promise.resolve({ validation: response, request: request });
        })
        .catch(() => {
          notifyError(t('Cannot merge - there was a temporary problem'));
          return Promise.reject();
        });
    });
  };

  const merge = (request: OrganizationMergeRequest) => {
    return Api.MergeOrganizations(request)
      .then((result) => {
        navigate(`/organization`);
        addToast(t('Merge successful'), {
          color: ToastColor.GREEN,
          autoClose: true,
          delay: 5000,
          position: ToastPosition.TOPMIDDLE,
          action: [
            {
              label: t('View organization'),
              handler: () => navigate(`/organization/${request.targetId}`),
              type: ToastActionType.PRIMARY,
            },
          ],
        });
        return Promise.resolve(result);
      })
      .catch(() => {
        notifyError(t('Cannot merge - there was a temporary problem'));
        return Promise.reject();
      });
  };

  const resolve = (source: Organization, target: Organization, validation: OrganizationMergeValidationResponse, request: OrganizationMergeRequest) => {
    setMergeErrors(FormatMergeErrors(validation, request, source, target));

    return Promise.resolve(validation);
  };

  const startMerge = () => {
    setLoading(true);
    load()
      .then(([source, target]) =>
        validate(source, target).then(({ validation, request }) => (!validation.errors?.length ? merge(request) : resolve(source, target, validation, request))),
      )
      .catch((e) => {
        setShowConfirmModal(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <OrganizationMergeConfirmModal isOpen={showConfirmModal} sourceOrganization={sourceOrganization} onCancel={() => setShowConfirmModal(false)} onConfirm={startMerge} />
      <OrganizationMergeResolveModal isOpen={!!mergeErrors?.length} onClose={() => setMergeErrors([])} targetOrganization={targetOrganization} errors={mergeErrors} />
      <ModalContainer id={'loader'} showModal={loading} closeModal={() => {}}>
        <LoadingIndicator color={COLORS.white} />
      </ModalContainer>
      <Wrapper $useMaxWidth={true} $maxWidth={900}>
        <BackButton size={Size.Small} onClick={() => navigate('/organization')}>
          {t('Back to Organizations')}
        </BackButton>
        <ContentContainer>
          <h3>{t('Merge organizations')}</h3>
          <OrganizationsSection>
            <OrganizationWrapper>
              <SubHeader>{t('1. Select source')}</SubHeader>
              <OrganizationMergeDetails
                setOrganization={(id) => setOrganization(id, setSourceOrganization, 'source')}
                organization={sourceOrganization}
                disabled={!!targetOrganization ? [targetOrganization.id] : []}
                type={t('source')}
              />
            </OrganizationWrapper>

            <MergeIconWrapper>
              <SystemIcons.ArrowLineRight />
            </MergeIconWrapper>

            <OrganizationWrapper>
              <SubHeader>{t('2. Select target')}</SubHeader>
              <OrganizationMergeDetails
                setOrganization={(id) => setOrganization(id, setTargetOrganization, 'target')}
                organization={targetOrganization}
                disabled={!!sourceOrganization ? [sourceOrganization.id] : []}
                type={t('target')}
              />
            </OrganizationWrapper>
          </OrganizationsSection>
          <MergeOptionsSection>
            <SubHeader>{t('3. Merge options')}</SubHeader>
            <ComponentM textStyle={ComponentTextStyle.Bold}>{t('Organization owners')}</ComponentM>
            <p>
              {t('Owners are considered to be key contact people who can manage user access, edit all subscriptions, and make purchases on the Webshop for their organization.')}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'space-between', justifyContent: 'space-between' }}>
              <div>
                <Checkbox selected={keepSourceOwners} label="Keep all SOURCE admins and set as TARGET admins" select={() => setKeepSourceOwners(!keepSourceOwners)} />
              </div>
              <Button disabled={keepSourceOwners} onClick={() => setEditSourceOwnerPermissions(true)}>
                Customize permissions
              </Button>
            </div>
            {editSourceOwnerPermissions && <EditPermissionsModal
              isModalOpen={editSourceOwnerPermissions}
              saveUpdatedPermissions={(newPermissions: string[]) => {
                setSourceOwnerPermissions(newPermissions);
                return Promise.resolve();
              }}
              title='Customize permissions'
              headerLabel='Select which permissions SOURCE admins should have when they are migrated to TARGET organization'
              allPermissions={memberPermissions}
              closeModal={() => setEditSourceOwnerPermissions(false)}
              currentPermissions={sourceOwnerPermissions}
            /> }
          </MergeOptionsSection>
          <ActionsWrapper>
            <Button variant={'primary'} disabled={!isValid} size={Size.Large} onClick={() => setShowConfirmModal(true)}>
              {t('Merge organizations')}
            </Button>
            <Button variant={'tertiary'} size={Size.Large} onClick={() => navigate('/organization')}>
              {t('Cancel')}
            </Button>
          </ActionsWrapper>
        </ContentContainer>
      </Wrapper>
    </>
  );
};

export default OrganizationMergePage;
