/**
 * Import React Libraries.
 */
import React, {useState, useEffect, useMemo} from 'react';
import {useNavigate, useLocation} from 'react-router';

/**
 * Import third-party libraries.
 */
import {
  ChoiceChips,
  COLORS,
  SearchBar,
  Table,
  ToggleSwitch
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

/**
 * Import custom types.
 */
import {Service, ServiceOrganization, ServiceOrganizationRecordsWrapper, Subscription} from '../../../types';
import {TableColumn, TablePagination, TableSortProps} from '@laerdal/life-react-components';

/**
 * Import custom components.
 */
import OrgServiceQuickDetailsModal from './modals/OrgServiceQuickDetailsModal';

/**
 * Import custom functions.
 */
import Api from '../../../utils/api';
import {formatDateOnly} from '../../../utils/functions';
import * as constants from '../../../constants';
import {TableSortingDirection} from '@laerdal/life-react-components/dist/Table/TableTypes';
import {ChipValue} from '@laerdal/life-react-components/dist/Chips/ChipTypes';
import moment from 'moment';
import { useToastContext } from '../../../userContext';

/**
 * Add custom styles.
 */
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  table .right  {
    text-align: right;
  }
`;

const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
`;
const SearchBarContainer = styled.div`
    width: 100%;
`;

const ChipsContainer = styled.div`
    display: flex;
    flex: 1;
    & > div {
      display: flex;
      gap: 8px;
    }
`;

const ToggleContainer = styled.div`
`;

/**
 * Add custom types.
 */
interface ServiceOrganizationTableRow {
  id: string;
  name: string;
  planType: string;
  tier: string;
  activeUsers: number;
  licenseExpiry: string;
  hasLicence: boolean;
}

interface ServiceUsersPageProps {
  service: Service;
}

const ServiceOrganizationsPage = ({service}: ServiceUsersPageProps) => {
  const {addToast} = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // Globally used states within the page
  const [pagination, setPagination] = useState<TablePagination>({
    from: 0,
    to: 0,
    currentPage: 1,
    total: 0,
    rowsPerPage: 10,
  });
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<number>(0);
  const [sortProps, setSortProps] = useState<TableSortProps | undefined>();
  const [sortBy, setSortBy] = useState<number>(0);
  const [query, setQuery] = useState<string>(new URLSearchParams(location.search).get('query') || '');
  const [loading, setLoading] = useState<boolean>(true);
  const {t} = useTranslation('Services');
  const [columns, setColumns] = useState<TableColumn[]>([
    {
      key: 'name',
      name: t('Organization name'),
      sortable: true,
    },
    {
      key: 'planType',
      name: t('Plan type'),
      sortable: true,
      colorFn: (row: ServiceOrganizationTableRow, key) => {
        if (row.hasLicence) {
          return COLORS.black;
        } else {
          return COLORS.warning;
        }
      },
    },
    {
      key: 'tier',
      name: t('Tier'),
      sortable: true,
    },
    {
      key: 'activeUsers',
      name: t('Active users'),
      sortable: true,
      justify: 'right',
    },
    {
      key: 'licenseExpiry',
      name: t('License expiry'),
      sortable: true,
    },
  ]);

  const [rows, setRows] = useState<any[]>([]);

  const [filterInternal, setFilterInternal] = useState<boolean>(false);

  const [expirationFilter, setExpirationFilter] = useState<string>('all');
  const expirationChips = useMemo(() => ([
    {
      value: 'all',
      label: t('All licenses'),
    }, {
      value: 'active',
      label: t('Active'),
    }, {
      value: 'expiring',
      label: t('Expiring'),
    }, {
      value: 'expired',
      label: t('Expired'),
    },
  ] as ChipValue[]), []);

  /**
   * Sets up search parameters for the page.
   */
  useEffect(() => {
    // Let's setup search params
    setupSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Does all required setup and retrieves initial rows.
   */
  const setupSearchParams = (): void => {
    // Let's get search params
    const searchParams = new URLSearchParams(location.search);

    // Let's update query if we have it in the url
    if (searchParams.get('query')) {
      setQuery(searchParams.get('query')!);
    }

    // Let's update rows per page if we have it in the url
    if (searchParams.get('rowsPerPage')) {
      setPagination({...pagination, rowsPerPage: parseInt(searchParams.get('rowsPerPage')!)});
    }

    // Let's update sort direction and sort by
    if (searchParams.get('sortDirection') && searchParams.get('sortBy')) {
      setSortDirection(parseInt(searchParams.get('sortDirection')!));
      setSortBy(parseInt(searchParams.get('sortBy')!));
    }
    
    if (searchParams.get('filterInternal')) {
      setFilterInternal(searchParams.get('filterInternal') === 'true');
    }
    
    if (searchParams.get('expirationFilter')) {
      setExpirationFilter(searchParams.get('expirationFilter')!);
    }

    // Retrieve the rows
    getNewRows(
      searchParams.get('query') ? searchParams.get('query')! : query,
      searchParams.get('from') ? parseInt(searchParams.get('from')!) : pagination.from,
      searchParams.get('currentPage') ? parseInt(searchParams.get('currentPage')!) : pagination.currentPage,
      searchParams.get('rowsPerPage') ? parseInt(searchParams.get('rowsPerPage')!) : pagination?.rowsPerPage,
      searchParams.get('sortBy') ? parseInt(searchParams.get('sortBy')!) : sortBy,
      searchParams.get('sortDirection') ? parseInt(searchParams.get('sortDirection')!) : sortDirection,
      searchParams.get('filterInternal') ? searchParams.get('filterInternal') === 'true' : filterInternal,
      searchParams.get('expirationFilter') ? searchParams.get('expirationFilter')! : expirationFilter,
    );
  };

  /**
   * Does all required pre-requisites and updates the search parameters for the context.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param newSortBy? - An enum value indicating the sort by column.
   */
  const updateSearchParams = (
    search: string,
    from: number,
    page: number,
    newRowsPerPage: number,
    newSortBy?: number,
    newSortDirection?: number,
    filterInternal?: boolean, 
    expirationFilter?: string,
  ): void => {
    const params = new URLSearchParams();

    // Let's add query if it is set
    search ? params.append('query', search) : params.delete('query');

    // Let's add from
    from ? params.append('from', from.toString()) : params.delete('from');

    // Let's add current page
    page ? params.append('currentPage', page.toString()) : params.delete('currentPage');

    // Let's add rows per page
    newRowsPerPage ? params.append('rowsPerPage', newRowsPerPage.toString()) : params.delete('rowsPerPage');

    // Let's add sort by
    newSortBy !== undefined ? params.append('sortBy', newSortBy.toString()) : params.delete('sortBy');

    // Let's add rows per page
    newSortDirection !== undefined ? params.append('sortDirection', newSortDirection.toString()) : params.delete(
      'sortDirection');
    
    filterInternal ? params.append('filterInternal', filterInternal.toString()) : params.delete('filterInternal');
    
    expirationFilter ? params.append('expirationFilter', expirationFilter) : params.delete('expirationFilter');

    // Let's append context
    navigate({pathname: '../organizations', search: params.toString()});
  };

  /**
   * Retrieves new rows from the API.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   */
  const getNewRows = (
    search: string,
    from: number,
    page: number,
    newRowsPerPage?: number,
    newSortBy?: number,
    newSortDirection?: number,
    newFilterInternal?: boolean,
    newExpirationFilter?: string,
  ) => {
    setLoading(true);
    // Assign temporary rows per page based on the global variable
    const tmpRowsPerPage = newRowsPerPage ? newRowsPerPage : pagination?.rowsPerPage;

    // Assign temp sort by and sort direction
    const tmpSortBy = newSortBy !== undefined ? newSortBy : sortBy;
    const tmpSortDirection = newSortDirection !== undefined ? newSortDirection : sortDirection;
    const tmpFilterInternal = newFilterInternal !== undefined ? newFilterInternal : filterInternal;
    const tmpExpirationFilter = newExpirationFilter !== undefined ? newExpirationFilter : expirationFilter;

    // Let's update search parameters
    updateSearchParams(search, from, page, tmpRowsPerPage, tmpSortBy, tmpSortDirection, tmpFilterInternal, tmpExpirationFilter);
    
    let expirationFrom: Date | undefined = undefined;
    let expirationTo: Date | undefined = undefined;
    switch (tmpExpirationFilter) {
      case 'active':
        expirationFrom = moment().add(90, 'day').endOf('day').toDate();
        break;
      case 'expiring':
        expirationFrom = moment().startOf('day').toDate();
        expirationTo = moment().add(90, 'day').endOf('day').toDate();
        break;
      case 'expired':
        expirationTo = moment().startOf('day').toDate();
        break;
    }

    Api.FindServiceOrgs(
        service.id,
        search,
        newFilterInternal,
        expirationFrom,
        expirationTo,
        from - 1 >= 0 ? from - 1 : from,
        tmpRowsPerPage,
        tmpSortBy,
        tmpSortDirection,
      )
      .then((organizations) => assignOrganizations(
        organizations,
        page * tmpRowsPerPage - tmpRowsPerPage + 1,
        page * tmpRowsPerPage,
        page,
        tmpRowsPerPage,
      ))
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const formatUserCount = (sub: Subscription) => {
    if (sub) {
      let userCountString = '';
      if (sub.tier?.maxSubscriptionInstances) {
        userCountString = `(${sub.tier?.maxSubscriptionInstances} ${t('users')})`;
      } else if (process.env.REACT_APP_SCENARIO_CLOUD_SERVICE_ID?.toLowerCase() === service?.id.toLowerCase()) {
        userCountString = `(16+ ${t('users')})`;
      }
      return userCountString;
    } else {
      return '';
    }
  };

  /**
   * Assign retrieved rows and update pagination.
   * @param organizationRecordWrapper - Organizations Record Wrapper containing rows and total row count.
   * @param from - From which row data was retrieved.
   * @param to - Till which row data was retrieved.
   * @param page - Current page in the table.
   * @param rowsPerPage - New rows per page that needs to be assigned.
   */
  const assignOrganizations = (
    organizationRecordWrapper: ServiceOrganizationRecordsWrapper,
    from: number,
    to: number,
    page: number,
    rowsPerPage: number,
  ) => {
    // Let's pull out the new rows data
    const newRows = organizationRecordWrapper.records.map((record: ServiceOrganization) => {
      //no subscription
      if (!record.activeServiceSubscription) {
        return {
          id: record.id,
          name: record.name,
          planType: t('NO LICENCE'),
          tier: '',
          activeUsers: '',
          licenseExpiry: '',
          hasLicence: false,
        };
      } else {
        return {
          id: record.id,
          name: record.name,
          planType: record.activeServiceSubscription?.plan?.name,
          tier: `${record.activeServiceSubscription?.tier?.name || ''} ${formatUserCount(record.activeServiceSubscription)}`,
          activeUsers: record.activeUsers,
          licenseExpiry: formatDateOnly(record.activeServiceSubscription?.expirationDate),
          hasLicence: true,
        };
      }
    });

    // Let's update pagination
    setPagination({
      from: from < 0 ? 0 : from,
      to: to > organizationRecordWrapper.totalCount ? organizationRecordWrapper.totalCount : to,
      currentPage: page,
      total: organizationRecordWrapper.totalCount,
      rowsPerPage,
    });

    // Let's assign new rows
    setRows(newRows);
  };

  /**
   * Navigate to the next page.
   */
  const onNextPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage < pagination?.total!) {
      // Let's get new rows
      getNewRows(query, pagination?.from! + pagination?.rowsPerPage, pagination?.currentPage! + 1);
    }
  };

  /**
   * Navigate to the previous page.
   */
  const onPreviousPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage - pagination?.rowsPerPage >= 0) {
      // Let's get new rows
      getNewRows(query, pagination?.from! - pagination?.rowsPerPage, pagination?.currentPage! - 1);
    }
  };

  /**
   * Updates rows per page and retrieves new data.
   * @param newRowsPerPage - New rows per page value set in the table.
   */
  const onRowsPerPageChange = (newRowsPerPage: number) => {
    // Let's retrieve fresh data
    getNewRows(query, 1, 1, newRowsPerPage);
  };

  /**
   * Opens the quick details modal
   * @param row - Row which was clicked in the table.
   */
  const onRowClick = (row: ServiceOrganizationTableRow) => {
    setSelectedOrganizationId(row.id);
  };

  /**
   * Searches the table based on the entered query.
   * @param searchQuery - Search query based on which to search for data.
   */
  const onSearchRows = (searchQuery: string) => {
    // Let's update the query
    setQuery(searchQuery);

    // Let's retrieve updated data
    getNewRows(searchQuery, 1, 1, pagination?.rowsPerPage);
  };
  
  const onExpirationFilterChange = (newExpirationFilter: string) => {
    setExpirationFilter(newExpirationFilter);
    
    getNewRows(query, 1, 1, pagination?.rowsPerPage, sortBy, sortDirection, filterInternal, newExpirationFilter);
  }
  
  const onFilterInternalChange = (value: boolean) => {
    setFilterInternal(value);
    
    getNewRows(query, 1, 1, pagination?.rowsPerPage, sortBy, sortDirection, value, expirationFilter);
  }

  /**
   * Applies sorting for the table.
   * @param key - Column key to which sorting should be applied.
   * @param direction - Direction in which to sort data.
   */
  const onApplySorting = (key: string, direction?: TableSortingDirection): void => {
    let sortBy = 0;
    let sortDirection = 0;

    if (!!key && !!direction) {
      // Let's retrieve sort by and sort direction
      sortBy = columns.findIndex((column: TableColumn) => column.key === key);
      sortDirection = direction === 'asc' ? 0 : 1;
    }

    // Set sort by.
    setSortBy(sortBy);

    // Set sort direction
    setSortDirection(sortDirection);

    // Let's search
    getNewRows(query, pagination?.from!, pagination?.currentPage, pagination?.rowsPerPage, sortBy, sortDirection);
  };

  return (
    <Wrapper>
      <OrgServiceQuickDetailsModal
        isModalOpen={selectedOrganizationId !== ''}
        closeModal={() => setSelectedOrganizationId('')}
        organizationId={selectedOrganizationId}
        serviceId={service?.id || ''}
      />
      <h6>{t('Organizations using this service')}</h6>

      <FilterContainer>
        <SearchBarContainer>
          <SearchBar id={'query'}
                     placeholder={t('Search by organization name')}
                     setSearchTerm={(q: string) => onSearchRows(q)}
                     enterSearch={() => onSearchRows(query)}
                     removeSearch={() => onSearchRows('')}
                     searchTerm={query}/>
        </SearchBarContainer>
        <ChipsContainer>
          <ChoiceChips values={expirationChips}
                       selected={expirationFilter}
                       onClick={(value) => onExpirationFilterChange(value.value)}/>
        </ChipsContainer>
        <ToggleContainer>
          <ToggleSwitch id={'internal'}
                        onToggle={onFilterInternalChange}
                        selected={filterInternal}
                        label={t('Show only internal organizations')}/>
        </ToggleContainer>
      </FilterContainer>

      <Table
        columns={columns}
        rows={rows}
        sortProps={sortProps}
        remoteOperations={true}
        pagination={pagination}
        onRowsPerPageChange={(newRowsPerPage: number) => onRowsPerPageChange(newRowsPerPage)}
        onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => onApplySorting(key, direction)}
        onNextPageClick={() => onNextPage()}
        onPreviousPageClick={() => onPreviousPage()}
        selectable={true}
        onSelectionChange={(row: ServiceOrganizationTableRow) => onRowClick(row)}
        showLoadingIndicator={loading}
      />
    </Wrapper>
  );
};

export default ServiceOrganizationsPage;
