import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
  BasicDropdown, COLORS,
  ComponentLStyling,
  ComponentTextStyle,
  DropdownItem,
  InputLabel,
  ModalDialog,
  Size,
  Textarea
} from '@laerdal/life-react-components';
import Api from '../../../../utils/api';
import {ImpersonateUserRequestDTO, Service, User} from '../../../../types';
import {useUserContext} from '../../../../userContext';
import styled from 'styled-components';

type SigninOnBehalfProps = {
  closeModal: any;
  isModalOpen: boolean;
  service?: Service | null;
  user: User;
};

const TextAreaContainer = styled.div`
  textarea {
    width: calc(100% - 35px) !important;
  }
`;

const Description = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.black)}
  strong {
    font-weight: 700;
  }
  margin-bottom: 16px;
`;

const SigninOnBehalfModal: React.FunctionComponent<SigninOnBehalfProps> = ({
                                                                             closeModal,
                                                                             isModalOpen,
                                                                             service,
                                                                             user
                                                                           }: SigninOnBehalfProps) => {
  const {t} = useTranslation('User');
  const [busy, setBusy] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [details, setDetails] = useState<string>('');
  const {email} = useUserContext();
  const reasons: DropdownItem[] = [
    {value: t('Reproduce user issue'), displayLabel: t('Reproduce user issue')},
    {value: t('Emergency user assistance'), displayLabel: t('Emergency user assistance')},
    // { detailsRequired: false, text: t('Product verification') },
    {value: t('Other'), displayLabel: t('Other')},
  ];
  const signOnBehalf = () => {
    setBusy(true);
    const request: ImpersonateUserRequestDTO = {
      impersonatedUserId: user.id,
      requestOwnerEmail: email,
      targetServiceId: service?.id,
      reason,
      details,
    };
    Api.SigninOnBehalf(request)
      .then((response: ImpersonateUserRequestDTO) => {
        window.open(`${process.env.REACT_APP_IDENTITY_URL}/login/on-behalf/${response.id}`);
        closeModal();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const modalButtons = [
    {
      id: 'cancel',
      variant: 'tertiary',
      text: t('Cancel'),
      disabled: busy,
      size: Size.Large,
      action: closeModal,
      loading: false,
    },
  ];

  if (service?.allowImpersonation) {
    modalButtons.push({
      id: 'save',
      action: (e: any) => {
        e.preventDefault();
        if (!busy) {
          signOnBehalf();
        }
      },
      disabled: !reason || (reason === reasons[reasons.length - 1].value && !details) || busy,
      loading: busy,
      variant: 'critical',
      size: Size.Large,
      text: t('Sign in as this user'),
    });
  } else {
    modalButtons.push({
      id: 'save',
      action: closeModal,
      disabled: busy,
      loading: false,
      variant: 'primary',
      size: Size.Medium,
      text: t('Okay'),
    });
  }

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeAction={closeModal}
      title={t('Sign in as this user')}
      size={Size.Large}
      note={t('Changes you make while impersonating this user are permanent. To stop impersonation, select “End session” from the red banner at the top of the page.')}
      state={'warning'}
      buttons={modalButtons as any}>
      <Description>
        <strong>{service?.name}</strong>
        <br/><br/>
        <Trans i18nKey={'SingInAsDescriptions'} ns={'User'}>
          You are about to impersonate <strong>{user.email}</strong> by controlling their user account.
        </Trans>
      </Description>
      {service?.allowImpersonation && (
        <>
          <InputLabel inputId="reason" text={t('Reason')} size={Size.Medium}/>
          <BasicDropdown
            id="reason"
            placeholder={t('Please select a reason')}
            list={reasons}
            itemsType={'normal'}
            disableSorting={true}
            onSelect={setReason}/>
          <InputLabel inputId="detailsLabel" text={t('Details (optional)')} size={Size.Medium}/>
          <TextAreaContainer>
            <Textarea
              id="details"
              required={false}
              placeholder="Add any additional references or reasons for signing in as this user"
              size={Size.Medium}
              onChange={(value) => setDetails(value)}
              validationMessage={reason && reason === reasons[reasons.length - 1].value && !details ? t('Please provide details') : undefined}
            />
          </TextAreaContainer>
        </>
      )}
      {!service?.allowImpersonation && (
        <p>{t('{{serviceName}} does not support signing in on behalf', {serviceName: service?.name})}</p>
      )}
    </ModalDialog>
  );
};

export default SigninOnBehalfModal;
