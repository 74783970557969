import React from 'react';
import {COLORS, ModalDialog, Size, SystemIcons} from '@laerdal/life-react-components';
import {Organization} from '../../../types';
import {Trans, useTranslation} from 'react-i18next';


export interface OrganizationMergeConfirmModalProps {
  isOpen: boolean;
  sourceOrganization: Organization | undefined;
  onConfirm: () => void;
  onCancel: () => void;
}

const OrganizationMergeConfirmModal: React.FunctionComponent<OrganizationMergeConfirmModalProps> = ({
                                                                                                      isOpen,
                                                                                                      onConfirm,
                                                                                                      onCancel,
                                                                                                      sourceOrganization,
                                                                                                    }) => {
  const {t} = useTranslation('Organization');

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeAction={onCancel}
                 submitAction={onConfirm}
                 size={Size.Medium}
                 buttons={[
                   {
                     variant: 'tertiary',
                     text: t('Cancel'),
                     action: onCancel,
                   }, {
                     variant: 'primary',
                     text: t('Yes, merge'),
                     action: onConfirm,
                   },
                 ]}
                 title={t('Confirm action')}
                 state={'critical'}
                 icon={<SystemIcons.TechnicalWarning color={COLORS.critical_500}/>}
                 note={t('This action is permanent and cannot be undone.')}>
      <p>
        <Trans i18nKey="ConfirmOrganizationMerge" ns="Organization" t={t} tOptions={{name: sourceOrganization?.name}}/>
      </p>
    </ModalDialog>
  );

};

export default OrganizationMergeConfirmModal;