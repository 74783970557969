/**
 * Import React libraries.
 */
import React, { useState } from 'react';

/**
 * Import third-party libraries.
 */
import { ModalDialog, Size } from '@laerdal/life-react-components';

/**
 * Import custom components.
 */

interface ConfirmModalProps {
  visible: boolean;
  cancel: () => void;
  confirm: () => Promise<void>;
  confirmTitle: string;
  cancelTitle: string;
  modalTitle: string;
  confirmButtonVariant: 'primary' | 'secondary' | 'tertiary' | 'text' | 'correct' | 'critical';
  children: React.ReactNode;
  modalSize?: Size.Large | Size.Medium | Size.Small;
  banner?: { type: string; text: string };
}

export const ConfirmationModal: React.FunctionComponent<ConfirmModalProps> = ({
  visible,
  cancel,
  modalSize,
  confirm,
  confirmTitle,
  cancelTitle,
  modalTitle,
  confirmButtonVariant,
  banner,
  children,
}: ConfirmModalProps) => {
  const [confirming, setConfirming] = useState<Boolean>(false);

  /**
   * Confirm function that is fired on confirm.
   */
  const handleConfirm = async () => {
    if (confirming) return;

    setConfirming(true);
    await confirm();
    setConfirming(false);
  };

  /**
   * Cancel function that is fired on closing the modal with non-confirm action.
   */
  const handleCloseClick = () => {
    if (!confirming) cancel();
  };

  return (
    <ModalDialog
      isModalOpen={visible}
      submitAction={() => {}}
      closeAction={handleCloseClick}
      title={modalTitle}
      size={modalSize}
      note={banner && banner.text}
      state={banner && banner.type}
      buttons={
        [
          {
            id: 'cancel',
            variant: 'tertiary',
            text: cancelTitle,
            action: handleCloseClick
          },
          {
            id: 'save',
            action: handleConfirm,
            loading: confirming,
            variant: confirmButtonVariant,
            text: confirmTitle,
            disabled: confirming
          },
        ] as any
      }>
      <>
        {children}
      </>
    </ModalDialog>
  );
};
