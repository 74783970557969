import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate, useParams} from 'react-router';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import Helmet from 'react-helmet';
import {
  BackButton,
  Button,
  COLORS,
  Columns,
  GlobalLoadingPage,
  PageWidth,
  Size,
  SystemIcons,
  ToastColor,
  ToastPosition,
  VerticalTabs,
} from '@laerdal/life-react-components';

import Api from '../../utils/api';
import {useToastContext, useUserContext} from '../../userContext';
import {
  Address,
  CountryDto,
  Invitation,
  Organization,
  OrganizationContact,
  OrganizationMember,
  OrganizationType
} from '../../types';
import InstituteIcon from '../../assets/Institute.svg';
import OrganizationDetailsPage from './OrganizationDetailsPage';
import OrganizationMembersPage from './OrganizationMembersPage';
import OrganizationServicesPage from './OrganizationServicesPage';
import CreateNewLicensePage from './license-management/CreateNewLicensePage';
import OrgLicenseManagementPage from './license-management/OrgLicenseManagementPage';
import {OrganizationSettingsPage} from './OrganizationSettingsPage';
import {OrganizationSalesForceSyncModal} from './Components/OrganizationSalesForceSyncModal';
import OrganizationHistoryPage from './OrganizationHistory';
import {ErrorToastOptions} from "../../constants";

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;

  section {
    margin-top: 16px;
  }
`;

const OrgIconWrapper = styled.section`
  height: 80px;
  width: 80px;
  padding: 25px;
  margin: 40px 0 8px 0;
  border-radius: 48px;
  background-color: ${COLORS.neutral_100};
  box-sizing: border-box;
`;

const OrgLogoWrapper = styled.section`
  margin: 40px 0 8px 0;
`;

const OrgLogo = styled.img`
  height: 30px;
  width: auto;
`;

const Borders = styled.div`
  height: 100%;
`;

const OrganizationName = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
`;

const OrganizationLocation = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: ${COLORS.neutral_700};
  margin-bottom: 16px;
`;

const initialCountry: CountryDto = {
  name: '',
  codeAlpha2: '',
  codeAlpha3: '',
  states: [],
  hasAccess: false,
};

const initialAddress: Address = {
  addressLine: '',
  zipCode: '',
  city: '',
  state: '',
  country: initialCountry,
  phoneNumber: '',
};

const initialOrganization: Organization = {
  id: '',
  customerNo: '',
  name: '',
  logoUrl: '',
  industry: undefined,
  address: initialAddress,
  defaultLanguage: '',
  billingOrganizationName: '',
  vatNumber: '',
  billingContactPersonFirstName: '',
  billingContactPersonLastName: '',
  billingEmailAddress: '',
  useOrganizationAddressForBilling: false,
  billingAddress: initialAddress,
  members: [],
  invitations: [],
  services: [],
  allowProducts: false,
  allowOrders: false,
  allowServiceAppointments: false,
  type: OrganizationType.Organization
};

export type ActionType =
  | {
  type: 'firstRun';
  value: Organization;
}
  | {
  type: 'string';
  value: string;
  field: string;
  field2?: string;
}
  | {
  type: 'number';
  value: number;
  field: string;
}
  | {
  type: 'boolean';
  value: boolean;
  field: string;
}
  | {
  type: 'address' | 'billingAddress';
  value: string | CountryDto;
  field: string;
}
  | {
  type: 'entireaddress';
  value: Address;
  field: string;
}
  | {
  type: 'members';
  value: OrganizationMember[];
  field: string;
}
  | {
  type: 'contacts';
  value: OrganizationContact[];
  field: string;
}
  | {
  type: 'invitations';
  value: Invitation[];
  field: string;
};

const reducer = (state: Organization, action: ActionType) => {
  switch (action.type) {
    case 'firstRun':
      return action.value;
    case 'address':
    case 'billingAddress':
      return {...state, [action.type]: {...state[action.type], [action.field]: action.value}};
    case 'entireaddress':
      return {...state, [action.field]: action.value};
    case 'string':
    case 'contacts':
    case 'number':
    case 'boolean':
    case 'members':
    case 'invitations':
      return {...state, [action.field]: action.value};
    default:
      return {...state};
  }
};

type TParams = { id: string };
const OrganizationPage = () => {
  const {t} = useTranslation('Organization');
  const {addToast} = useToastContext();
  const {
    setSelfServiceServices,
    setCountryConfigurations
  } = useUserContext();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<TParams>();
  const {authenticated} = useUserContext();
  const [savedOrganization, setSavedOrganization] = useState<Organization | null>(null);
  const [organization, dispatch] = useReducer(reducer, initialOrganization);
  const [organizationFromSalesforce, setOrganizationFromSalesforce] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [showSyncModal, setShowSyncModal] = useState<boolean>(false);

  const tabs = useMemo(() => [
    {requiredLine: t('Organization details'), to: `/organization/${params.id}/details`},
    {requiredLine: t('User management'), to: `/organization/${params.id}/user-management`},
    {requiredLine: t('License management'), to: `/organization/${params.id}/license-management/`},
    {requiredLine: t('Settings'), to: `/organization/${params.id}/settings`},
    {requiredLine: t('History'), to: `/organization/${params.id}/history`},
  ], []);

  const updateLocalOrganizationInformation = (org: Organization) => {
    setSavedOrganization(org);
    dispatch({type: 'firstRun', value: org});
  };

  const getOrganization = () => {
    return Api.GetOrganization(params.id!)
      .then((org) => {
        dispatch({type: 'firstRun', value: org});
        setSavedOrganization(org);
        return org;
      })
      .catch((error: any) => {
        if (error?.response?.status != 403) {
          addToast(t('Organization does not exist'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
          navigate('/');
        }
      });
  };

  const getOrganizationFromSalesforce = (customerNo: string) => {
    return Api.CheckOrganizationCreatedFromSalesforce(customerNo)
      .then((value: boolean) => {
        setOrganizationFromSalesforce(value);
        return Promise.resolve();
      })
      .catch((error: any) => {
        console.log(error);
        setOrganizationFromSalesforce(false);
        return Promise.resolve();
      });
  };

  const getSelfServiceServices = (signal: AbortSignal) =>
    Api.GetSelfServiceServices(signal).catch(() => {
      if (!signal.aborted) {
        addToast(t('Self service services could not be fetched.'), ErrorToastOptions);
      }
      return [];
    });

  const getCountryConfigurations = (signal: AbortSignal) =>
    Api.GetCountryConfigurations(signal).catch(() => {
      if (!signal.aborted) {
        addToast(t('Country configurations could not be fetched.'), ErrorToastOptions);
      }
      return {};
    });

  useEffect(() => {
    const abort = new AbortController();
    if (authenticated && Api.token) {
      setLoading(true);
      Promise.all([
        getOrganization(),
        getSelfServiceServices(abort.signal),
        getCountryConfigurations(abort.signal)]
      ).then(([organization, selfServiceServices, countryConfigurations]) => {
          setSelfServiceServices(selfServiceServices);
          setCountryConfigurations(countryConfigurations);
          return organization?.customerNo ? getOrganizationFromSalesforce(organization.customerNo) : Promise.resolve();
        }
      ).finally(() => setLoading(false));
    }

    return () => abort.abort();
  }, [authenticated, Api.token]);

  const getOrganizationsUrl = () => {
    let orgsQuery = localStorage.getItem('orgsQuery');
    let orgsFrom = localStorage.getItem('orgsFrom');
    let orgsCurrentPage = localStorage.getItem('orgsCurrentPage');
    let orgsRowsPerPage = localStorage.getItem('orgsRowsPerPage');
    let orgsSortBy = localStorage.getItem('orgsSortBy');
    let orgsSortDir = localStorage.getItem('orgsSortDirection');

    return `/organization?${orgsQuery ? `query=${orgsQuery}&` : ''}${orgsFrom ? `from=${orgsFrom}&` : ''}${orgsCurrentPage ? `currentPage=${orgsCurrentPage}&` : ''}` +
      `${orgsRowsPerPage ? `rowsPerPage=${orgsRowsPerPage}&` : ''}${orgsSortBy ? `sortBy=${orgsSortBy}&` : ''}${orgsSortDir ? `sortDirection=${orgsSortDir}&` : ''}`;
  };

  /**
   * Renders the navigate back section and all of it's related logic.
   * @returns HTML for the navigate back section.
   */
  const renderNavigateBackSection = () => {
    return (
      <BackButton size={Size.Small} onClick={() => navigate(getOrganizationsUrl())}>
        {t('Back to results')}
      </BackButton>
    );
  };

  const pageTitle = `${organization.name ?? organization.customerNo ?? 'Organization'} details - Laerdal Cloud Control`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {
        loading ? (
          <GlobalLoadingPage/>
        ) : (
          <PageWidth $useMaxWidth={true} $maxWidth={1088}>
            <OrganizationSalesForceSyncModal isOpen={showSyncModal}
                                             onClose={() => setShowSyncModal(false)}
                                             onSuccess={() => setOrganizationFromSalesforce(true)}
                                             organization={savedOrganization!}/>
            <Columns $columns="25% 8fr">
              <Sidebar>
                {savedOrganization?.logoUrl ? (
                  <OrgLogoWrapper>
                    <OrgLogo src={savedOrganization.logoUrl} alt={`${savedOrganization.name} ${t('logo')}`}/>
                  </OrgLogoWrapper>
                ) : (
                  <OrgIconWrapper>
                    <OrgLogo src={InstituteIcon} alt={t('Generic icon representing an organization logo')}/>
                  </OrgIconWrapper>
                )}
                <OrganizationName>{savedOrganization?.name}</OrganizationName>
                <OrganizationLocation>{`${savedOrganization?.address?.city
                  ? savedOrganization?.address?.city + ', '
                  : ''}${
                  savedOrganization?.address?.country?.name
                }`}</OrganizationLocation>
                {
                  savedOrganization?.customerNo && !organizationFromSalesforce &&
                  <Button variant={'secondary'}
                          icon={<SystemIcons.CloudSyncing/>}
                          onClick={() => setShowSyncModal(true)}>
                    {t('Sync in SalesForce')}
                  </Button>
                }
                <section>
                  <VerticalTabs entries={tabs} size={Size.Medium}/>
                </section>
                <Borders/>
              </Sidebar>

              <main id="main">
                {!location.pathname.includes('/license-details') &&
                !location.pathname.includes('/user-access') &&
                !location.pathname.includes('/sessions') &&
                !location.pathname.includes('/settings') &&
                !location.pathname.includes('/history') &&
                !location.pathname.includes('/license-management/new')
                  ? renderNavigateBackSection()
                  : ''}
                <Routes>
                  <Route index element={<Navigate to="details" replace/>}/>
                  <Route
                    path={`details`}
                    element={
                      <OrganizationDetailsPage
                        savedOrganization={savedOrganization!}
                        setSavedOrganization={(org: Organization) => setSavedOrganization(org)}
                        organizationFromSalesforce={!!organizationFromSalesforce}
                      />
                    }
                  />
                  <Route path="/settings"
                         element={<OrganizationSettingsPage savedOrganization={savedOrganization!}
                                                            setSavedOrganization={setSavedOrganization}/>}/>

                  <Route
                    path={`/user-management`}
                    element={<OrganizationMembersPage organization={organization} getOrganization={getOrganization}
                                                      dispatch={dispatch} existsInSF={!!organizationFromSalesforce}/>}
                  />
                  <Route
                    path={`/user-management/:page`}
                    element={<OrganizationMembersPage organization={organization} getOrganization={getOrganization}
                                                      dispatch={dispatch} existsInSF={!!organizationFromSalesforce}/>}
                  />
                  <Route path={`/license-management`} element={<OrganizationServicesPage/>}/>
                  <Route
                    path={`/license-management/new/:id`}
                    element={<CreateNewLicensePage savedOrganization={savedOrganization!}
                                                   setSavedOrganization={updateLocalOrganizationInformation}/>}
                  />
                  <Route path="/license-management/:id/*"
                         element={<OrgLicenseManagementPage organizationId={organization.id}/>}/>

                  <Route path="history"
                         element={<OrganizationHistoryPage organization={organization}/>}/>
                </Routes>
              </main>
            </Columns>
          </PageWidth>
        )}
    </>
  );
};

export default OrganizationPage;
