import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ModalDialog,  Size} from '@laerdal/life-react-components';

import {useToastContext, useUserContext} from '../../../../userContext';
import Api from '../../../../utils/api';
import {OrganizationService, OrganizationServiceMember, User} from '../../../../types';
import {ErrorToastOptions, SuccessToastOptions} from "../../../../constants";

type RemoveUserFromServiceProps = {
  closeModal: any;
  isModalOpen: boolean;
  orgId: string;
  organizationService: OrganizationService | null;
  member: OrganizationServiceMember | undefined;
  user: User | undefined;
  onSuccess: () => Promise<void>;
};

const RemoveUserFromServiceModal: React.FunctionComponent<RemoveUserFromServiceProps> = ({
                                                                                           closeModal,
                                                                                           isModalOpen,
                                                                                           orgId,
                                                                                           organizationService,
                                                                                           member,
                                                                                           user,
                                                                                           onSuccess,
                                                                                         }: RemoveUserFromServiceProps) => {
  const {t} = useTranslation('User');
  const {authenticated} = useUserContext();
  const {addToast} = useToastContext();
  const [busy, setBusy] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>('');

  useEffect(() => {
    if (member?.user.firstName && member?.user.lastName)
      setDisplayName(member?.user.firstName + ' ' + member?.user.lastName);
    else if (user?.email)
      setDisplayName(user.email);
    else
      setDisplayName(t('User'));
  }, [member, user]);

  const removeMember = (orgServiceId: string, userId: string) => {
    if (authenticated) {
      setBusy(true);
      Api.RemoveMemberFromOrganizationService(orgId, orgServiceId, userId)
        .then(() => onSuccess().then(() => {
          addToast(t('User was removed from service successfully.'), SuccessToastOptions);
        }))
        .catch(() => {
          addToast(t('There was a problem removing the user from the service.'), ErrorToastOptions);
        })
        .finally(() => {
          closeModal();
          setBusy(false)
        });
    }
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeAction={closeModal}
      title={t('Remove from service')}
      size={Size.Large}
      buttons={
        [
          {
            id: 'cancel',
            variant: 'tertiary',
            text: t('No, cancel'),
            disabled: busy,
            action: closeModal
          },
          {
            id: 'remove',
            action: () => {
              if (!busy) {
                removeMember(organizationService?.id!, member?.user?.id || '');
              }
            },
            loading: busy,
            variant: 'critical',
            text: t('Yes, remove'),
            disabled: busy
          },
        ] as any
      }>

      <p><strong>{displayName}</strong></p>
      <br/>
      <p>{t('This person will no longer be able to access this service, nor be able to see any content that has been shared by others who are using this service.')}</p>
      <br/>
      <p>{t('Are you sure you want to remove this person from {{service}}?', {service: organizationService?.name})}</p>
    </ModalDialog>
  );
};

export default RemoveUserFromServiceModal;
