/**
 * Import React Libraries.
 */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';

/**
 * Import third-party libraries.
 */
import { Trans, useTranslation } from 'react-i18next';
import { PageWidth, InputLabel, COLORS, Button, ChipDropdownInput, ToastColor, ToastContext, ToastPosition, SystemIcons, Checkbox } from '@laerdal/life-react-components';
import styled from 'styled-components';

/**
 * Import custom functions.
 */
import Api from '../../utils/api';

/**
 * Import custom types.
 */
import { Organization, Recipient } from '../../types';
import { useToastContext, useUserContext } from '../../userContext';
import UnauthorizedMessage from '../../components/UnauthorizedMessage';

/**
 * Custom styles.
 */
const InputWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  &.textarea {
    div:nth-child(2) {
      display: flex;

      textarea.medium {
        width: 100%;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > button + button {
    margin-left: 10px;
  }
`;

const Description = styled.p`
  color: ${COLORS.neutral_600};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const CenteredText = styled.p`
  text-align: center;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
  justify-content: center;
  .success-icon {
    border-radius: 50%;
    background-color: ${COLORS.correct_500};
    color: ${COLORS.white};
    max-width: fit-content;
    max-height: fit-content;
    margin-right: 0.2em;
  }
`;

const OrganizationOnboard = () => {
  // Globally used variables within the page
  const { t } = useTranslation('Organization');
  const { addToast } = useToastContext();
  const { onboarder } = useUserContext();
  const [customerNo, setCustomerNo] = useState<string>('');
  const [noOrganization, setNoOrganization] = useState<boolean>(false);
  const [onboarded, setOnboarded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [multipleOrganizations, setMultipleOrganizations] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [organization, setOrganization] = useState<Organization>();
  const [allAvailableEmails, setAllAvailableEmails] = useState<string[]>([]);
  const [validForm, setValidForm] = useState<boolean>(false);
  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [regularEmails, setRegularEmails] = useState<string[]>([]);
  const location = useLocation();

  React.useEffect(() => {
    if(adminEmails.length == 0 && regularEmails.length == 0)
      setValidForm(false);
  }, [adminEmails, regularEmails]);

  /**
   * Retrieves URL parameters and stores them in a state.
   */
  const getURLParameters = (): void => {
    // Let's get URL params
    const parameters = new URLSearchParams(location.search);

    // Let's retrieve parameters from the url
    if (parameters.get('customerNo')) {
      // Let's update customer number
      setCustomerNo(parameters.get('customerNo')!);
    }
  };

  /**
   * Retrieves a specific organization data.
   * @param id - ID of the organization which to retrieve.
   */
  const getOrganization = (id: string): void => {
    Api.GetOrganization(id).then((organization: Organization) => {
      // Let's check if organization is not already onboarded
      if (!organization.isOnboarded) {
        // Organization was found, let's update data
        setOrganization(organization);
        setNoOrganization(false);
        setMultipleOrganizations(false);

        setAllAvailableEmails(organization!.members.map(member => member.user.email)
          .concat(organization!.contacts?.map(c => c.user.email) ?? []).filter((value, index, self) => 
          value !== null && index === self.indexOf(value)));
      } else {
        // Set organization
        setOrganization(organization);
        setOnboarded(true);
        setAllAvailableEmails([]);
      }
    });
  };

  /**
   * Retrieves all passed parameters for the page.
   */
  useEffect(() => {
    // Let's retrieve parameters
    getURLParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retrieves organization details from the API.
   */
  useEffect(() => {
    if (customerNo) {
      Api.GetOrganizationByCustomerNo(customerNo)
        .then((organization: Organization) => {
          // Let's retrieve organization details and members
          getOrganization(organization.id);
        })
        .catch((error) => {
          // If there are no organizations found inform user
          if (error === 'Not Found') {
            setMultipleOrganizations(false);
            setNoOrganization(true);
          }

          // Let's check if multiple organizations were found
          if (error === 'Bad Request') {
            setMultipleOrganizations(true);
            setNoOrganization(false);
          }
        });
    }
  }, [customerNo]);

  /**
   * Validate e-mails and assigns them to the form data.
   * @param emailAddresses - A list of e-mail addresses from the chip input.
   */
  const validateEmails = (emailAddresses: string[], forAdmins: boolean): void => {
    // First let's remove white spaces
    emailAddresses = emailAddresses.map((email: string) => email.replace(/\s+/g, ''));

    // Let's assign values
    if (forAdmins) {
      setAdminEmails(emailAddresses);
    } else {
      setRegularEmails(emailAddresses);
    }

    // Validate form
    validateForm(emailAddresses);
  };

  /**
   * Validates the form.
   */
  const validateForm = (emailAddresses?: string[]): void => {
    const validationEmails = emailAddresses ? emailAddresses : adminEmails;

    // Let's mark form as valid
    setValidForm(true);

    // Let's validate the form
    if (validationEmails.some((email) => !validEmail(email))) {
      // One of the entered e-mails is not a valid email
      setValidForm(false);
    }
  };

  /**
   * Validates an e-mail address against a valid e-mail address format.
   * @param email - Email address which needs to be validated.
   * @returns Boolean value indicating if the e-mail is a valid e-mail address.
   */
  const validEmail = (email: string): boolean => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(email);
  };

  /**
   * Does all required pre-requisites and tries to onboard the company.
   */
  const onboardCompany = () => {
    // Let's make sure that we onboard only in case the form is valid
    if (validForm) {

      const adminRecipients = adminEmails
        .map((x) => organization!.members.find((member) => member.user.email === x) ?? 
          organization!.contacts?.find((contact) => contact.user.email === x))
        .filter((x) => Boolean(x))
        .map((x) => ({
          email: x!.user.email,
          id: x!.user.id,
          firstName: x!.user.firstName,
          lastName: x!.user.lastName,
        }));

      const regularRecipients = regularEmails
        .map((x) => organization!.members.find((member) => member.user.email === x) ?? 
          organization!.contacts?.find((contact) => contact.user.email === x))
        .filter((x) => Boolean(x))
        .map((x) => ({
          email: x!.user.email,
          id: x!.user.id,
          firstName: x!.user.firstName,
          lastName: x!.user.lastName,
        }));

      setLoading(true);

      // Let's onboard the organization
      Api.OnboardOrganization(organization!.id, '', '', adminRecipients, regularRecipients)
        .then(() => {
          setShowSuccessMessage(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          addToast(t('There was an error with onboarding the organization'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
        });
    }
  };

  /**
   * Renders a specific view in case customerNo URL parameter is missing.
   * @returns HTML view for a case when required URL parameter is missing.
   */
  const renderMissingParameters = () => {
    return (
      <CenteredText>
        <b>Customer Number</b> parameter is missing.
      </CenteredText>
    );
  };

  /**
   * Renders a specific view in case organization is already onboarded.
   * @returns HTML view for a case when organization is already onboarded.
   */
  const renderOrganizationOnboarded = () => {
    return <CenteredText>{t('{{organizationName}} organization is already onboarded', { organizationName: organization!.name })}.</CenteredText>;
  };

  /**
   * Renders a specific view in case organization with specified customer number was not found.
   * @returns HTML view for a case when organization with the specified customer number was not found.
   */
  const renderNoOrganization = () => {
    return (
      <CenteredText>
        Organization with a customer number <b>{customerNo}</b> was not found.
      </CenteredText>
    );
  };

  /**
   * Renders a specific view in case multiple organizations with specified customer number were found.
   * @returns HTML view for a case when multiple organizations with the specified customer number were found.
   */
  const renderMultipleOrganizations = () => {
    return (
      <CenteredText>
        Multiple organizations with a customer number <b>{customerNo}</b> were found.
      </CenteredText>
    );
  };

  /**
   * Renders a specific view in case organization was found and onboard process can be started.
   * @returns HTML view for a case when organization was found and onboarding can be started.
   */
  const onboardOrganization = () => {
    const organizationName = organization!.name;
    
    return (
      <>
        <h3>Onboard Organization {organizationName}</h3>

        <p>Select people to enable access to Connect for this Organization</p>
        <p>Each person will receive an email welcoming them to their organization on Laerdal Connect</p>

        {/* E-mail Address input field */}
        <InputWrapper>
          <InputLabel text={t('Admin users')} inputId="email-addresses" />
          <ChipDropdownInput
            placeholder={t('Enter admin email address')}
            list={allAvailableEmails.filter((x) => regularEmails.findIndex((y) => x == y) < 0)}
            inputId="email-addresses"
            values={adminEmails}
            icon={<SystemIcons.User/>}
            onValueChange={(chips) => validateEmails(chips, true)}
            required={false}
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabel text={t('Normal users')} inputId="normal-email-addresses" />
          <ChipDropdownInput
            placeholder={t('Enter user email address')}
            list={allAvailableEmails.filter((x) => adminEmails.findIndex((y) => x == y) < 0)}
            inputId="normal-email-addresses"
            values={regularEmails}
            icon={<SystemIcons.User/>}
            onValueChange={(chips) => validateEmails(chips, false)}
            required={false}
          />
        </InputWrapper>

        <ButtonWrapper>
          <Button id="send-invites" loading={loading} type="submit" disabled={!validForm} onClick={onboardCompany}>
            {t('Send welcome email(s)')}
          </Button>
        </ButtonWrapper>
      </>
    );
  };

  /**
   * Renders a specific view when the onboard process has been done successfully
   */
  if (showSuccessMessage) {
    return (
      <SuccessMessage id="OrganizationOnboardSuccessMsg">
        <SystemIcons.CheckMark className={'success-icon'} size="28px" />
        <span>{t('You successfully set Laerdal Connect Home as the default login page for this organization.')}</span>
      </SuccessMessage>
    );
  }

  return onboarder ? (
    <PageWidth $useMaxWidth={true} $maxWidth={1600}>
      {
        // Render missing parameters message
        !customerNo && renderMissingParameters()
      }
      {
        // Render no organization message
        noOrganization && renderNoOrganization()
      }
      {
        // Render multiple organizations message
        multipleOrganizations && renderMultipleOrganizations()
      }
      {
        // Render organization onboarded message
        organization && onboarded && renderOrganizationOnboarded()
      }
      {
        // Render onboard organization view
        organization && !onboarded && onboardOrganization()
      }
    </PageWidth>
  ) : (
    <UnauthorizedMessage>
      <>
        You don't have access to Active Onboarding functionality <br />
        Contact a member of the Laerdal Support Center team to request access.
      </>
    </UnauthorizedMessage>
  );
};

export default OrganizationOnboard;
