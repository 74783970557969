import React, {useEffect, useState} from 'react';
import {Organization} from '../../../types';
import styled from 'styled-components';
import {
  COLORS, ComponentLStyling,
  ComponentTextStyle,
  Size,
  SystemIcons,
  Tile,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import OrganizationSelectModal from './OrganizationSelectModal';
import {memberFullAccessPermission} from "../../../constants";
import {LicenseInformationHelper} from "../license-management/Common/LicenseInformationHelper";


const OrganizationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OrganizationInfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrganizationInfoItemTitle = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;
const OrganizationInfoItemValue = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.black)}
`;


interface OrganizationMergeDetailsProps {
  organization?: Organization;
  disabled?: string[];
  setOrganization: (orgId: string | undefined) => void;
  type: string;
}

const OrganizationMergeDetails: React.FunctionComponent<OrganizationMergeDetailsProps> = (props) => {
  const {t} = useTranslation('Organization');

  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const [info, setInfo] = useState<{
    customerNo?: string,
    owner?: string,
    location?: string,
    numberOfUsers: number,
    numberOfServices: number,
    activeSubscriptions: number,
  }>();

  useEffect(() => {
    if (props.organization) {
      const owners = props.organization.members?.filter(m => !!m.permissions?.find(a => a.permissionId == memberFullAccessPermission));

      setInfo({
        customerNo: props.organization.customerNo ?? '-',
        owner: owners?.length ? `${owners[0].user.email} ${owners.length > 1 ? '+' + (owners.length - 1) : ''}` : '-',
        location: `${props.organization.address?.city || '-'}, ${props.organization.address?.country?.name || '-'}`,
        numberOfUsers: props.organization.members?.length || 0,
        numberOfServices: props.organization.services?.length || 0,
        activeSubscriptions: props.organization.services?.filter(a => LicenseInformationHelper.IsActive(a.subscription)).length || 0,
      });
    } else {
      setInfo(undefined);
    }
  }, [props.organization]);

  return (
    <>
      <OrganizationSelectModal isOpen={isSelectModalOpen}
                               onClose={() => setIsSelectModalOpen(false)}
                               organization={props.organization}
                               disabled={props.disabled}
                               setOrganization={props.setOrganization}/>
      <Tile size={Size.Large}
            header={{
              title: props.organization?.name || `No ${props.type}`,
              subtitle: props.organization?.customerNo || '-',
              buttons: props.organization ? [
                {
                  componentType: 'icon',
                  action: () => window.open(`/organization/${props.organization!.id}`, '_blank'),
                  icon: <SystemIcons.OpenNewWindow/>,
                }
              ] : []
            }}
            footer={{
              leftItem: {
                componentType: 'button',
                variant: 'secondary',
                buttonText: props?.organization ? t('Change') : t('Select organization'),
                onClick: () => setIsSelectModalOpen(true),
              }
            }}
      >
        {
          info &&
            <OrganizationInfoWrapper>
                <OrganizationInfoItem>
                    <OrganizationInfoItemTitle>
                      {t('Account owner')}
                    </OrganizationInfoItemTitle>
                    <OrganizationInfoItemValue>
                      {info.owner}
                    </OrganizationInfoItemValue>
                </OrganizationInfoItem>

                <OrganizationInfoItem>
                    <OrganizationInfoItemTitle>
                      {t('Location')}
                    </OrganizationInfoItemTitle>
                    <OrganizationInfoItemValue>
                      {info.location}
                    </OrganizationInfoItemValue>
                </OrganizationInfoItem>

                <OrganizationInfoItem>
                    <OrganizationInfoItemTitle>
                      {t('Number of users')}
                    </OrganizationInfoItemTitle>
                    <OrganizationInfoItemValue>
                      {info.numberOfUsers}
                    </OrganizationInfoItemValue>
                </OrganizationInfoItem>

                <OrganizationInfoItem>
                    <OrganizationInfoItemTitle>
                      {t('Number of services')}
                    </OrganizationInfoItemTitle>
                    <OrganizationInfoItemValue>
                      {info.numberOfServices}
                    </OrganizationInfoItemValue>
                </OrganizationInfoItem>

                <OrganizationInfoItem>
                    <OrganizationInfoItemTitle>
                      {t('Active subscriptions')}
                    </OrganizationInfoItemTitle>
                    <OrganizationInfoItemValue>
                      {info.activeSubscriptions}
                    </OrganizationInfoItemValue>
                </OrganizationInfoItem>

            </OrganizationInfoWrapper>
        }
      </Tile>
    </>
  );
};


export default OrganizationMergeDetails;