import React, {useEffect, useReducer, useState} from 'react';
import {Navigate, Route, Routes, useNavigate, useParams} from 'react-router';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import Helmet from 'react-helmet';
import {
  BackButton,
  COLORS,
  Columns,
  ComponentLStyling, ComponentSStyling,
  ComponentTextStyle,
  GlobalLoadingPage,
  PageWidth,
  Size,
  SystemIcons,
  Tag,
  ToastColor,
  ToastPosition,
  VerticalTabs,
} from '@laerdal/life-react-components';

import Api from '../../utils/api';
import {useToastContext, useUserContext} from '../../userContext';
import {Organization, User, UserStates} from '../../types';
import LanguageAndRegionPage from './LanguageAndRegionPage';
import UserDetailsPage from './UserDetailsPage';
import UserOrganizationListPage from './UserOrganizationListPage';
import UserHistory from './UserHistory';

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Borders = styled.div`
  height: 100%;
`;

const AvatarContainer = styled.div`
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${COLORS.primary_500};
  color: ${COLORS.white};
  text-transform: uppercase;
`;

const AvatarContainerName = styled.span`
  display: block;
  padding-top: 8px;
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const AvatarContainerDetail = styled.span`
  display: block;
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_700)}
`;


const StyledPageWidth = styled(PageWidth)`
  margin-top: 32px;
  margin-bottom: 128px;

  .unregistered {
    background-color: transparent;
    border: solid 2px ${COLORS.correct_600};
  }
`;

const initialUser: User = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  identityId: '',
  currentOrganization: null,
  organizations: [],
  services: [],
};

export type ActionType =
  | {
  type: 'firstRun';
  value: User;
}
  | {
  type: 'organization';
  value: User;
}
  | {
  type: 'string';
  value: string;
  field: string;
  field2?: string;
}
  | {
  type: 'number';
  value: number;
  field: string;
}
  | {
  type: 'boolean';
  value: boolean;
  field: string;
} | {
  type: 'addOrganization',
  value: Organization
} | {
  type: 'clearOrganizations',
} | {
  type: 'remove-invitation',
  value: string
};

const reducer = (state: User, action: ActionType) => {
  switch (action.type) {
    case 'firstRun':
      return {...action.value};
    case 'addOrganization':
      return {...state, organizations: [...state.organizations, action.value]};
    case  'clearOrganizations':
      return {...state, organizations: []};
    case 'remove-invitation':
      return {
        ...state,
        invitations: state.invitations?.filter((invitation) => invitation.invitation.code !== action.value),
      }
    case 'organization':
      return {
        ...action.value,
        identityId: state.identityId,
        hasGigyaAccount: state.hasGigyaAccount,
        isVerified: state.isVerified,
      };
    case 'string':
    case 'number':
    case 'boolean':
      return {...state, [action.field]: action.value};
    default:
      return {...state};
  }
};

type TParams = { id: string };
const UserPage = () => {
  const {t} = useTranslation('User');
  const {addToast} = useToastContext();
  const params = useParams<TParams>();
  const navigate = useNavigate();
  const {authenticated} = useUserContext();
  const [savedUser, setSavedUser] = useState<User>(initialUser);
  const [user, dispatch] = useReducer(reducer, initialUser);

  var tabs = [
    {requiredLine: t('User details'), to: `/user/${params.id}/details`},
    {requiredLine: t('Organization access'), to: `/user/${params.id}/organization-access`},
    {requiredLine: t('Language & Region'), to: `/user/${params.id}/language-region`},
    {requiredLine: t('User history'), to: `/user/${params.id}/user-history`},
  ];

  useEffect(() => {
    if (authenticated) {
      Api.GetUser(params.id!)
        .then((user) => {
          dispatch({type: 'firstRun', value: user});
          setSavedUser(user);
        })
        .catch((error: any) => {
          if (error?.response?.status != 403) {
            addToast(t('User does not exist'), {
              color: ToastColor.RED,
              showCloseButton: true,
              autoClose: true,
              position: ToastPosition.TOPMIDDLE,
            });
            navigate('/');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (user.email) {
      Api.HasGigyaAccount(user.email)
        .then((exists) => {
          dispatch({type: 'boolean', value: exists, field: 'hasGigyaAccount'});
        })
        .catch(() => {
          addToast(t('Could not retrieve user account status in SAP CDC'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.email]);

  useEffect(() => {
    if (user.hasGigyaAccount && user.email) {
      Api.IsVerified(user.email)
        .then((res) => {
          dispatch({type: 'boolean', value: res.isVerified, field: 'isVerified'});
          dispatch({type: 'string', value: res.uid, field: 'identityId'});
        })
        .catch(() => {
          addToast(t('Could not retrieve user account status in SAP CDC'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.email, user.hasGigyaAccount]);

  const getUsersUrl = () => {
    let orgsQuery = localStorage.getItem('usersQuery');
    let orgsFrom = localStorage.getItem('usersFrom');
    let orgsCurrentPage = localStorage.getItem('usersCurrentPage');
    let orgsRowsPerPage = localStorage.getItem('usersRowsPerPage');
    let orgsSortBy = localStorage.getItem('usersSortBy');
    let orgsSortDir = localStorage.getItem('usersSortDirection');
    let userState = localStorage.getItem('usersState');

    const url = `/user?${orgsQuery ? `query=${orgsQuery}&` : ''}${orgsFrom ? `from=${orgsFrom}&` : ''}${orgsCurrentPage ? `currentPage=${orgsCurrentPage}&` : ''}` +
      `${orgsRowsPerPage ? `rowsPerPage=${orgsRowsPerPage}&` : ''}${orgsSortBy ? `sortBy=${orgsSortBy}&` : ''}${orgsSortDir ? `sortDirection=${orgsSortDir}&` : ''}` +
      `${userState ? `state=${userState}&` : ''}`;
    return url;
  };

  /**
   * Renders the navigate back section and all of it's related logic.
   * @returns HTML for the navigate back section.
   */
  const renderNavigateBackSection = () => {
    return (
      <BackButton size={Size.Small} onClick={() => navigate(getUsersUrl())}>
        {t('Back to results')}
      </BackButton>
    );
  };

  const pageTitle = `${user.email ?? 'User'} details - Cloud Control`;

  return (
    <>
      <Helmet>
        <title>{t('User')}</title>
      </Helmet>
      {savedUser === initialUser ? (
        <GlobalLoadingPage/>
      ) : (
        <StyledPageWidth $maxWidth={1600} $useMaxWidth={true}>
           <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Columns $columns="25% 8fr">
            <Sidebar>
              {
                <AvatarContainer>
                  <Avatar>
                    {
                      (!!savedUser?.firstName?.charAt(0) || !!savedUser?.lastName?.charAt(0)) &&
                      <h4>{savedUser?.firstName?.charAt(0)}{savedUser?.lastName?.charAt(0)}</h4>
                    }
                    {
                      !savedUser?.firstName?.charAt(0) && !savedUser?.lastName?.charAt(0) &&
                      <SystemIcons.User size={'36px'}/>
                    }
                  </Avatar>
                  <AvatarContainerName>{`${savedUser?.firstName || ''} ${savedUser?.lastName ?? ''}`}</AvatarContainerName>
                  <AvatarContainerDetail>{savedUser?.email}</AvatarContainerDetail>
                </AvatarContainer>
              }
              {
                user?.state !== UserStates.Default &&
                <Tag 
                  className={user?.state === UserStates.Unregistered ? 'unregistered' : ''} 
                  label={user?.state === UserStates.Registered ? 'Registered' : user?.state === UserStates.Unregistered ? 'Unregistered' : 'SF Contact'}
                  variant={(user?.state === UserStates.Registered || user?.state == UserStates.Unregistered) ? 'positive' : 'neutral'} />
              }
              <section>
                <VerticalTabs entries={tabs} size={Size.Large}/>
              </section>
              <Borders/>
            </Sidebar>
            <main id="main">
              {renderNavigateBackSection()}
              <Routes  >
                <Route index element={<Navigate to="details"/>}/>
                <Route path="/details" element={<UserDetailsPage  user={user} dispatch={dispatch} savedUser={savedUser}
                                                                 setSavedUser={(user: User) => setSavedUser(user)}/>}/>
                <Route
                  path="/organization-access"
                  element={<UserOrganizationListPage  user={user} dispatch={dispatch} savedUser={savedUser}
                                                     setSavedUser={(user: User) => setSavedUser(user)}/>}
                />
                <Route path="/language-region" element={<LanguageAndRegionPage  user={user}/>}/>

                <Route path="/user-history" element={<UserHistory  user={user}/>}/>
              </Routes>
            </main>
          </Columns>
        </StyledPageWidth>
      )}
    </>
  );
};

export default UserPage;
