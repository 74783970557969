import React, {useEffect, useState} from 'react';
import {Organization, OrganizationService, Service, SessionDTO} from '../../../types';
import styled from 'styled-components';
import {
  ComponentTextStyle,
  COLORS,
  ListRow,
  Paginator,
  Size,
  SystemIcons, LoadingIndicator, LinearProgress,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Params} from '../usermanagement/UserManagementList';
import SessionInformationModal from './Components/SessionInformationModal';
import {ComponentMStyling} from '@laerdal/life-react-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

interface OrgLicenseSessionsPageProps {
  organization: Organization;
  organizationService: OrganizationService;
  service: Service;
  sessions: SessionDTO[] | undefined;
  url: string;
}

const OrgLicenseSessionsPage: React.FunctionComponent<OrgLicenseSessionsPageProps> = (props) => {
  const {t} = useTranslation('OrganizationServices');

  const [selectedSession, setSelectedSession] = React.useState<{ session: SessionDTO, seat: number }>();
  const [showModal, setShowModal] = React.useState(false);

  const resultsPerPage = 10;
  const params = useParams<Params>();
  let page = Number(params.page) || 1;

  const [from, setFrom] = useState<number>(0);
  const [to, setTo] = useState<number>(0);

  const showLoader = props.sessions === undefined;

  /**
   * Initializes the pagination for the list.
   */
  useEffect(() => {
    setFrom((page - 1) * resultsPerPage);
    setTo((page - 1) * resultsPerPage + resultsPerPage);
  }, [page]);

  const showSessionInfo = (session: SessionDTO, seat: number) => {
    setSelectedSession({session, seat});
    setShowModal(true);
  };

  const sessionCount = props.sessions?.length || 0;
  const hasLimit = !!props.organizationService.subscription!.maxSubscriptionInstances;
  const sessionLimit = hasLimit
    ? props.organizationService.subscription!.maxSubscriptionInstances!
    : 0;

  return (
    <>
      {
        showLoader &&
        <LoadingIndicator/>
      }
      {
        !showLoader &&
        <Wrapper>
          <SessionInformationModal session={selectedSession?.session}
                                   seat={selectedSession?.seat}
                                   onClose={() => setShowModal(false)}
                                   isOpen={showModal}/>

          <Header>
            <LinearProgress value={sessionCount} 
                            max={hasLimit? sessionLimit : sessionCount} 
                            label={
                              hasLimit
                                  ? t('Active seats: {{count}}/{{limit}}', {count: sessionCount, limit: sessionLimit})
                                  : t('Active seats: {{count}}', {count: sessionCount})
                            }
            />
          </Header>
          <Body>
            {
              props.sessions!.slice(from, to).map((a, i) =>
                <ListRow key={a.deviceId || i}
                         mainText={`${a.firstName} ${a.lastName}`}
                         secondaryText={t('Seat {{number}}', {number: (i + 1) + (page - 1) * resultsPerPage})}
                         icon={<SystemIcons.User/>}
                         size={Size.Large}
                         action={() => showSessionInfo(a, (i + 1) + (page - 1) * resultsPerPage)}/>,
              )
            }

          </Body>
          <Footer>
            <Paginator pageCount={Math.ceil(props.sessions!.length / resultsPerPage)}
                       currentPage={page}
                       baseUrl={props.url}/>
          </Footer>
        </Wrapper>
      }
    </>
  );
};

export default OrgLicenseSessionsPage;
