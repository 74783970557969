import { CountryDto, ServiceRole } from '../types';

/**
 * @param key: string
 * @param value: any
 * @returns boolean
 * @description Saves a key/value to localStorage
 */
export const saveToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * @param key: string
 * @returns any or null
 * @description Returns a key/value from localStorage, or null if not present.
 */
export const getFromLocalStorage = (key: string) => {
  let value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

const MilliSecondsPerDay = 1000 * 60 * 60 * 24;
export const getDateDiffInDays = (a: Date, b: Date) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc1 - utc2) / MilliSecondsPerDay);
}

export const capitalizeFirstLetter = (text: string | null | undefined) => {
  if (text) {
    return text?.charAt(0).toUpperCase() + text?.slice(1);
  } else {
    return '';
  }
};

export const dateOnlyMidnightUtc = (date: Date | undefined | string) => {
  if (!date) return undefined;
  if (!(date instanceof Date) ) date = new Date(date);
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
};

export const formatDateOnly = (expirationDate: Date | undefined) => {
  if (expirationDate) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    expirationDate = new Date(expirationDate || '');
    expirationDate.setHours(0, 0, 0, 0);
    return expirationDate.toLocaleDateString("en-US", options);
  } else {
    return '';
  }
};

export const domainValidationRequired = (serviceId?: string) : Boolean => {
  return serviceId?.toLowerCase() == process.env.REACT_APP_TEAMREPORTER_SERVICE_ID?.toLowerCase() ||
    serviceId?.toLowerCase() == process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase();
};  

export const getDateOnly = (date: Date) => {
  const result = new Date(date || '');
  result.setHours(0, 0, 0, 0);
  return result;
}

export const hasStates = (country: CountryDto, countries: CountryDto[]) => {
  if (countries) {
    var c = countries.find((s) => s?.codeAlpha2 === country?.codeAlpha2);
    if (c) {
      return c?.states?.length > 0;
    }
  }
  return false;
};

export const randomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const newGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
};