import React from 'react';
import styled from "styled-components";
import {
  Banner,
  COLORS,
  ComponentSStyling,
  ComponentTextStyle,
  Size,
  States,
  ToggleSwitch,
  ValidationMessage
} from "@laerdal/life-react-components";
import {UseControllerReturn, useFormContext} from "react-hook-form";
import moment from "moment/moment";
import {useUserContext} from "../../../../userContext";
import {AccessLevel, ServicePlan, ServicePlanTier} from "../../../../types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_400};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin-bottom: 8px;
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const Description = styled.div`
  white-space: pre-wrap;
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

interface Props {
  title: string;
  description: React.ReactNode;
  banner?: React.ReactNode;
  action?: React.ReactNode;
}

export const SubscriptionActionBlock = ({title, description, banner, action}: Props) => {
  return (
    <Wrapper>
      <Group>
        <Header>{title}</Header>
        <Description>{description}</Description>
      </Group>
      {banner}
      <Actions>
        {action}
      </Actions>
    </Wrapper>
  )
}