import React, {useEffect, useState} from 'react';
import {
  COLORS,
  DropdownFilter,
  DropdownItem,
  InputLabel,
  ModalDialog,
  Size,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Organization} from '../../../types';
import {useDebounce} from 'use-debounce';
import Api from '../../../utils/api';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Description = styled.p`
  margin: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

interface OrganizationSelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  disabled?: string[];
  organization?: Organization;
  setOrganization: (orgId: string | undefined) => void;
}

export const OrganizationSelectModal: React.FunctionComponent<OrganizationSelectModalProps> = ({
  isOpen,
  onClose,
  disabled,
  organization,
  setOrganization,
}) => {
  const {t} = useTranslation('Organization');

  const [selectedOrganization, setSelectedOrganization] = React.useState<Organization | undefined>(undefined);
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const [dropdownList, setDropdownList] = useState<DropdownItem[]>([]);

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [debounce] = useDebounce(searchTerm, 500);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  const mapOrganizationToDropdownItem = (a: Organization) => {
    return {
      value: a.id,
      displayLabel: a.name + (a.customerNo ? ` (${a.customerNo})` : ''),
      noteLabel: a.memberCount + ' ' + t('users') + ' | ' + a.address?.city + ', ' + a.address?.country?.name,
      disabled: disabled?.includes(a.id),
    } as DropdownItem;
  };

  const queryOrganizations = () => {
    abortController?.abort();
    const controller = new AbortController();
    const signal = controller.signal;

    setAbortController(controller);
    setLoading(true);

    Api.FindOrganizations(searchTerm ?? '', 0, 10, undefined, undefined, signal)
      .then((organizations) => {
        setOrganizations(organizations.records);
      })
      .finally(() => !signal.aborted && setLoading(false));
  };

  useEffect(() => {
    setSelectedOrganization(organization);
  }, [organization]);

  useEffect(() => {
    setDropdownList(organizations.map(mapOrganizationToDropdownItem));
  }, [organizations]);

  useEffect(() => {
    isOpen && queryOrganizations();
  }, [debounce]);

  useEffect(() => {
    if (isOpen) {
      if (organization) {
        setDropdownList([mapOrganizationToDropdownItem(organization)]);
        setSelectedOrganization(organization);
      } else {
        queryOrganizations();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    isOpen && !selectedOrganization?.id && queryOrganizations();
  }, [selectedOrganization?.id]);


  const close = () => {
    setSelectedOrganization(undefined);
    onClose();
  };
  const submit = () => {
    setOrganization(selectedOrganization?.id);
    onClose();
  };

  return (
    <ModalDialog isModalOpen={isOpen}
                 submitAction={submit}
                 closeAction={close}
                 size={Size.Medium}
                 title={t('Select organization')}
                 buttons={[
                   {
                     variant: 'tertiary',
                     text: t('Cancel'),
                     action: close,
                   },
                   {
                     variant: 'primary',
                     text: t('Select'),
                     action: submit,
                     disabled: !selectedOrganization,
                   },
                 ]}>
      <Wrapper>
        <Description>{t('Use the field below to search for a particular organization record.')}</Description>
        <InputWrapper>
          <InputLabel inputId={'organization'} text={t('Select organization')}/>
          <DropdownFilter id={'organization'}
                          list={dropdownList}
                          loading={loading}
                          size={Size.Medium}
                          scrollable={true}
                          value={selectedOrganization?.id}
                          onInputChange={(value) => setSearchTerm(value)}
                          onSelect={value => {
                            const item = organizations.find(a => a.id === value);
                            setSelectedOrganization(item);
                          }}
          />
        </InputWrapper>
      </Wrapper>

    </ModalDialog>
  );
};

export default OrganizationSelectModal;