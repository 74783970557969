import React, {Dispatch, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {BREAKPOINTS, ModalDialog, Size, ToastColor, ToastPosition} from '@laerdal/life-react-components';

import Api from '../../../utils/api';
import {Organization, OrganizationMember} from '../../../types';
import {ActionType} from '../OrganizationPage';
import {useMediaQuery} from 'react-responsive';
import {useToastContext} from '../../../userContext';

type RemoveMemberProps = {
  organization: Organization;
  closeModal: any;
  isModalOpen: boolean;
  organizationId: string;
  member: OrganizationMember | null;
  dispatch: Dispatch<ActionType>;
};

const RemoveMemberModal: React.FunctionComponent<RemoveMemberProps> = ({
                                                                         organization,
                                                                         closeModal,
                                                                         isModalOpen,
                                                                         organizationId,
                                                                         member,
                                                                         dispatch
                                                                       }: RemoveMemberProps) => {
  const {t} = useTranslation('Organization');
  const [busy, setBusy] = useState<boolean>(false);

  const {addToast} = useToastContext();
  const isMediumScreen = useMediaQuery({query: BREAKPOINTS.MEDIUM.replace('@media ', '')});

  const removeMember = (orgId: string, memberEmail: string) => {
    setBusy(true);
    Api.RemoveMemberFromOrganization(orgId, memberEmail)
      .then(() => {
        if (organization?.members.find((m) => m.user.email === memberEmail)) {
          const newMembers = organization?.members.filter((m) => m.user.email !== memberEmail);
          dispatch({type: 'members', value: [...newMembers], field: 'members'});
        } else {
          const newInvitations = organization?.invitations.filter((m) => m.email !== memberEmail);
          dispatch({type: 'invitations', value: [...newInvitations], field: 'invitations'});
        }
        setBusy(false);
        closeModal();
        addToast(t('Member was removed successfully.'), {
          color: ToastColor.GREEN,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      })
      .catch((error: any) => {
        closeModal();
        setBusy(false);
        console.error(error);
        addToast(t('There was a problem removing the member.'), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });
      });
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeAction={closeModal}
      size={Size.Large}
      title={t('Remove from organization')}
      buttons={
        [
          {
            id: 'cancel',
            variant: 'tertiary',
            text: t('No, cancel'),
            disabled: busy,
            action: closeModal,
          },
          {
            id: 'save',
            action: (e: any) => {
              e.preventDefault();
              if (!busy) {
                removeMember(organizationId, member?.user?.email!);
              }
            },
            loading: busy,
            variant: 'critical',
            text: t('Yes, remove'),
            disabled: busy
          },
        ] as any
      }>
      <>
          <p><strong>{member?.user?.email}</strong></p>
          <p>{t('This person will no longer be able to see product information, and will lose access to all subscription licenses and content associated with this organization.')}</p>
          <p>{t('Are you sure you want to remove this person from {{organization}}?', {organization: organization.name})}</p>
      </>
    </ModalDialog>
  );
};

export default RemoveMemberModal;
