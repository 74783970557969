/**
 * Import React libraries.
 */
import React from "react";

/**
 * Import third-party libraries.
 */
import { useTranslation } from "react-i18next";
import { BREAKPOINTS, InputLabel, ModalDialog, Size } from "@laerdal/life-react-components";
import { useMediaQuery } from "react-responsive";

/**
 * Custom types.
 */
type CreateLicenseProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitting: boolean;
  owner: string;
  invitationWarning: boolean;
};

const ConfirmCreateLicenseModal: React.FunctionComponent<CreateLicenseProps> = ({
                                                                                  isOpen,
                                                                                  onClose,
                                                                                  onSubmit,
                                                                                  submitting,
                                                                                  invitationWarning,
                                                                                  owner
                                                                                }: CreateLicenseProps) => {
  const { t } = useTranslation("OrganizationServices");

  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace("@media ", "") });

  return (
    <ModalDialog
      isModalOpen={isOpen}
      submitAction={onSubmit}
      closeAction={onClose}
      title={t("Confirm license")}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={
        [
          {
            id: "cancel",
            variant: "tertiary",
            text: t("No, cancel"),
            action: onClose
          },
          {
            id: "save",
            action: onSubmit,
            loading: submitting,
            variant: "primary",
            text: t("Yes, create license")
          }
        ] as any
      }>
      <>
        {owner && (
          <>
            <InputLabel inputId="owner" text={t("License owner")} />
            <p>
              <b>{owner}</b>
            </p>
            <p>{invitationWarning ? t("CONFIRM_LICENSE_DESCRIPTION_1") : t("CONFIRM_LICENSE_DESCRIPTION_3")}</p>
          </>
        )}
        <p>{t("CONFIRM_LICENSE_DESCRIPTION_2")}</p>
      </>
    </ModalDialog>
  );
};

export default ConfirmCreateLicenseModal;
