/**
 * Import React libraries.
 */
import React, {useEffect, useRef, useState} from "react";

/**
 * Import third-party libraries.
 */
import {
  BackButton,
  BasicDropdown,
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  DatepickerField,
  DropdownItem,
  IconButton,
  InputLabel,
  ListRow,
  LoadingIndicator,
  NumberField,
  PageWidth,
  Size,
  States,
  SystemIcons,
  TextField,
  ToggleSwitch,
  ValidationMessage
} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useToastContext, useUserContext} from "../../../userContext";
import {useNavigate, useParams} from "react-router";
import Api from "../../../utils/api";
import {LanguageDto, TermsOfServiceDocument, ToSVersion} from "../../../types";
import {ErrorToastOptions, SuccessToastOptions} from "../../../constants";
import {useController, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CreateToS, GetErrorMessage, GetLanguageCodes, ShouldShowError} from "./helpers/TermsOfServiceHelpers";
import {useTermsOfServiceValidator} from "./helpers/TermsOfServiceValidator";
import {TermsOfServiceFileUploadModal} from "./modals/TermsOfServiceFileUploadModal";
import moment from "moment";

type AddNewToSDocumentModalProps = {
  tosDocId: string;
  serviceId: string;
}

export interface ToSDocumentForm {
  name: string;
  minorVersion: number;
  majorVersion: number;
  gracePeriod: number;
  salesOrg: string;
  effectiveAt: Date;
  reconsent: boolean;
  files: ToSFile[];
}

export interface ToSFile {
  file?: any;
  fileId: string;
  fileName: string;
  languageCode: string;
  languageLabel: string;
  fallback: boolean;
}

const CustomPageWidth = styled.div`
  background-color: ${COLORS.neutral_100};
  min-height: 100vh;
  padding: 50px 100px;
  margin: 0px;

`;

const FilesSection = styled.div`
  margin: 20px 0px;
`;

const FormRow = styled.div`
  display: flex;
  gap: 16px;

  & > div {
    flex-grow: 1;
    width: 50%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

const FileInfoContainer = styled.div`
  position: relative;

  & > button {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .list-item-note {
    margin-right: 50px;
  }
`;

const ContentContainer = styled.div``;

const FormContainer = styled.form`
  padding: 24px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.neutral_200};
  border-radius: 8px;
`;

const ServiceName = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  }
  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  }
`;

const FormHeader = styled.div`
  margin-bottom: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const SwitchWidthFix = styled.div`
  > div {
    max-width: 100%;
  }
`;

const AddNewToSVersion = () => {
  // Globally used variables within the page
  const { t } = useTranslation("Services");
  const { salesOrgs } = useUserContext();
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [allToSDocs, setAllTosDocs] = useState<TermsOfServiceDocument[]>([]);
  const { addToast } = useToastContext();
  const params = useParams<AddNewToSDocumentModalProps>();
  const [languages, setLanguages] = useState<LanguageDto[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);

  const latestVersion = useRef<ToSVersion | null>(null);
  const tosDocument = useRef<TermsOfServiceDocument | null>(null);

  const schema = useTermsOfServiceValidator(latestVersion);

  const { handleSubmit, control, watch, setValue, reset, formState, trigger } = useForm<ToSDocumentForm>({
    defaultValues: {
      name: "",
      minorVersion: 0,
      majorVersion: 1,
      gracePeriod: 90,
      reconsent: false,
      salesOrg: "",
      effectiveAt: new Date(),
      files: []
    },
    resolver: yupResolver(schema)
  });

  const salesOrgController = useController({ control: control, name: "salesOrg" });
  const nameController = useController({ control: control, name: "name" });
  const gracePeriodController = useController({ control: control, name: "gracePeriod" });
  const reConsentController = useController({ control: control, name: "reconsent" });
  const majorVersionController = useController({ control: control, name: "majorVersion" });
  const minorVersionController = useController({ control: control, name: "minorVersion" });
  const effectiveAtController = useController({ control: control, name: "effectiveAt" });
  const filesController = useController({ control: control, name: "files" });


  const files = watch("files");
  const salesOrg = watch("salesOrg");
  const majorVersion = watch("majorVersion");
  const minorVersion = watch("minorVersion");

  useEffect(() => {
    trigger(["majorVersion", "minorVersion"]);
  }, [majorVersion, minorVersion]);

  useEffect(() => {
    if (files && files.length > 0) {
      const fallbackCount = files.filter(f => f.fallback).length;
      const fallback = files.map(a => a.fallback).lastIndexOf(true);
      if (fallbackCount === 0) {
        const newFiles = [...files];
        newFiles[0].fallback = true;
        setValue("files", newFiles);
      }

      if (fallbackCount > 1) {
        const newFiles = [...files.map((a, i) => ({ ...a, fallback: i === fallback }))];
        setValue("files", newFiles);
      }
    }
  }, [files]);

  useEffect(() => {
    GetLanguageCodes()
      .then(setLanguages)
      .then(() => Api.GetToS(params.serviceId!))
      .then(data => {
        setAllTosDocs(data);
        return Promise.resolve(data);
      })
      .then((data) => {
        if (params.tosDocId) {
          const curToS = data.find((x) => x.id == params.tosDocId);
          if (curToS) {
            tosDocument.current = new TermsOfServiceDocument(curToS);
            latestVersion.current = tosDocument.current.getLatestToSVersion();
          }
        }
      })
      .then(() => {
        if (latestVersion.current) {
          reset({
            name: tosDocument.current!.name,
            salesOrg: tosDocument.current!.salesOrganizationCode,
            reconsent: false,
            gracePeriod: 90,
            minorVersion: +latestVersion.current?.minorVersion,
            majorVersion: +latestVersion.current?.version,
            effectiveAt: moment(latestVersion.current?.effectiveAt).toDate(),
            files: latestVersion.current!.files.map(a => ({
              fileId: a.blobName,
              fileName: `${tosDocument.current?.name}-v${latestVersion.current?.version}.${latestVersion.current?.minorVersion}-${a.locale}`,
              languageCode: a.locale,
              languageLabel: languages.find(b => b.locale === a.locale)?.name ?? "",
              fallback: a.isFallback
            }))
          });
        }
      })
      .catch(e => console.log(e))
      .finally(() => setInitialLoad(false));
  }, []);

  const cancelClicked = () => {
    navigate(`/service/${params.serviceId}/documents`);
  };

  const createNewToS = (tosDocument: ToSDocumentForm) => {
    setLoading(true);

    //unfortunately files in the blob storage does not become immediately searchable, thus, temporarily
    //we need to wait 4 seconds before redirecting to service page
    CreateToS(tosDocument, allToSDocs, params.serviceId!, params.tosDocId)
      .then(() => addToast(t("New ToS document created successfully"), SuccessToastOptions))
      .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 4000)))
      .then(() => navigate(`/service/${params.serviceId}/documents`))
      .catch(() => addToast(t("Error occured while uploading files or saving ToS documents"), ErrorToastOptions))
      .finally(() => setLoading(false));
  };

  const latest = latestVersion.current;
  const minMajorVersion = latest && latest.version || 1;
  const minMinorVersion = minMajorVersion < majorVersion ? 0 : latest && latest.minorVersion + 1 || 0;

  return (
    <CustomPageWidth>
      <PageWidth $useMaxWidth={true} $maxWidth={648}>

        <BackButton size={Size.Small} onClick={() => navigate(`/service/${params.serviceId}/documents`)}>
          Back to service
        </BackButton>
        <ContentContainer>
          {initialLoad && <LoadingIndicator size={Size.Medium} />}
          {!initialLoad && (
            <FormContainer onSubmit={handleSubmit(createNewToS)}>
              <FormHeader>
                <ServiceName>
                  Service name
                </ServiceName>
                <h3>
                  Upload new terms
                </h3>
                <p>Multiple files can be assigned to each sales organization</p>
              </FormHeader>
              <InputLabel inputId="salesOrgDropdown" text="Sales organization" size={Size.Medium} />
              <BasicDropdown
                id="salesOrgDropdown"
                list={[
                  {
                    value: "global",
                    displayLabel: "Global",
                    locked: allToSDocs.some((y) => y.salesOrganizationCode == "global" && salesOrg != "global"),
                    suggestion: true
                  } as DropdownItem,
                  ...salesOrgs.map(
                    (x) =>
                      ({
                        value: x.code,
                        displayLabel: x.name,
                        locked: allToSDocs.some((y) => y.salesOrganizationCode == x.code && salesOrg != x.code)
                      } as DropdownItem)
                  )
                ]}
                pinTopItem={true}
                itemsType="normal"
                scrollable={true}
                readOnly={Boolean(params.tosDocId)}
                placeholder="Select ..."
                messageOnNoResults="No results found"
                size={Size.Medium}
                activeValidationMessage={salesOrgController.fieldState.error?.message}

                ref={salesOrgController.field.ref}
                value={salesOrgController.field.value}
                onSelect={salesOrgController.field.onChange}
                onBlur={salesOrgController.field.onBlur}
              />

              <FilesSection>
                <InputLabel inputId="" text="Upload files" size={Size.Medium} />
                {files.map((x, i) => (
                  <FileInfoContainer key={(x.file?.name || x.fileId) + "-" + i}>
                    <ListRow
                      mainText={x.file?.name || x.fileName}
                      secondaryText={x.languageLabel}
                      note={x.fallback ? "Primary" : ""}
                      icon={<SystemIcons.Document />}
                      size={Size.Small}
                    />
                    <IconButton
                      className="clearFile"
                      variant="secondary"
                      shape="circular"
                      action={() => setValue("files", [...files].filter((y) => y != x))}>
                      <SystemIcons.Clear size="24px" />
                    </IconButton>
                  </FileInfoContainer>
                ))}
                <Button
                  onClick={() => setShowUploadModal(true)}
                  variant="secondary">
                  Choose file(s)
                </Button>
                {
                  !!filesController.fieldState.error?.message &&
                  <ValidationMessage type={States.Invalid}>
                    {filesController.fieldState.error?.message}
                  </ValidationMessage>
                }
              </FilesSection>

              <InputLabel inputId="name" text="Name" size={Size.Medium} />
              <TextField
                id="Name"
                placeholder={t("e.g. ToS Germany")}
                autoComplete="none"
                validationMessage={nameController.fieldState.error?.message}
                {...nameController.field}
              />

              <FormRow>
                <div>
                  <InputLabel inputId="majorVersion" text="Major Version number" />
                  <NumberField
                    type={"NumberField"}
                    id="majorVersion"
                    minValue={minMajorVersion}
                    size={Size.Medium}
                    ref={majorVersionController.field.ref}
                    value={majorVersionController.field.value}
                    onChange={majorVersionController.field.onChange}
                    onBlur={majorVersionController.field.onBlur}

                    state={ShouldShowError(majorVersionController) ? States.Invalid : undefined}
                    note={GetErrorMessage(majorVersionController)}
                  />
                </div>

                <div>
                  <InputLabel inputId="minorVersion" text="Minor Version number" />
                  <NumberField
                    type={"NumberField"}
                    id="minorVersion"
                    size={Size.Medium}
                    minValue={minMinorVersion}

                    ref={minorVersionController.field.ref}
                    value={minorVersionController.field.value}
                    onChange={minorVersionController.field.onChange}
                    onBlur={minorVersionController.field.onBlur}

                    state={ShouldShowError(minorVersionController) ? States.Invalid : undefined}
                    note={GetErrorMessage(minorVersionController)}
                  />
                </div>
              </FormRow>

              <FormRow style={{ marginTop: "4px" }}>
                <div style={{ width: "50%" }}>
                  <InputLabel inputId="effectiveAt" text="Effective At" />
                  <DatepickerField
                    id="effectiveAt"
                    yearPicker
                    placeholder="Select..."
                    validationMessage={effectiveAtController.fieldState.error?.message}
                    value={effectiveAtController.field.value}
                    onChange={effectiveAtController.field.onChange}

                    ref={effectiveAtController.field.ref}
                    onBlur={effectiveAtController.field.onBlur}
                    name={effectiveAtController.field.name}
                  />
                </div>

                <div style={{ width: "50%" }}>
                  <InputLabel inputId="gracePeriod" text="Grace period (days)" />
                  <NumberField
                    size={Size.Medium}
                    type={"NumberField"}
                    id="gracePeriod"
                    readOnly={true}
                    {...gracePeriodController.field}
                  />
                </div>
              </FormRow>
              <SwitchWidthFix>
                <ToggleSwitch
                  id="reConsentNeeded"
                  label="Customers with active subscriptions must accept these terms within the grace period"
                  selected={reConsentController.field.value}
                  onToggle={reConsentController.field.onChange}
                  onBlur={reConsentController.field.onBlur}
                  ref={reConsentController.field.ref}
                />
              </SwitchWidthFix>

              <ButtonsContainer>
                <Button variant="tertiary" size={Size.Large} onClick={cancelClicked}>Cancel</Button>
                <Button variant="primary" size={Size.Large} type={"submit"} loading={loading}>
                  Add document
                </Button>
              </ButtonsContainer>
            </FormContainer>
          )}
        </ContentContainer>
        <TermsOfServiceFileUploadModal isModalOpen={showUploadModal}
                                       onClose={() => setShowUploadModal(false)}
                                       onSubmit={(file) => {
                                         setValue("files", [...files, file]);
                                         trigger("files");
                                       }}
                                       languages={languages}
                                       files={files} />
      </PageWidth>
    </CustomPageWidth>
  );
};

export default AddNewToSVersion;
