import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { AccessLevel, Invitation, Organization, OrganizationMember, OrganizationService, Service, User, UserProfile } from '../../types';
import { ActionType } from './UserPage';
import Api from '../../utils/api';
import { Banner, Button, COLORS, ComponentSStyling, ComponentTextStyle, Size, SystemIcons, ToggleSwitch } from '@laerdal/life-react-components';
import { ErrorToastOptions, SuccessToastOptions } from '../../constants';
import RemoveUserFromOrganizationModal from './components/modals/RemoveUserFromOrganizationModal';
import RemoveUserFromServiceModal from './components/modals/RemoveUserFromServiceModal';
import EditOrgServiceUserModal from './components/modals/EditOrgServiceUserModal';
import AddUserToOrgServiceModal from './components/modals/AddUserToServiceModal';
import SigninOnBehalfModal from './components/modals/SigninOnBehalfModal';
import { useToastContext, useUserContext } from '../../userContext';
import { AddUserToOrganizationModal } from './components/modals/AddUserToOrganizationModal';
import { UserOrganizationEntry } from './components/UserOrganizationEntry';
import { SendInvitationModal } from '../../components/modals/SendInvitationModal';
import EditPermissionsModal from '../../components/modals/EditPermissionsModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  gap: 32px;

  h6 {
    color: ${COLORS.neutral_600};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  button .button-content {
    width: max-content;
  }
`;

const MultiOrgAccess = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_400};
  > h2 {
    margin-bottom: 8px;
    ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  }
  > p {
    padding-bottom: 8px;
    ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  }
`;

interface UserOrganizationListPageParams {
  user: User;
  savedUser: User;
  setSavedUser: (user: User) => void;
  dispatch: Dispatch<ActionType>;
}

const UserOrganizationListPage = ({ user, dispatch }: UserOrganizationListPageParams) => {
  const { t } = useTranslation('User');
  const { addToast } = useToastContext();
  const { memberPermissions, accessLevel } = useUserContext();
  const [services, setServices] = useState<Service[]>([]);
  const [signinOnBehalfService, setSigninOnBehalfService] = useState<Service | undefined | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [orgIdToBeAddedTo, setOrgIdToBeAddedTo] = useState<string | null>(null);
  const [organizationToBeRemoved, setOrganizationToBeRemoved] = useState<Organization | null>(null);
  const [orgIdToBeEdited, setOrgIdToBeEdited] = useState<string | null>(null);
  const [orgServiceToBeRemoved, setOrgServiceToBeRemoved] = useState<OrganizationService | null>(null);
  const [orgServiceIdToBeEdited, setOrgServiceIdToBeEdited] = useState<string | null>(null);
  const [orgMemberToBeEdited, setOrgMemberToBeEdited] = useState<OrganizationMember>();
  const [isAddUserToOrganizationModalOpen, setIsAddUserToOrganizationModalOpen] = useState<boolean>(false);
  const [contactOrganizationToBeInvited, setContactOrganizationToBeInvited] = useState<Organization>();
  const [invitationToBeEdited, setInvitationToBeEdited] = useState<Invitation>();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const onOrganizationAction = (id: string, organization: Organization) => {
    switch (id) {
      case 'remove-from-organization':
        setOrganizationToBeRemoved(organization);
        break;
      case 'send-welcome-to-connect':
        sendOnboardingEmail(organization.id, user);
        break;
      case 'signin-on-behalf':
        const service = services.find((s) => s.id.toLowerCase() === process.env.REACT_APP_CONNECT_SERVICE_ID?.toLowerCase());
        setSigninOnBehalfService(service);
        break;
      case 'view-organization':
        const url = `/organization/${organization.id}`;
        window.open(url, '_blank');
        break;
      case 'edit-permissions':
        setOrgMemberToBeEdited(organization.members.find((a) => a.user.id === user.id));
        break;
      case 'invite-contact':
        console.log('inviting contact');
        setContactOrganizationToBeInvited(organization);
        break;
      case 'edit-invitation-permissions':
        setInvitationToBeEdited(user.invitations!.find((a) => a.organization!.id === organization.id)!.invitation!);
        break;
      case 'revoke-invitation':
        handleRevokeInvitation(organization);
        break;
    }
  };

  const onServiceAction = (id: string, organization: Organization, service?: OrganizationService) => {
    switch (id) {
      case 'add-to-new-service':
        setOrgIdToBeAddedTo(organization.id);
        break;
      case 'edit-access':
        setOrgIdToBeEdited(organization.id);
        setOrgServiceIdToBeEdited(service!.id);
        break;
      case 'remove-from-service':
        setOrganizationToBeRemoved(organization);
        setOrgServiceToBeRemoved(service!);
        break;
      case 'view-service':
        const url = '/organization/' + organization.id + '/license-management/' + service!.id;
        window.open(url, '_blank');
        break;
      case 'signin-on-behalf':
        const s = services.find((s) => s.id.toLowerCase() === service!.serviceId.toLowerCase());
        setSigninOnBehalfService(s);
        break;
    }
  };

  const getUser = () => {
    setLoading(true);
    return Api.GetUser(user.id)
      .then((u) => {
        dispatch({ type: 'organization', value: u });
      })
      .catch(() => {
        addToast(t('User does not exist'), ErrorToastOptions);
      })
      .finally(() => setLoading(false));
  };

  const getUserProfile = (id: string) => {
    return Api.GetUserProfile(id)
      .then((p) => {
        setUserProfile(p);
      })
      .catch(() => {
        //just ignore this
      });
  };

  const toggleSwitchOrganizationEnabled = (id: string) => {
    const profile: UserProfile = {
      lscMedicalSpecialities: userProfile?.lscMedicalSpecialities ?? [],
      lscNursingSpecialities: userProfile?.lscNursingSpecialities ?? [],
      lscNursingCourses: userProfile?.lscNursingCourses ?? [],
      lscPatientType: userProfile?.lscPatientType ?? [],
      lscSimulators: userProfile?.lscSimulators ?? [],
      switchOrganizationEnabled: !userProfile?.switchOrganizationEnabled,
    };
    return Api.UpdateUserProfile(id, profile)
      .then(() => {
        addToast(profile.switchOrganizationEnabled ? t('Multi-org access enabled.') : t('Multi-org access disabled.'), SuccessToastOptions);
        setUserProfile(profile);
      })
      .catch(() => {
        setLoading(false);
        addToast(profile.switchOrganizationEnabled ? t('Multi-org access could not be enabled.') : t('Multi-org access could not be disabled.'), ErrorToastOptions);
      });
  };

  const sendOnboardingEmail = (organizationId: string, user: User) => {
    setLoading(true);
    // Let's onboard the organization
    return Api.OnboardOrganization(organizationId, '', '', [], [user])
      .then(() => {
        addToast(t('Welcome email to Connect has been sent'), SuccessToastOptions);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        addToast(t('There was an error with welcoming the user to Connect'), ErrorToastOptions);
      });
  };

  const handleChangeCurrentOrganization = (organizationId: string) => {
    setLoading(true);
    Api.SetAsCurrentOrganization(user.id, organizationId)
      .then(() => {
        getUser();
        addToast(t('Current organization set successfully'), SuccessToastOptions);
      })
      .catch(() => {
        addToast(t('Current organization could not be set.'), ErrorToastOptions);
        setLoading(false);
      });
  };

  const handleRevokeInvitation = (organization: Organization) => {
    const invitation = user.invitations!.find((a) => a.organization!.id === organization.id)!.invitation;
    Api.UpdateOrganizationInvitation(organization?.id!, invitation?.code!, false, true)
      .then(() => addToast(t('Invitation was removed successfully from organization.'), SuccessToastOptions))
      .then(() => {
        dispatch({ type: 'remove-invitation', value: invitation.code });
      })
      .catch((error: any) => addToast(t('There was a problem removing the invitation from organization.'), ErrorToastOptions));
  };

  useEffect(() => {
    Api.GetServices()
      .then((services) => {
        setServices(services);
      })
      .finally(() => setLoading(false));
    getUserProfile(user.id);
  }, []);

  return (
    <>
      <Wrapper>
        <h3>{t('Organization access')}</h3>
        <ContentContainer>
          <ListHeader>
            <h6>{t('Manage access to organizations')}</h6>
            <Button variant={'primary'} size={Size.Large} icon={<SystemIcons.Add size="36px" />} width={'fit-content'} onClick={() => setIsAddUserToOrganizationModalOpen(true)}>
              {t('Add to new organization')}
            </Button>
          </ListHeader>
          {user.organizations.length === 0 && (
            <Banner
              size={Size.Small}
              icon={<SystemIcons.Information />}
              link={'/organization'}
              linkAction={(e) => {
                setIsAddUserToOrganizationModalOpen(true);
              }}
              linkText={'Search for an organization'}>
              {t('This person does not belong to any organization. Add this person by selecting an organization from the list.')}
            </Banner>
          )}
          {user.organizations.length > 1 && (
            <MultiOrgAccess>
              <h2>{t('Multi-org access')}</h2>
              <p> {t('Before enabling this option, make sure that the account data is correct for each organization listed below.')}</p>
              <ToggleSwitch
                id="multiorg"
                selected={userProfile?.switchOrganizationEnabled}
                disabled={accessLevel != AccessLevel.Full}
                onToggle={() => {
                  toggleSwitchOrganizationEnabled(user.id);
                }}
                size={Size.Small}
                label={`${t('Allow user to switch between their organizations')}`}
              />
            </MultiOrgAccess>
          )}
          {user.organizations.map((org) => (
            <UserOrganizationEntry
              user={user}
              organization={org}
              type={'member'}
              onAction={onOrganizationAction}
              onServiceAction={onServiceAction}
              onCurrentOrganizationChange={handleChangeCurrentOrganization}
            />
          ))}
          {user.invitations?.map((entry) => (
            <UserOrganizationEntry user={user} organization={entry.organization} type={'invitation'} onAction={onOrganizationAction} onServiceAction={onServiceAction} />
          ))}

          {user.contactOrganizations
            ?.filter((a) => !user.invitations?.find((b) => b.organization.id === a.id))
            .map((org) => (
              <UserOrganizationEntry user={user} organization={org} type={'contact'} onAction={onOrganizationAction} onServiceAction={onServiceAction} />
            ))}
        </ContentContainer>
      </Wrapper>
      <SendInvitationModal
        canAddImmediately={!!contactOrganizationToBeInvited}
        isOpen={!!contactOrganizationToBeInvited}
        onClose={() => setContactOrganizationToBeInvited(undefined)}
        user={user}
        onSuccessSentInvitation={getUser}
        organization={contactOrganizationToBeInvited}
      />

      <EditPermissionsModal
        closeModal={() => setOrgMemberToBeEdited(undefined)}
        isModalOpen={!!orgMemberToBeEdited}
        currentPermissions={orgMemberToBeEdited?.permissions?.map((x) => x.permissionId) ?? []}
        saveUpdatedPermissions={async (newPermissions: string[]) => {
          await Api.UpdateOrganizationMemberPermissions(orgMemberToBeEdited!.id, newPermissions);
          addToast(t('Member permissions updated successfully'), SuccessToastOptions);
          orgMemberToBeEdited!.permissions = newPermissions.map((x) => ({
            organizationMemberId: orgMemberToBeEdited!.id,
            permissionId: x,
          }));
        }}
        allPermissions={memberPermissions}
      />

      <RemoveUserFromOrganizationModal
        isModalOpen={organizationToBeRemoved !== null && orgServiceToBeRemoved === null}
        closeModal={() => setOrganizationToBeRemoved(null)}
        organization={organizationToBeRemoved}
        member={user.organizations.find((o) => o.id === organizationToBeRemoved?.id)?.members[0]}
        onSuccess={getUser}
      />

      <RemoveUserFromServiceModal
        isModalOpen={organizationToBeRemoved !== null && orgServiceToBeRemoved !== null}
        closeModal={() => {
          setOrganizationToBeRemoved(null);
          setOrgServiceToBeRemoved(null);
        }}
        orgId={organizationToBeRemoved?.id!}
        organizationService={orgServiceToBeRemoved}
        member={user.organizations.find((o) => o.id === organizationToBeRemoved?.id)?.services?.find((s) => s.id === orgServiceToBeRemoved?.id)?.members[0]}
        user={user}
        onSuccess={getUser}
      />

      <EditOrgServiceUserModal
        member={user.organizations.find((o) => o.id === orgIdToBeEdited)?.services?.find((s) => s.id === orgServiceIdToBeEdited)?.members[0]}
        isModalOpen={orgIdToBeEdited !== null && orgServiceIdToBeEdited !== null}
        closeModal={() => {
          setOrgIdToBeEdited(null);
          setOrgServiceIdToBeEdited(null);
        }}
        orgId={orgIdToBeEdited!}
        orgServiceId={orgServiceIdToBeEdited}
        orgService={services.find((a) => a.id === user.organizations.find((o) => o.id === orgIdToBeEdited)?.services?.find((s) => s.id === orgServiceIdToBeEdited)?.serviceId)}
        onSuccess={getUser}
      />

      <AddUserToOrgServiceModal
        closeModal={() => setOrgIdToBeAddedTo(null)}
        isModalOpen={orgIdToBeAddedTo !== null}
        organizationId={orgIdToBeAddedTo}
        userId={user.id}
        existingServices={user.organizations.find((o) => o.id === orgIdToBeAddedTo)?.services}
        onSuccess={getUser}
      />

      <SigninOnBehalfModal closeModal={() => setSigninOnBehalfService(null)} isModalOpen={signinOnBehalfService !== null} service={signinOnBehalfService} user={user} />

      <AddUserToOrganizationModal isOpen={isAddUserToOrganizationModalOpen} onClose={() => setIsAddUserToOrganizationModalOpen(false)} onSuccess={getUser} user={user} />

      <EditPermissionsModal
        closeModal={() => setInvitationToBeEdited(undefined)}
        saveUpdatedPermissions={async (newPermissions: string[]) => {
          const organization = user.invitations?.find((x) => x.invitation.code == invitationToBeEdited!.code)?.organization!;
          await Api.UpdateOrganizationInvitation(organization.id, invitationToBeEdited!.code, false, false, true, newPermissions).then(() => {
            addToast(t('Invitation permissions updated successfully'), SuccessToastOptions);
            invitationToBeEdited!.permissions = newPermissions;
          });
        }}
        isModalOpen={!!invitationToBeEdited}
        currentPermissions={invitationToBeEdited?.permissions}
        allPermissions={memberPermissions}
      />
    </>
  );
};

export default UserOrganizationListPage;
