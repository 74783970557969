import React, { useContext, useEffect, useState } from 'react';
import { AddOrganizationMemberInvitation, Organization, OrganizationInvitationResponse, OrganizationRecordWrapper, User } from '../../../../types';
import { Checkbox, COLORS, DropdownFilter, DropdownItem, InputLabel, ModalDialog, RadioButton, Size, ToastContext } from '@laerdal/life-react-components';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Api from '../../../../utils/api';
import { useDebounce } from 'use-debounce';
import { ErrorToastOptions, SuccessToastOptions } from '../../../../constants';
import { useToastContext } from '../../../../userContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioButtonWrapper = styled.div`
  &.active {
    background: ${COLORS.neutral_20};
    border: 1px solid ${COLORS.neutral_200};
    border-radius: 4px;
  }
`;

interface Props {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

export const AddUserToOrganizationModal = ({ isOpen, onClose, user, onSuccess }: Props) => {
  const { t } = useTranslation('User');

  const { addToast } = useToastContext();

  const [step, setStep] = useState<'organization' | 'options'>('organization');
  const [dropdownItems, setDropdownItems] = useState<(DropdownItem & { organization: Organization })[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [filterDebounced] = useDebounce(filter, 200);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [organization, setOrganization] = useState<Organization>();
  const [removeFromOtherOrganizations, setRemoveFromOtherOrganizations] = useState<boolean>(false);
  const [addImmediately, setAddImmediately] = useState<boolean>(true);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [sendInvitation, setSendInvitation] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();

    if (isOpen) {
      setLoading(true);
      Api.FindOrganizations(filterDebounced, undefined, undefined, undefined, undefined, controller.signal)
        .then((organizations: OrganizationRecordWrapper) => {
          setDropdownItems(
            organizations?.records?.map((organization: Organization) => ({
              value: organization.id,
              displayLabel: organization.name + (organization.customerNo ? ` (${organization.customerNo})` : ''),
              disabled: !!user.organizations?.find((a) => a.id === organization.id),
              organization: organization,
            })),
          );
        })
        .catch((error: Error) => {
          if (!controller.signal.aborted) {
            addToast(t('There was an error retrieving organizations. Try again later.'), ErrorToastOptions);
          }
        })
        .finally(() => !controller.signal.aborted && setLoading(false));
    }

    return () => controller.abort();
  }, [filterDebounced, isOpen]);

  const close = () => {
    setOrganization(undefined);
    setStep('organization');
    setRemoveFromOtherOrganizations(false);
    setAddImmediately(true);
    setSendInvitation(false);
    setSendEmail(false);
    setFilter('');
    onClose();
  };

  const submit = () => {
    setSubmitting(true);

    // Send invitation
    Api.AddUserToOrganization(organization!.id, user.id, user.email, sendEmail, addImmediately, removeFromOtherOrganizations)
      .then(() =>
        onSuccess().then(() => {
          if (addImmediately) {
            addToast(
              t('Added {{email}} to {{organization}}', {
                email: user.email,
                organization: organization?.name,
              }),
              SuccessToastOptions,
            );
            close();
          } else {
            addToast(t('{{email}} invited to the organization successfully', { email: user.email }), SuccessToastOptions);
            close();
          }
        }),
      )
      .catch(() => {
        addToast(t('Something went wrong while inviting member'), ErrorToastOptions);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <ModalDialog
      isModalOpen={isOpen}
      closeAction={() => close()}
      width={'640px'}
      size={Size.Medium}
      title={user.organizations.length > 0 ? t('Add to another organization') : t('Add to organization')}
      backButton={step === 'options' ? () => setStep('organization') : undefined}
      submitAction={step === 'organization' ? () => setStep('options') : submit}
      state={step === 'organization' && removeFromOtherOrganizations ? 'warning' : undefined}
      note={
        step === 'organization' && removeFromOtherOrganizations
          ? t('User will lose access to other organizations and be added as a member of the selected organization only. This action cannot be undone.')
          : undefined
      }
      buttons={[
        {
          text: t('Cancel'),
          variant: 'tertiary',
          disabled: submitting,
          action: () => close(),
        },
        {
          text: step === 'organization' ? t('Next') : t('Add to organization'),
          variant: 'primary',
          disabled: !organization,
          loading: submitting,
          action: step === 'organization' ? () => setStep('options') : submit,
        },
      ]}>
      {step === 'organization' && (
        <Wrapper>
          <InputLabel inputId={'organization'} text={t('Select organization')} />
          <DropdownFilter
            list={dropdownItems}
            placeholder={t('Search by name or number')}
            onInputChange={setFilter}
            size={Size.Medium}
            loading={loading}
            scrollable={true}
            onSelect={(value) => setOrganization(dropdownItems.find((a) => a.value === value)!.organization)}
            value={organization?.id}
          />
          {user.organizations.length > 0 && (
            <Checkbox selected={removeFromOtherOrganizations} select={setRemoveFromOtherOrganizations} 
              label={t('Remove user from all other organizations')} />
          )}
        </Wrapper>
      )}

      {step === 'options' && (
        <Wrapper>
          <p>
            <strong>{user.email}</strong> will be added as a member of <strong>{organization?.name}</strong>
          </p>
          <div>
            <RadioButtonWrapper className={addImmediately ? 'active' : undefined}>
              <RadioButton
                selected={addImmediately}
                select={() => {
                  setAddImmediately(true);
                  setSendInvitation(false);
                }}
                label={t('Add to organization immediately')}
              />
              {addImmediately && <Checkbox selected={sendEmail} select={setSendEmail} style={{ marginLeft: '40px' }} label={t('Notify user by email')} />}
            </RadioButtonWrapper>
            <RadioButton
              selected={sendInvitation}
              select={() => {
                setAddImmediately(false);
                setSendInvitation(true);
                setSendEmail(false);
              }}
              label={t('Send email inviting user to join organization')}
            />
          </div>
        </Wrapper>
      )}
    </ModalDialog>
  );
};
