import React, { useContext } from "react";
import { BREAKPOINTS, COLORS, HyperLink, PageWidth } from "@laerdal/life-react-components";
import { useUserContext } from "../../userContext";
import { useTranslation } from "react-i18next";
import styled from "styled-components";


const StyledPageWidth = styled(PageWidth)`
  display: flex;
  flex-direction: column;
  a {
    width: max-content;
  }
`;

const OverviewPage = () => {
  const { t } = useTranslation("User");
  const { firstName } = useUserContext();
  return (
    <StyledPageWidth $useMaxWidth={true} $maxWidth={1600}>
      <h1>
        {t("Welcome")}, {firstName}
      </h1>
      <p>{t("This is the new version of Laerdal Cloud Control")}</p>
      <HyperLink variant={"default"}
                 target={"_blank"}
                 rel={"noopener noreferrer"}
                 href={"https://laerdal.sharepoint.com/sites/CloudControl/SitePages/Training-Materials.aspx"}>
        {t("User guide")}
      </HyperLink>
      <HyperLink variant={"default"}
                 href={"mailto:cloudcontrol@laerdal.com"}>
        {t("Report a problem")}
      </HyperLink>
    </StyledPageWidth>
  );
};

export default OverviewPage;
