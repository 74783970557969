import { memberFullAccessPermission } from '../../constants';
import {
  Organization,
  OrganizationMergeRequest,
  OrganizationMergeService,
  OrganizationMergeValidationResponse,
  OrganizationService,
} from '../../types';

export interface OrganizationMergeInstanceLimitError {
  serviceName: string;
  instanceLimit: number;
  expectedInstances: number;
}

export const FormatMergeErrors = (
  validation: OrganizationMergeValidationResponse,
  request: OrganizationMergeRequest,
  source: Organization,
  target: Organization,
) =>
  validation.errors.filter(a => a.code === 'LimitExceeded').map(e => {
    const serviceId = e.context;
    const requestService = request.mergeServices.find(s => s.serviceId === serviceId)!;
    const targetService = target.services!.find(s => s.serviceId === serviceId)!;
    const currentCount = (targetService.members.length ?? 0) + (targetService.invitations?.filter(a => a.isActive).length ?? 0);
    const additionalCount = (requestService.mergeUsers.length ?? 0) + (requestService.mergeInvitations.length ?? 0);
    const expectedCount = currentCount + additionalCount;

    return {
      serviceName: targetService.name,
      instanceLimit: targetService.subscription!.maxSubscriptionInstances!,
      expectedInstances: expectedCount,
    };
  });

export const BuildMergeRequestObject = (source: Organization, target: Organization, ownerPermissions: string[], userPermissions: string[]) => {
  const targetEmails =
    (target?.invitations.filter(a => a.isActive).map(a => a.email) ?? [])
      .concat(target?.members.map(a => a.user?.email) ?? []);

  const invitations =
    source?.invitations.filter(a => !targetEmails.includes(a.email) && a.isActive)
      .map(a =>
        a.permissions.find(x => x == memberFullAccessPermission) != undefined
        ? { email: a.email, permissions: ownerPermissions }
        : { email: a.email, permissions: userPermissions }) ?? [];

  const targetUsers = target?.members.filter(a => !!a.user?.id).map(a => a.user.id) ?? [];

  const users =
    source?.members.filter(a => !targetUsers?.includes(a.user?.id))
      .map(a =>
        a.permissions?.find(x => x.permissionId == memberFullAccessPermission) != undefined
        ? {userId: a.user!.id, permissions: ownerPermissions}
        : {userId: a.user!.id, permissions: userPermissions},
      );

  const targetServices: { [key: string]: OrganizationService } =
    target?.services?.reduce(
      (prev, cur) => ({...prev, [cur.serviceId!]: cur}),
      {},
    )
    || {};

  const services: OrganizationMergeService[] = source?.services?.map(sourceService => {
    const targetService = targetServices[sourceService.serviceId!];
    const targetServiceEmails = (targetService?.invitations?.filter(a => a.isActive).map(b => b.email) ?? [])
      .concat(targetService?.members?.map(b => b.user?.email) ?? []);

    const invitations = sourceService.invitations?.filter(b => !targetServiceEmails.includes(b.email) && b.isActive)
      .map(b => ({email: b.email, serviceRoleId: b.role?.id})) ?? [];

    const targetUsers = targetService?.members?.filter(b => !!b.user?.id).map(b => b.user.id) ?? [];
    
    const users =
      sourceService.members?.filter(b => !targetUsers?.includes(b.user?.id)).map(b => ({
        userId: b.user?.id,
        serviceRoleId: b.role?.id,
      })) ?? [];

    return {
      serviceId: sourceService.serviceId,
      mergeInvitations: invitations,
      mergeUsers: users,
    };
  }) ?? [];

  return {
    sourceId: source.id!,
    targetId: target.id!,
    mergeInvitations: invitations,
    mergeUsers: users,
    mergeServices: services,
  } as OrganizationMergeRequest;
};
