/**
 * Import React libraries.
 */
import React, {useEffect, useState} from "react";

/**
 * Import third-party libraries.
 */
import {Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router";
import {
  BackButton,
  COLORS,
  ComponentLStyling,
  ComponentTextStyle,
  HorizontalTabs,
  Size,
  ToastColor,
  ToastPosition
} from "@laerdal/life-react-components";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

/**
 * Import custom utils.
 */
import Api from "../../../utils/api";

/**
 * Import custom pages.
 */
import OrgLicenseDetailsPage from "./OrgLicenseDetailsPage";
import OrgUserAccessPage from "./OrgUserAccessPage";
import OrgSettingsPage from "./OrgSettingsPage";

/**
 * Import custom types.
 */
import {
  CountryConfiguration,
  InstanceLimitType,
  Organization,
  OrganizationService,
  Service,
  ServiceRole,
  SessionDTO,
  Subscription
} from "../../../types";
import OrgLicenseSessionsPage from "./OrgLicenseSessionsPage";
import {useToastContext, useUserContext} from "../../../userContext";
import {ErrorToastOptions} from "../../../constants";

/**
 * Add custom styling.
 */
const LicenseManagementCard = styled.div`
  max-width: 640px;
  border: 1px solid ${COLORS.neutral_200};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LicenseManagementHeader = styled.div`
  display: flex;
  flex-direction: column;

  span {
    ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  }
`;

const LicenseManagementBody = styled.div``;


/**
 * Add custom types.
 */
type TParams = { id: string };

interface HorizontalTab {
  value: string;
  selected: boolean;
  to: string;
  disabled?: boolean;
}

interface OrgLicenseManagementPageProps {
  organizationId: string;
}

const OrgLicenseManagementPage = ({organizationId}: OrgLicenseManagementPageProps) => {
  // Globally used parameters within the component
  const {t} = useTranslation("OrganizationServices");
  const {addToast} = useToastContext();
  const {selfServiceServices, countryConfigurations} = useUserContext();
  const [tabs, setTabs] = useState<HorizontalTab[]>([]);
  const [organization, setOrganization] = useState<Organization>();
  const [serviceRoles, setServiceRoles] = useState<ServiceRole[]>();
  const [organizationService, setOrganizationService] = useState<OrganizationService>();
  const [subscription, setSubscription] = useState<Subscription | undefined>();
  const params = useParams<TParams>();

  const [service, setService] = useState<Service>();
  const [sessions, setSessions] = useState<SessionDTO[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [existsInSF, setExistsInSF] = useState<boolean | undefined>(undefined);

  const licenseManagementUrl = `/organization/${organization?.id}/license-management`;

  /**
   * Let's retrieve details about the license.
   */
  const getOrganizationService = () => {
    const controller = new AbortController();
    Api.GetOrganizationService(organizationId, params.id!, controller.signal)
      .then((orgService: OrganizationService) => {
        setOrganizationService(orgService);

        if (orgService.subscription) {
          return Api.GetSubscription(organizationId, orgService.subscription.id, controller.signal).then(sub => setSubscription(sub));
        } else {
          return Promise.resolve();
        }
      })
      .catch(() => {
        if (!controller.signal.aborted) {
          addToast(t("Organization service could not be found."), ErrorToastOptions);

          // Navigate to license management page
          navigate("../");
        }
      });

    return () => controller.abort();
  };


  /**
   * Let's load and set tabs.
   */
  useEffect(() => {
    if (loading) {
      return;
    }

    const tabs = [];
    tabs.push({
      value: t("License details"),
      to: `${licenseManagementUrl}/${organizationService?.id}/license-details`,
      disabled: false,
      selected: location.pathname.split("/").includes("license-details")
    });

    if (service?.instanceLimitType == InstanceLimitType.Session) {
      tabs.push({
        value: t("Active seats"),
        to: `${licenseManagementUrl}/${organizationService?.id}/sessions`,
        disabled: false,
        selected: location.pathname.split("/").includes("sessions")
      });
    }

    tabs.push({
      value: t("User access"),
      to: `${licenseManagementUrl}/${organizationService?.id}/user-access`,
      disabled: false,
      selected: location.pathname.split("/").includes("user-access")
    });

    if (service?.id.toLowerCase() != process.env.REACT_APP_ECOURSES_SERVICE_ID?.toLowerCase()) {
      tabs.push({
        value: t("Settings"),
        to: `${licenseManagementUrl}/${organizationService?.id}/settings`,
        disabled: false,
        selected: location.pathname.split("/").includes("settings")
      });
    }

    setTabs(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, location]);

  /**
   * Let's retrieve organization.
   */
  useEffect(() => {
    const controller = new AbortController();

    Api.GetOrganization(organizationId, controller.signal)
      .then((org: Organization) => setOrganization(org))
      .catch(() => {
        if (!controller.signal.aborted) {
          addToast(t("Organization could not be found."), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE
          });

          // Navigate to license management page
          navigate("../");
        }
      });

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * Set loading to true when all necessary data is loaded.
   */
  useEffect(() => setLoading(!service || !organizationService || !organization), [service, organization, organizationService]);

  /**
   * Retrieve service details.
   */
  useEffect(() => {
    if (!organizationService?.serviceId) {
      return;
    }

    const controller = new AbortController();

    Api.GetService(organizationService!.serviceId, controller.signal)
      .then((a) => {
        setService(a);
        setServiceRoles(a.serviceRoles);

        if (a.instanceLimitType == InstanceLimitType.Session) {
          let interval = setInterval(() => {
            Api.GetSessions(organizationId, organizationService.subscription!.id, controller.signal)
              .then((response) => {
                setSessions(response);
              })
              .catch(() => {
                clearInterval(interval);
                if (!controller.signal.aborted) {
                  addToast(t("Could not retrieve sessions."), ErrorToastOptions);
                }
              });
          }, 2000);
        }
      })
      .catch(() => {
        if (!controller.signal.aborted) {
          addToast(t("Service could not be found."), ErrorToastOptions);

          // Navigate to license management page
          navigate("../");
        }
      });

    return () => controller.abort();
  }, [organizationService]);

  /**
   * Let's retrieve details about the license.
   */
  useEffect(() => getOrganizationService(), []);

  useEffect(() => {
    if (organization?.customerNo) {
      Api.CheckOrganizationCreatedFromSalesforce(organization.customerNo)
        .then((value: boolean) => {
          setExistsInSF(value);
        })
        .catch((error: any) => {
          console.log(error);
          setExistsInSF(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  /**
   * Switches the current tab in license management page.
   * @param url - New url to which to navigate.
   */
  const switchTab = (url: string): void => {
    // Update selected tab
    setTabs([
      ...tabs.map((tab: HorizontalTab) => {
        if (tab.to !== url && tab.selected) {
          tab.selected = false;
        } else if (tab.to === url) {
          tab.selected = true;
        }

        return tab;
      })
    ]);

    // Let's navigate to the according page
    navigate(url);
  };

  return (
    <>
      <BackButton size={Size.Small} onClick={() => navigate(licenseManagementUrl)}>
        {t("Back to License management")}
      </BackButton>
      {!loading && (
        <LicenseManagementCard>
          <LicenseManagementHeader>
            <span>{organization?.name}</span>
            <h3>{organizationService?.name}</h3>
          </LicenseManagementHeader>

          <HorizontalTabs fullWidth={true}
                          onTabChange={(to: string) => switchTab(to)}
                          size={Size.Large}
                          variant={"floating"}
                          tabs={tabs}/>

          <LicenseManagementBody>
            <Routes>
              <Route
                path={"license-details"}
                element={
                  <OrgLicenseDetailsPage
                    organizationService={organizationService}
                    organization={organization}
                    subscription={subscription}
                    setSubscription={setSubscription}
                    service={service!}
                  />
                }
              />
              <Route
                path={"sessions"}
                element={
                  <OrgLicenseSessionsPage
                    service={service!}
                    sessions={sessions}
                    organization={organization!}
                    organizationService={organizationService!}
                    url={`/organization/${organization?.id}/license-management/${organizationService?.id}/sessions`}
                  />
                }>
                <Route path=""/>
                <Route path=":page"/>
              </Route>
              <Route
                path={"user-access"}
                element={
                  <OrgUserAccessPage
                    service={service}
                    organizationService={organizationService || undefined}
                    url={`/organization/${organization?.id}/license-management/${organizationService?.id}/user-access`}
                    organization={organization}
                    getOrganizationService={getOrganizationService}
                    subscription={subscription}
                    serviceRoles={serviceRoles}
                    sessions={sessions}
                    existsInSF={existsInSF}
                  />
                }>
                <Route path=""/>
                <Route path=":page"/>
              </Route>
              <Route
                path={"settings"}
                element={<OrgSettingsPage getOrganizationService={getOrganizationService} organization={organization}
                                          service={organizationService || undefined}/>}
              />
              <Route index element={<Navigate replace
                                              to={`/organization/${organization?.id}/license-management/${organizationService?.id}/license-details`}/>}></Route>
            </Routes>
          </LicenseManagementBody>
        </LicenseManagementCard>
      )}
    </>

  );
};

export default OrgLicenseManagementPage;
