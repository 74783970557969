import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Helmet from 'react-helmet';
import {
    ActionChip,
    Button,
    COLORS,
    DropdownFilter,
    InputLabel,
    ListRow,
    Note,
    PageWidth,
    Size,
    SystemIcons,
    ToastColor,
    ToastPosition,
    UpperCase
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {AccessLevel, CountryDto, LanguageDto, UpdateUserLocalizationDTO, User} from '../../types';
import {capitalizeFirstLetter} from '../../utils/functions';
import AddLanguageModal from './AddLanguageModal';
import Api from '../../utils/api';
import {useLocation} from 'react-router';
import {useToastContext, useUserContext} from '../../userContext';
import PromptOnLeave from '../../components/PromptOnLeave';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  gap: 32px;
  
  h6 {
    color: ${COLORS.neutral_600};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;    
  flex-direction: row;
  gap: 16px;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OtherLanguagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.neutral_600};    
`;

interface LanguageAndRegionPage {
    user: User;
}

const LanguageAndRegionPage = ({user}: LanguageAndRegionPage) => {
    const {t} = useTranslation('Preferences');

    const {addToast} = useToastContext();
    const {accessLevel} = useUserContext();

    const [language, setLanguage] = useState<LanguageDto | null | undefined>(null);
    const [country, setCountry] = useState<CountryDto | null | undefined>(null);
    const [otherLanguages, setOtherLanguages] = useState<LanguageDto[]>([]);

    const [isAddLanguageModalOpen, setIsAddLanguageModalOpen] = useState<boolean>(false);

    const [countries, setCountries] = useState<CountryDto[]>([]);
    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const [addLanguageList, setAddLanguageList] = useState<string[]>([]);

    const [localization, setLocalization] = useState<UpdateUserLocalizationDTO | null | undefined>(null);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [shouldBlockNavigation, setsShouldBlockNavigation] = useState<boolean>(false);
    const location = useLocation();
    const readOnly = accessLevel != AccessLevel.Full;

    useEffect(() => {
        Api.GetCountries().then((countries: CountryDto[]) => {
            setCountries(countries);
        });
        Api.GetLanguages().then((languages: LanguageDto[]) => {
            setLanguages(languages);
        });
        Api.GetUserLocalization(user?.id)
            .then((response: UpdateUserLocalizationDTO) => {
                setLocalization(response);
            })
            .catch(() => {
                addToast(t('Fetching language and region preferences failed'), {
                    color: ToastColor.RED,
                    showCloseButton: true,
                    autoClose: true,
                    position: ToastPosition.TOPMIDDLE,
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Let's figure out which language user has selected
        let language = languages.find((l: LanguageDto) => l.locale.toLowerCase() === localization?.locale?.toLowerCase());

        // Let's see if language  was found
        if  (!language && (localization && localization.locale && localization.locale.split("-").length > 0)) {
            language = languages.find((l: LanguageDto)  => l.locale.toLowerCase() === localization?.locale?.split("-")[0].toLowerCase());
        }

        // Let's set the language
        setLanguage(language);
    }, [languages, localization?.locale]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCountry(countries.find((c: CountryDto) => c.codeAlpha2.toLowerCase() === localization?.country?.toLowerCase()));
    }, [countries, localization?.country]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const list: LanguageDto[] = [];
        if (localization?.additionalLanguages) {
            localization?.additionalLanguages.split(';').forEach((element) => {
                const foundLang = languages.find((l: LanguageDto) => l.locale.toLowerCase() === element.toLowerCase());
                if (foundLang) {
                    list.push(foundLang);
                }
            });
        }
        setOtherLanguages(list);
    }, [languages, localization?.additionalLanguages]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const langs = [...languages].filter((l) => otherLanguages.findIndex((o) => o.locale === l.locale) === -1 && l.locale !== language?.locale);
        setAddLanguageList(langs.map((l) => capitalizeFirstLetter(l?.name)));
    }, [languages, localization?.locale, otherLanguages]); // eslint-disable-line react-hooks/exhaustive-deps

    const addOtherLanguage = (lang: string) => {
        const newLang = languages.find((l: LanguageDto) => l.name.toLowerCase() === lang.toLowerCase());
        if (newLang) {
            const newLanguages = [...otherLanguages, newLang];
            const request: UpdateUserLocalizationDTO = {
                additionalLanguages: newLanguages.map((o) => o.locale).join(';'),
                country: country?.codeAlpha2 ?? '',
                locale: language?.locale ?? '',
                uid: user?.id,
            };
            updatePreferences(request, t('Language added'), t('Adding language failed'));
        }
    };

    const removeOtherLanguage = (lang: LanguageDto) => {
        const newList = [...otherLanguages].filter((element) => element.locale !== lang.locale);
        setOtherLanguages(newList);
        const request: UpdateUserLocalizationDTO = {
            additionalLanguages: newList.map((o) => o.locale).join(';'),
            country: country?.codeAlpha2 ?? '',
            locale: language?.locale ?? '',
            uid: user?.id,
        };
        updatePreferences(request, t('Language removed'), t('Removing language failed'));
    };

    const updatePreferences = (req: UpdateUserLocalizationDTO | null, successMessage: string, errorMessage: string) => {
        let request: UpdateUserLocalizationDTO = req ?? {
            additionalLanguages: otherLanguages.map((o) => o.locale).join(';'),
            country: country?.codeAlpha2 ?? '',
            locale: language?.locale ?? '',
            uid: user?.id,
        };
        setIsSaving(true);
        Api.UpdateUserLocalization(request)
            .then(() => {
                addToast(successMessage, {
                    color: ToastColor.GREEN,
                    showCloseButton: true,
                    autoClose: true,
                    position: ToastPosition.TOPMIDDLE,
                });
                Api.GetUserLocalization(user?.id)
                    .then((response: UpdateUserLocalizationDTO) => {
                        setLocalization(response);
                    })
                    .catch(() => {
                        addToast(t('Fetching language and region preferences failed'), {
                            color: ToastColor.RED,
                            showCloseButton: true,
                            autoClose: true,
                            position: ToastPosition.TOPMIDDLE,
                        });
                    })
                    .finally(() => {
                        setIsSaving(false);
                        setIsAddLanguageModalOpen(false);
                    });
            })
            .catch(() => {
                addToast(errorMessage, {
                    color: ToastColor.RED,
                    showCloseButton: true,
                    autoClose: true,
                    position: ToastPosition.TOPMIDDLE,
                });
                setIsSaving(false);
                setIsAddLanguageModalOpen(false);
            });
    };

    const onCancel = () => {
        setLanguage(languages.find((l: LanguageDto) => l.locale.toLowerCase() === localization?.locale?.toLowerCase()));
        setCountry(countries.find((c: CountryDto) => c.codeAlpha2.toLowerCase() === localization?.country?.toLowerCase()));
    };

    useEffect(() => {
        if (!isSaving) {
            setIsEdited(
                !!(language?.locale && language?.locale.toLowerCase() !== localization?.locale?.toLowerCase()) ||
                !!(country?.codeAlpha2 && country?.codeAlpha2.toLowerCase() !== localization?.country?.toLowerCase()),
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localization?.country, localization?.locale, country, language, isSaving]);

    useEffect(() => {
        if (location.hash !== `/user/${user.id}/language-region`) {
            setsShouldBlockNavigation(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            <PromptOnLeave prompt={shouldBlockNavigation && isEdited}/>
            <Wrapper>
                <h3>{t('Language & Region')}</h3>
                <GroupContainer>
                    <h6>{t('Language settings')}</h6>
                    <p>{t('Menus and buttons are displayed using your Primary Language. Add more languages to view content in multiple languages.')}</p>

                    <div>
                        <InputLabel inputId={"LanguagePickerDropdown"} text={"Primary language"}/>
                        <DropdownFilter
                            id="LanguagePickerDropdown"
                            disabled={readOnly}
                            list={languages.filter(a => a.name).map((lang: LanguageDto) => ({value: capitalizeFirstLetter(lang.name)}))}
                            placeholder={t('Select ...')}
                            onSelect={(value: string) => {
                                setLanguage(languages.find((l: LanguageDto) => capitalizeFirstLetter(l.name) === value));
                            }}
                            value={capitalizeFirstLetter(language?.name)}
                            scrollable={true}
                        />
                    </div>
                    <OtherLanguagesContainer>
                        <UpperCase>{t('Other languages')}</UpperCase>
                        {otherLanguages.map((lang: LanguageDto) => (
                            <ListRow
                                key={lang.id}
                                mainText={lang?.name.slice(0, 1).toUpperCase() + lang?.name.slice(1, lang?.name.length)}
                                dropdown={
                                    {
                                        items: [{
                                            value: 'Remove'
                                        }],
                                        onClick: () => {
                                            if (lang) {
                                                removeOtherLanguage(lang);
                                            }
                                        }
                                    }}
                            />
                        ))}
                    </OtherLanguagesContainer>

                    <ActionChip text={"Add another language"}
                                disabled={readOnly}
                                icon={<SystemIcons.Add/>}
                                onClick={() => setIsAddLanguageModalOpen(true)}/>
                </GroupContainer>
                <GroupContainer>
                    <h6>{t('Regional settings')}</h6>
                    <p>
                        This setting can influence region-specific behavior of the digital services the user accesses like date format or data privacy rules.
                    </p>
                    <Note>
                        Billing and other commercial details are affected by the organizational country only.
                    </Note>
                    <div>
                        <InputLabel inputId={'CountryPickerDropdown'} text={'Country / Region'}/>
                        <DropdownFilter
                            disabled={readOnly}
                            scrollable={true}
                            id="CountryPickerDropdown"
                            list={countries.map((country: CountryDto) => ({value: country?.name}))}
                            placeholder={t('Select ...')}
                            onSelect={(value: string) => setCountry(countries.find((c: CountryDto) => c.name === value))}
                            value={country?.name}
                        />
                    </div>
                        
                    <ButtonWrapper>
                        <Button
                            id={'SaveButton'}
                            size={Size.Large}
                            variant="primary"
                            onClick={() => updatePreferences(null, t('Account preferences saved'), t('Account preferences could not be saved'))}
                            disabled={readOnly || !isEdited || isSaving}
                            loading={isSaving}>
                            {t('Save changes')}
                        </Button>
                        
                        <Button
                            id={'CancelButton'}
                            onClick={() => {
                                if (!isSaving) {
                                    onCancel();
                                }
                            }}
                            size={Size.Large}
                            variant="tertiary"
                            disabled={readOnly || isSaving || !isEdited}>
                            {t('Cancel')}
                        </Button>

                    </ButtonWrapper>
                </GroupContainer>
            </Wrapper>
            <AddLanguageModal isModalOpen={isAddLanguageModalOpen} closeModal={() => setIsAddLanguageModalOpen(false)}
                              languages={addLanguageList} addOtherLanguage={addOtherLanguage} isSavingLang={isSaving}/>
        </>
    );
};

export default LanguageAndRegionPage;
