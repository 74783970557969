/**
 * Import React Libraries.
 */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';

/**
 * Import third-party libraries.
 */
import { Trans, useTranslation } from 'react-i18next';
import { PageWidth, InputLabel, COLORS, Button, ChipDropdownInput, ToastColor, ToastContext, ToastPosition, SystemIcons, Checkbox, RadioButton } from '@laerdal/life-react-components';
import styled from 'styled-components';

/**
 * Import custom functions.
 */
import Api from '../../utils/api';

/**
 * Import custom types.
 */
import { Organization, Recipient, User } from '../../types';
import { useToastContext, useUserContext } from '../../userContext';
import UnauthorizedMessage from '../../components/UnauthorizedMessage';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > button + button {
    margin-left: 10px;
  }
`;

const CenteredText = styled.p`
  text-align: center;
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
  justify-content: center;
  .success-icon {
    border-radius: 50%;
    background-color: ${COLORS.correct_500};
    color: ${COLORS.white};
    max-width: fit-content;
    max-height: fit-content;
    margin-right: 0.2em;
  }
`;

const UserOnboard = () => {
  // Globally used variables within the page
  const { t } = useTranslation('Organization');
  const { addToast } = useToastContext();
  const { onboarder } = useUserContext();
  const [customerNo, setCustomerNo] = useState<string>('');
  const [email, setUserEmail] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [accessLevel, setAccessLevel] = useState<Boolean | null>(null);
  const [noUser, setNoUser] = useState<boolean>(false);
  const [noOrganization, setNoOrganization] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [organization, setOrganization] = useState<Organization>();
  
  const location = useLocation();

  /**
   * Retrieves URL parameters and stores them in a state.
   */
  const getURLParameters = (): void => {
    // Let's get URL params
    const parameters = new URLSearchParams(location.search);

    // Let's retrieve parameters from the url
    if (parameters.get('customerNo')) {
      // Let's update customer number
      setCustomerNo(parameters.get('customerNo')!);
    }

    // Let's retrieve parameters from the url
    if (parameters.get('email')) {
      // Let's update customer number
      const email = parameters.get('email');
      setUserEmail(email!);
      setInvalidEmail(!validEmail(email!));
    }
    else 
      setInvalidEmail(true);
  };

  /**
   * Retrieves a specific organization data.
   * @param id - ID of the organization which to retrieve.
   */
  const getOrganization = (id: string): void => {
    Api.GetOrganization(id).then((organization: Organization) => {
      // Let's check if organization is not already onboarded
      if (organization) {
        // Organization was found, let's update data
        setOrganization(organization);
        setNoOrganization(false);

      } else {
        // Set organization
        setOrganization(organization);
      }
    });
  };

  /**
   * Retrieves all passed parameters for the page.
   */
  useEffect(() => {
    // Let's retrieve parameters
    getURLParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retrieves organization details from the API.
   */
  useEffect(() => {
    if (customerNo) {
      Api.GetOrganizationByCustomerNo(customerNo)
        .then((organization: Organization) => {
          // Let's retrieve organization details and members
          getOrganization(organization.id);
        })
        .catch((error) => {
          // If there are no organizations found inform user
          if (error === 'Not Found') {
            setNoOrganization(true);
          }

          // Let's check if multiple organizations were found
          if (error === 'Bad Request') {
            setNoOrganization(false);
          }
        });
    }
  }, [customerNo]);

  useEffect(() => {
    if (email) {
      Api.GetUser(email)
        .then((user: User) => {
        })
        .catch((error) => {
            setNoUser(true);
        });
    }
  }, [email]);


  /**
   * Validates an e-mail address against a valid e-mail address format.
   * @param email - Email address which needs to be validated.
   * @returns Boolean value indicating if the e-mail is a valid e-mail address.
   */
  const validEmail = (email: string): boolean => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(email);
  };

  /**
   * Does all required pre-requisites and tries to onboard the user.
   */
  const onboardUser = () => {
    // Let's make sure that we onboard only in case the form is valid
    if (accessLevel != null) {

      setLoading(true);

      // Let's onboard the organization
      Api.OnboardUser(organization!.id, email, Boolean(accessLevel))
        .then(() => {
          setShowSuccessMessage(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          addToast(t('There was an error with onboarding the organization'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });
        });
    }
  };

  /**
   * Renders a specific view in case customerNo  URL parameter is missing.
   * @returns HTML view for a case when required URL parameter is missing.
   */
  const renderMissingParameters = () => {
    return (
      <CenteredText>
        <b>Customer Number</b> parameter is missing.
      </CenteredText>
    );
  };

    /**
   * Renders a specific view in case email URL parameter is missing or invalid.
   * @returns HTML view for a case when required URL parameter is missing or invalid.
   */
  const renderMissingOrInvalidEmailParameters = () => {
    return (
      <CenteredText>
        <b>User email</b> parameter is missing or invalid.
      </CenteredText>
    );
  };
  

  /**
   * Renders a specific view in case organization with specified customer number was not found.
   * @returns HTML view for a case when organization with the specified customer number was not found.
   */
  const renderNoOrganization = () => {
    return (
      <CenteredText>
        Organization with a customer number <b>{customerNo}</b> was not found.
      </CenteredText>
    );
  };

    /**
   * Renders a specific view in case organization with specified customer number was not found.
   * @returns HTML view for a case when organization with the specified customer number was not found.
   */
    const renderNoUser = () => {
      return (
        <CenteredText>
          User with provided email <b>{email}</b> was not found.
        </CenteredText>
      );
    };
  


  /**
   * Renders a specific view in case organization was found and onboard process can be started.
   * @returns HTML view for a case when organization was found and onboarding can be started.
   */
  const onboardUserView = () => {
    const organizationName = organization!.name;
    
    return (
      <>
        <h3>{email}</h3>
        <h5>{organizationName}</h5>

        <p>Enable access to Connect for this user.</p>
        <p>This person will receive an email welcoming them to their organization on Laerdal Connect.</p>
        <p>Select access level:</p>

        <RadioButton
            selected={accessLevel == true}
            select={() => {
                setAccessLevel(true);
            }} label="Set as admin"
        />

        <RadioButton
            selected={accessLevel == false}
            select={() => {
                setAccessLevel(false);
            }} label="Set as normal user"
        />

        <ButtonWrapper>
          <Button id="send-invites" loading={loading} type="submit" disabled={accessLevel == null} onClick={onboardUser}>
            {t('Send welcome email(s)')}
          </Button>
        </ButtonWrapper>
      </>
    );
  };

  /**
   * Renders a specific view when the onboard process has been done successfully
   */
  if (showSuccessMessage) {
    return (
      <SuccessMessage id="UserOnboardSuccessMsg">
        <SystemIcons.CheckMark className={'success-icon'} size="28px" />
        <span>{t('You successfully onboarded user')}</span>
      </SuccessMessage>
    );
  }

  return onboarder ? (
    <PageWidth $useMaxWidth={true} $maxWidth={1600}>
      {
        // Render missing parameters message
        !customerNo && renderMissingParameters()
      }
      {
        invalidEmail && renderMissingOrInvalidEmailParameters()
      }
      {
        // Render no organization message
        noOrganization && renderNoOrganization()
      }
      {
        noUser && renderNoUser()
      }
      {
        // Render onboard user view
        organization && !noUser && onboardUserView()
      }
    </PageWidth>
  ) : (
    <UnauthorizedMessage>
      <>
        You don't have access to Active Onboarding functionality <br />
        Contact a member of the Laerdal Support Center team to request access.
      </>
    </UnauthorizedMessage>
  );
};

export default UserOnboard;
