import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
 import {
  COLORS,
  DropdownFilter,
  SystemIcons,
  ModalDialog,
  BREAKPOINTS,
  Size,
  InputLabel
} from '@laerdal/life-react-components';

import { useMediaQuery } from 'react-responsive';

type InviteUserProps = {
  closeModal: any;
  isModalOpen: boolean;
  languages: string[];
  addOtherLanguage: (lang: string) => void;
  isSavingLang: boolean;
};

const AddLanguageModal: React.FunctionComponent<InviteUserProps> = ({ closeModal, isModalOpen, languages, addOtherLanguage, isSavingLang }) => {
  const [language, setLanguage] = useState<string>('');
  const { t } = useTranslation('Preferences');
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace('@media ', '') });

  const closeModalAndClearInput = () => {
    closeModal();
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {}}
      closeAction={closeModalAndClearInput}
      title={t('Add language')}
      size={isMediumScreen ? Size.Medium : Size.Small}
      buttons={
        [
          {
            id: 'cancel',
            variant: 'tertiary',
            text: t('Cancel'),
            action: closeModalAndClearInput,
          },
          {
            id: 'save',
            action: () => addOtherLanguage(language),
            variant: 'primary',
            text: t('Add language'),
            loading: isSavingLang,
            disabled: isSavingLang
          },
        ] as any
      }>
      <div>
        <InputLabel inputId={'OtherLanguagePickerDropdown'} text={'Select language'}/>
        <DropdownFilter id="OtherLanguagePickerDropdown"
                        list={languages.map(val => ({value: val}))}
                        placeholder={t('Select ...')}
                        scrollable={true}
                        onSelect={(value: string) => setLanguage(value)}/>
      </div>
    </ModalDialog>
  );
};

export default AddLanguageModal;
