import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {
  ModalDialog,
  RadioButton,
  Size
} from '@laerdal/life-react-components';
import {OrganizationServiceMember, Service, ServiceRole} from '../../../../types';
import Api from '../../../../utils/api';
import {useToastContext} from '../../../../userContext';
import {ErrorToastOptions, SuccessToastOptions} from "../../../../constants";

const RadioButtonList = styled.ul`
  .role {
    label {
      white-space: pre-line;
    }
  }
`;

type EditOrgServiceUserProps = {
  closeModal: any;
  isModalOpen: boolean;
  orgId: string;
  orgServiceId: string | null;
  orgService: Service | undefined;
  member: OrganizationServiceMember | undefined;
  onSuccess: () => Promise<void>;
};

const EditOrgServiceUserModal: React.FunctionComponent<EditOrgServiceUserProps> = ({
                                                                                     orgId,
                                                                                     member,
                                                                                     closeModal,
                                                                                     isModalOpen,
                                                                                     orgServiceId,
                                                                                     orgService,
                                                                                     onSuccess
                                                                                   }) => {
  const [selectedRole, setSelectedRole] = useState<ServiceRole | null | undefined>(member?.role);
  const {t} = useTranslation('User');
  const {addToast} = useToastContext();

  const [busy, setBusy] = useState<boolean>(false);

  const serviceRoles = orgService?.serviceRoles;

  useEffect(() => {
    if (member?.role) {
      setSelectedRole(member?.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const changeRole = () => {
    if (selectedRole && !busy && member?.user?.id) {
      setBusy(true);
      Api.UpdateOrganizationServiceMember(orgId, orgServiceId!, member?.user?.id || '', selectedRole)
        .then(() => onSuccess().then(() => {
          closeModal();
          addToast(t('Changes saved'), SuccessToastOptions);
        }))
        .catch(() => {
          addToast(t('There was an error saving the changes'), ErrorToastOptions);
        })
        .finally(() => {
          setBusy(false);
        });
    }
  };

  const getRoleName = (id?: string) => {
    return serviceRoles?.find((roles) => roles.id === id)?.name;
  }

  const getRoleDescription = (id?: string) => {
    return serviceRoles?.find((roles) => roles.id === id)?.description;
  }

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeAction={closeModal}
      title={t('Edit access to {{service}}', {service: orgService?.name})}
      size={Size.Large}
      buttons={
        [
          {
            id: 'cancel',
            variant: 'tertiary',
            text: t('Cancel'),
            disabled: busy,
            action: closeModal,
          },
          {
            id: 'save',
            action: (e: any) => {
              e.preventDefault();
              changeRole();
            },
            loading: busy,
            variant: 'primary',
            text: t('Save changes'),
            disabled: busy
          },
        ] as any
      }>
      <p><strong>{`${member?.user?.firstName} ${member?.user?.lastName} (${member?.user?.email})`}</strong></p>
      <RadioButtonList>
        {serviceRoles?.map((role) => (
          <RadioButton
            id={`${role.name}_Radio`}
            key={role.id}
            className={'role'}
            label={t(getRoleName(role.id) ?? '') + '\n' + t(getRoleDescription(role.id) ?? '')}
            select={() => {
              setSelectedRole(role);
            }}
            selected={selectedRole?.name === role.name}
          />
        ))}
      </RadioButtonList>
    </ModalDialog>
  );
};

export default EditOrgServiceUserModal;
