/**
 * Import React Libraries.
 */
import React, {MouseEvent, useState} from 'react';

/**
 * Import third-party libraries.
 */
import {ActionChip, BREAKPOINTS, ModalDialog, Size, SystemIcons} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';

/**
 * Import custom styles.
 */
import {EmailSpan, NameSpan, UserDetails, UserDetailsWrapper} from './ResetPasswordStyles';

/**
 * Import custom types.
 */
import {ResetPasswordProps} from './ResetPassword.type';
import {useMediaQuery} from 'react-responsive';
import {useToastContext, useUserContext} from '../../../../userContext';
import {AccessLevel} from '../../../../types';
import {ErrorToastOptions, SuccessToastOptions} from '../../../../constants';
import Api from '../../../../utils/api';

const ResetPassword = ({ firstName, lastName, email }: ResetPasswordProps) => {
  // Globally used variables within the component
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] = useState<boolean>(false);
  const [resettingPassword, setResettingPassword] = useState<boolean>(false);
  const { addToast } = useToastContext();
  const { accessLevel } = useUserContext();
  const { t } = useTranslation('User');
  const isMediumScreen = useMediaQuery({ query: BREAKPOINTS.MEDIUM.replace('@media ', '') });
  const readOnly = accessLevel == AccessLevel.ReadOnly;

  /**
   * Does all required pre-requisites when user clicks on reset password button.
   */
  const openResetPasswordModal = () => {
    setResetPasswordModalIsOpen(true);
  };

  /**
   * Does all required pre-requisites when modal window is closed.
   */
  const closeResetPasswordModal = () => {
    setResetPasswordModalIsOpen(false);
  };

  /**
   * Let's try to reset password on the reset password button click.
   * @param event - Event handler from the mouse click.
   */
  const resetPassword = (event: MouseEvent<HTMLButtonElement>) => {
    // Let's prevent the default action
    event.preventDefault();

    // Let's reset password if we are not already resetting
    if (!resettingPassword) {
      // Let's set button as busy
      setResettingPassword(true);

      // Let's reset the password
      // @ts-ignore
      Api.ResetPassword(email)
      .then(() => {
        onResetPasswordResponse(true);
      })
      .catch(() =>{
        onResetPasswordResponse(false);
      });
    }
  };

  /**
   * Does all required pre-requisites when we retrieve the response from the gigya.
   * @param response - Response from the reset password gigya endpoint.
   */
  const onResetPasswordResponse = (successfull: boolean) => {
    // Let's check if error code is 0
    if (successfull) {
      // Let's inform about success
      addToast(t('Reset password email has been sent'), SuccessToastOptions);
    } else {
      // Let's inform about error
      addToast(t('There was an error sending reset password email'), ErrorToastOptions);
    }

    // Let's close the modal
    closeResetPasswordModal();

    // Let's clear the busy state
    setResettingPassword(false);
  };

  /**
   * Does all required pre-requisites and renders reset password modal.
   */
  const renderModal = () => {
    return (
      <ModalDialog
        isModalOpen={resetPasswordModalIsOpen}
        submitAction={() => {}}
        closeAction={closeResetPasswordModal}
        title={t('Reset user password')}
        size={isMediumScreen ? Size.Medium : Size.Small}
        buttons={
          [
            {
              id: 'cancel',
              variant: 'tertiary',
              text: t('Cancel'),
              action: closeResetPasswordModal,
            },
            {
              id: 'sendEmail',
              action: resetPassword,
              loading: resettingPassword,
              variant: 'primary',
              text: t('Send Email'),
              disabled: resettingPassword
            },
          ] as any
        }>
        <UserDetailsWrapper>
          <SystemIcons.User />
          <UserDetails>
            <NameSpan>
              {firstName} {lastName}
            </NameSpan>
            <br />
            <EmailSpan>{email}</EmailSpan>
          </UserDetails>
        </UserDetailsWrapper>
        <p>{t('This person will receive an email containing instructions on how to reset their password.')}</p>
      </ModalDialog>
    );
  };

  return (
    <>
      <ActionChip disabled={readOnly} text={t('Reset password')} size={Size.Large} onClick={openResetPasswordModal}/>
      {renderModal()}
    </>
  );
};

export default ResetPassword;
