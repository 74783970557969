import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import {useTranslation} from 'react-i18next';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import {
  ContentIcons,
  GlobalLoadingPage,
  PageWidth,
  ComponentL,
  ComponentS,
  COLORS,
  ComponentTextStyle,
  SystemIcons,
  HyperLink
} from '@laerdal/life-react-components';

import Api from '../../utils/api';
import { UserRecordWrapper, User } from '../../types';
import moment from 'moment';
import { useUserContext } from '../../userContext';
import UnauthorizedMessage from '../../components/UnauthorizedMessage';

interface OrgRow {
  name: string;
  userRole: string;
  invitation: boolean;
  customerNo: string;
  current: boolean;
}

type UserState = 'registered' | 'pending' | 'contact';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`;

const OrganizationInfoDetails = styled.div`
  width: 100%;

  .vertical-middle {
    > svg {
      vertical-align: middle;
    }
  }
`;

const DataTable = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  
  &.bottom-borders {
    > div {
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      padding: 8px 0px;

      svg {
        vertical-align: middle;
      }
    }
  }
`;

const TableLine = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  border: 1px solid ${COLORS.neutral_100};
  padding: 8px;

  + div {
    border-top: 0px;
  }

  .inline {
    display: inline;
  }
`;

const UserInfo = () => {
  const {t} = useTranslation('User');
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userOrgs, setUserOrgs] = useState<OrgRow[]>([]);
  const [userState, setUserState] = useState<UserState| undefined>(undefined);
  const [lastLogin, setLastLogin] = useState<string | null>(null);
  const { isAccountAndCustomerViewer } = useUserContext();

  /**
   * Retrieves URL parameters and stores them in a state.
   */
  const getURLParameters = (): void => {
    // Let's get URL params
    const parameters = new URLSearchParams(location.search);

    // Let's retrieve parameters from the url
    if (parameters.get('email')) {
      // Let's update customer number
      setEmail(parameters.get('email')!);
    }
  };

  /**
   * Retrieves all passed parameters for the page.
   */
  useEffect(() => {
    // Let's retrieve parameters
    getURLParameters();
  }, []);

  useEffect(() => {
    if (email) {
      Api.FindUsers(email)
        .then((users: UserRecordWrapper) => {
          if (users.records.length) {
            Api.GetUser(users.records[0].id)
              .then((userRow: User) => {
                // Let's create empty row org array
                const orgRows: OrgRow[] = [];

                if (userRow) {
                  setUser(userRow);

                  // Let's get last login date
                  Api.getUserLastLogin(userRow.id)
                    .then(data => {
                      if (data.length > 0) {
                        const lastLoginDate = data[0].eventTime;

                        if (new Date(lastLoginDate).toDateString() === new Date().toDateString()) {
                          setLastLogin(t('Today, ') + moment(lastLoginDate).format('hh:mm A'));
                        } else if (new Date(lastLoginDate).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) {
                          setLastLogin(t('Yesterday, ') + moment(lastLoginDate).format('hh:mm A'));
                        } else {
                          setLastLogin(moment(lastLoginDate).format('MMM Do, YYYY, hh:mm A'));
                        }
                      } else {
                        setLastLogin('-');
                      };
                    });

                  // Let's add invitations
                  userRow.invitations?.forEach(invitation => {
                    orgRows.push({
                      name: invitation.organization.name,
                      current: userRow.currentOrganization?.id === invitation.organization.id,
                      customerNo: invitation.organization.customerNo,
                      invitation: true,
                      userRole: invitation.invitation.permissions.find(permission => permission === 'account.owner') !== undefined ? t('Admin') : t('User')
                    });
                  });

                  // Let's add member orgs
                  userRow.organizations?.forEach(org => {
                    orgRows.push({
                      name: org.name,
                      current: userRow.currentOrganization?.id === org.id,
                      customerNo: org.customerNo,
                      invitation: false,
                      userRole: org.members.find(member => member.user.id === userRow.id)?.permissions!.find(permission => permission.permissionId === 'account.owner') !== undefined ? t('Admin') : t('User')
                    });
                  });

                  // Set user state
                  if (userRow?.currentOrganization && userRow.currentOrganization.length !== 0 && userRow?.identityId) {
                    setUserState('registered');
                  } else if (orgRows.length > 0) {
                    setUserState('pending');
                  } else if (userRow.contactOrganizations?.length) {
                    setUserState('contact');
                  }

                  // Let's set user orgs
                  setUserOrgs(orgRows);
                }
              })
              .finally(() => setLoading(false));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [email]);

  return (
    <>
      <Helmet>
        <title>{t('User Information')}</title>
      </Helmet>
      {
        loading ? (
          <GlobalLoadingPage/>
        ) : (
          isAccountAndCustomerViewer ? (
            <PageWidth $useMaxWidth={true} $maxWidth={1088}>
              <Row>
                <ComponentL textStyle={ComponentTextStyle.Bold}>{ user ? user.firstName && user.lastName ? `${t('User')} - ${user.firstName} ${user.lastName}` : t('User') : t('No User Found')}</ComponentL>
                <ContentIcons.Person size='32' />
              </Row>
              { user &&
                <OrganizationInfoDetails>
                  <DataTable className="bottom-borders">
                    <TableLine>
                      <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Username')}</ComponentS>
                      <ComponentS>{email}</ComponentS>
                    </TableLine>
                    <TableLine>
                      <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Contact State')}</ComponentS>
                      <ComponentS color={userState !== 'contact' ? COLORS.black : COLORS.critical_500} >
                        <>
                          { userState === 'registered' && <SystemIcons.CheckMark size='20' /> }
                          { userState === 'registered' ? 'Registered user' : userState === 'pending' ? 'Pending user' : 'SF contact only'  }
                        </>
                      </ComponentS>
                    </TableLine>
                    <TableLine>
                      <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Last login date')}</ComponentS>
                      <ComponentS>{lastLogin}</ComponentS>
                    </TableLine>
                  </DataTable>
                  <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Organizations')} ({userOrgs.length})</ComponentS>
                  <DataTable>
                    {
                      userOrgs.map((org: OrgRow) => (
                        <TableLine key={org.customerNo}>
                          <ComponentS>
                            {org.name} {org.customerNo} <ComponentS className="inline" color={COLORS.neutral_300}>{org.current ? t('Default') : ''}</ComponentS>
                          </ComponentS>
                          <ComponentS>{org.userRole} {org.invitation ? t('(Pending)') : ''}</ComponentS>
                        </TableLine>
                      ))
                    }
                  </DataTable>

                  <HyperLink id="UserInfo" variant="default" href={`/user/${user.id}`} className="vertical-middle">
                    {t('View in Cloud Control')} <SystemIcons.OpenNewWindow />
                  </HyperLink>
                </OrganizationInfoDetails>
              }
            </PageWidth>
          ) : (
            <UnauthorizedMessage>
              <>
                You don't have access to Active Onboarding functionality <br />
                Contact a member of the Laerdal Support Center team to request access.
              </>
            </UnauthorizedMessage>
          )
        )}
    </>
  );
};

export default UserInfo;
