import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Checkbox, Size } from '@laerdal/life-react-components';

export interface CheckboxProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'onKeyDown' | 'onMouseDown' | 'tabIndex'>{
  id?: string;
  selected: boolean;
  select?: (selected: boolean) => void;
  label?: string;
  invalid?: boolean;
  disabled?: boolean;
  margin?: string;
  size?: Size.Small | Size.Medium | Size.Large;
  iconPointerEventsTransparent?: boolean;
  semiSelected?: boolean;
  readOnly?: boolean;
  tabIndexVal?: number;
  className?: string;
};

interface FormCheckboxFieldProps<T extends FieldValues> extends Omit<CheckboxProps, 'selected' | 'select' | 'onBlur' | 'ref'> {
  control: Control<T, any>;
}

export const FormCheckboxField = <T extends FieldValues>({ control, ...rest }: FormCheckboxFieldProps<T>) => {
  return (
    <Controller
      control={control}
      render={(fields) => (
        <Checkbox {...rest} {...fields.field} selected={fields.field.value ?? false} select={fields.field.onChange} onBlur={fields.field.onBlur} ref={fields.field.ref} />
      )}
      name={rest.id as Path<T>}
    />
  );
};
