import {SessionDTO} from '../../../../types';
import {BREAKPOINTS, COLORS, ModalDialog, Size} from '@laerdal/life-react-components';
import moment from 'moment';
import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
} from '@laerdal/life-react-components';


const SessionInfoModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  ${BREAKPOINTS.MEDIUM}{
    gap: 16px;
  }
  
  &> div{
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const RowKey = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
  }
`;

const RowValue = styled.p`
`;


interface SessionInformationModalProps {
  session?: SessionDTO;
  seat?: number;
  onClose: () => void;
  isOpen: boolean;
}

const SessionInformationModal: React.FunctionComponent<SessionInformationModalProps> = (props) => {
  const {t} = useTranslation('OrganizationServices');
  const isMediumScreen = useMediaQuery({query: BREAKPOINTS.MEDIUM.replace('@media ', '')});

  const started = moment(props.session?.created).format('LLL');
  const lastUsed = moment(props.session?.updated).format('LLL');

  return (
    <ModalDialog isModalOpen={props.isOpen}
                 closeAction={props.onClose}
                 submitAction={props.onClose}
                 buttons={[
                   {
                     action: props.onClose,
                     text: t('Close'),
                     variant: 'tertiary',
                   },
                 ]}
                 title={t('User session information')}
                 size={isMediumScreen ? Size.Medium : Size.Small}>
      <SessionInfoModalBody>
        <div>
          <RowKey>{t('Started')}:</RowKey>
          <RowValue>{started}</RowValue>
        </div>
        <div>
          <RowKey>{t('Last used')}:</RowKey>
          <RowValue>{lastUsed}</RowValue>
        </div>
        <div>
          <RowKey>{t('Seat')}:</RowKey>
          <RowValue>{props.seat}</RowValue>
        </div>

        <div>
          <RowKey>{t('Username')}:</RowKey>
          <RowValue>{props.session?.email}</RowValue>
        </div>


        <div>
          <RowKey>{t('Software version')}:</RowKey>
          <RowValue>{props.session?.softwareInfo}</RowValue>
        </div>

        <div>
          <RowKey>{t('Device')}:</RowKey>
          <RowValue>{props.session?.deviceInfo}</RowValue>
        </div>

      </SessionInfoModalBody>


    </ModalDialog>
  );
};

export default SessionInformationModal;