import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ModalDialog,
  Size
} from "@laerdal/life-react-components";
import Api from "../../../../utils/api";
import { Organization, OrganizationMember } from "../../../../types";
import { useMediaQuery } from "react-responsive";
import { useToastContext } from "../../../../userContext";
import { ErrorToastOptions, SuccessToastOptions } from "../../../../constants";

type RemoveUserProps = {
  closeModal: any;
  isModalOpen: boolean;
  organization?: Organization | null;
  member: OrganizationMember | undefined;
  onSuccess: () => Promise<void>;
};

const RemoveUserFromOrganizationModal: React.FunctionComponent<RemoveUserProps> = ({
                                                                                     closeModal,
                                                                                     isModalOpen,
                                                                                     member,
                                                                                     onSuccess,
                                                                                     organization
                                                                                   }: RemoveUserProps) => {
  const { t } = useTranslation("User");
  const [busy, setBusy] = useState<boolean>(false);

  const { addToast } = useToastContext();

  const removeMember = (orgId: string, memberEmail: string) => {
    setBusy(true);
    Api.RemoveMemberFromOrganization(orgId, memberEmail)
      .then(() => onSuccess().then(() => {
        addToast(t("User was removed successfully from organization."), SuccessToastOptions);
      }))
      .catch((error: any) => {
        console.error(error);
        addToast(t("There was a problem removing the user from organization."), ErrorToastOptions);
      })
      .finally(() => {
        setBusy(false);
        closeModal();
      });
  };

  return (
    <ModalDialog
      isModalOpen={isModalOpen}
      submitAction={() => {
      }}
      closeAction={closeModal}
      title={t("Remove from organization")}
      size={Size.Large}
      buttons={
        [
          {
            id: "cancel",
            variant: "tertiary",
            text: t("No, cancel"),
            disabled: busy,
            action: closeModal
          },
          {
            id: "remove",
            action: (e: any) => {
              e.preventDefault();
              if (!busy) {
                removeMember(organization?.id!, member?.user?.email ?? "");
              }
            },
            loading: busy,
            variant: "critical",
            text: t("Yes, remove"),
            disabled: busy
          }
        ] as any
      }>
      <p><strong>{member?.user?.email}</strong></p>
      <br />
      <p>{t("This person will no longer be able to see product information, and will lose access to all subscription licenses and content associated with this organization.")}</p>
      <br />
      <p>{t("Are you sure you want to remove this person from {{organization}}?", { organization: organization?.name })}</p>
    </ModalDialog>
  );
};

export default RemoveUserFromOrganizationModal;
