/**
 * Import React libraries.
 */
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";

/**
 * Import third-party libraries.
 */
import {
  BackButton,
  Button,
  Checkbox,
  COLORS,
  ComponentTextStyle,
  ComponentXSStyling,
  LoadingIndicator,
  Size,
  ToastColor,
  ToastPosition
} from "@laerdal/life-react-components";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {
  AddOrganizationServiceMemberInvitation,
  CountryDto,
  Organization,
  OrganizationService,
  PaymentType,
  Service,
  ServiceRole,
  SubscriptionCreation,
  SubscriptionStatus
} from "../../../types";
import Api from "../../../utils/api";
import api from "../../../utils/api";
import {dateOnlyMidnightUtc, domainValidationRequired} from "../../../utils/functions";
import {FormProvider, useForm} from "react-hook-form";
import {CreateLicenseForm} from "./Common/types";
import {yupResolver} from "@hookform/resolvers/yup";
import {createLicenseSchema} from "./Common/validations";
import moment from "moment";
import ConfirmCreateLicenseModal from "./Components/ConfirmCreateLicenseModal";
import {GeneralSubscriptionPropertiesCard} from "./Components/CreateForm/GeneralSubscriptionPropertiesCard";
import {SubscriptionOptionsCard} from "./Components/CreateForm/SubscriptionOptionsCard";
import {OrganizationBillingDetailsCard} from "./Components/CreateForm/OrganizationBillingDetailsCard";
import {useToastContext, useUserContext} from "../../../userContext";
import {ErrorToastOptions, SuccessToastOptions} from "../../../constants";
import {StringParam, useQueryParams} from "use-query-params";
import {getOwners} from "./Common/helpers";


const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 56px;
  max-width: 560px;

  .tile > .large {
    overflow: visible;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Subtitle = styled.div`
  ${ComponentXSStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
`;


const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.neutral_600};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

type TParams = { id: string; organizationId: string };

interface CreateNewLicensePageProps {
  savedOrganization: Organization;
  setSavedOrganization: (org: Organization) => void;
}

const CreateNewLicensePage = ({savedOrganization, setSavedOrganization}: CreateNewLicensePageProps) => {
  const {t} = useTranslation('OrganizationServices');
  const {addToast} = useToastContext();
  const {email: ccUserEmail, selfServiceServices, countryConfigurations, countries} = useUserContext();

  const navigate = useNavigate();
  const match = useParams<TParams>();
  const [{subscriptionId, orgServiceId}] = useQueryParams({subscriptionId: StringParam, orgServiceId: StringParam});

  const [isSelfService, setIsSelfService] = useState<boolean>(false);
  const [isSalesforce, setIsSalesforce] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [validating, setValidating] = useState(false);
  const isReactivating = useRef(false);

  const [service, setService] = useState<Service>();
  const [roles, setRoles] = useState<ServiceRole[]>();

  const [requireSubscription, setRequireSubscription] = useState<boolean>(true);
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const methods = useForm<CreateLicenseForm>({
    resolver: yupResolver(createLicenseSchema),
    context: {
      countries: countries,
      organization: savedOrganization,
      requiresSubscription: requireSubscription,
      serviceId: service?.id,
      isReactivating: isReactivating.current,
      isSelfService: isSelfService,
    },
    defaultValues: {
      general: {
        owner: [],
      },
      billingDetails: {
        ...savedOrganization,
        paymentType: PaymentType.Invoice,
        orderNumber: '',
      },
      subscription: {
        isTrial: false,
        startDate: moment().startOf('day').toDate(),
        expirationDate: moment().startOf('day').add(1, 'year').endOf('day').toDate(),
        duration: '1',
        autoRenew: false,
        renewalPeriod: '',
      },
    },
  });

  const {handleSubmit, watch, setValue, getValues, reset} = methods;

  const isTrial = watch('subscription.isTrial');
  const licenseOwner = watch('general.owner');

  const createOrganizationService = (form: CreateLicenseForm) => {
    return Api.CreateOrganizationService(savedOrganization.id, service!.id)
      .then((orgService) => Promise.resolve({form, orgService}))
      .catch(() => {
        addToast(t('There was an error adding organization service subscription.'), ErrorToastOptions);

        return Promise.reject();
      });
  };

  const createServiceInvitation = ({form, orgService}: {
    form: CreateLicenseForm;
    orgService: OrganizationService
  }) => {
    if (isReactivating.current)
      return {form, orgService};

    //no need for sending invitation if it is a SimCapture service
    if (service?.id == process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase()) {
      if (!form.general.owner || form.general.owner.length == 0)
        return {form, orgService};

      var user = savedOrganization.members.find((x) => x.user.email == form.general.owner[0]);
      return Api.AddMemberToOrganizationService(savedOrganization?.id, orgService.id, user!.user?.id)
        .then(async () => {
          try {
            await Api.UpdateOrganizationServiceMember(savedOrganization?.id, orgService.id, user!.user?.id, {
              id: undefined,
              name: 'Owner',
              description: ''
            });
          } catch (error) {
            console.error(error);
            addToast(t('There was a problem adding the user to service.'), {
              color: ToastColor.RED,
              showCloseButton: true,
              autoClose: true,
              position: ToastPosition.TOPMIDDLE,
            });
            throw error;
          }
          return {form, orgService};
        })
        .catch(() => {
          addToast &&
          addToast(t('There was an error adding user as an owner to the service.'), {
            color: ToastColor.RED,
            showCloseButton: true,
            autoClose: true,
            position: ToastPosition.TOPMIDDLE,
          });

          return Promise.reject();
        });
    }

    // Create invitation object
    const invitationObject: AddOrganizationServiceMemberInvitation = {
      emails: form.general.owner,
      role: form.general.role,
      sendEmail: true,
      createSFContact: false,
    };

    return Api.InviteMemberToOrganizationService(savedOrganization?.id!, orgService.id, invitationObject)
      .then(() => Promise.resolve({form, orgService}))
      .catch(() => {
        addToast &&
        addToast(t('There was an error sending invitation to the service owner.'), {
          color: ToastColor.RED,
          showCloseButton: true,
          autoClose: true,
          position: ToastPosition.TOPMIDDLE,
        });

        return Promise.reject();
      });
  };

  const notifyExternalApis = ({form, orgService}: { form: CreateLicenseForm; orgService: OrganizationService }) => {
    if (orgService.serviceId?.toLowerCase() != process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase()) return Promise.resolve({
      form,
      orgService
    });

    return Api.NotifySimCapture(
      savedOrganization.customerNo,
      savedOrganization.name,
      ccUserEmail,
      (form.subscription.meteorDomain ?? '').toLowerCase(),
      savedOrganization?.address?.country?.codeAlpha3 ?? '',
      form.subscription.tier?.name ?? '',
      (`Install date: ${form.subscription.startDate.toDateString()} \n`) + (form.billingDetails.orderNumber ? `Salesforce Order Number: ${form.billingDetails.orderNumber}` : ''),
      savedOrganization.id
    )
      .then(() => Promise.resolve({form, orgService}))
      .catch(async (error) => {
        await Api.DeleteOrganizationLicense(savedOrganization?.id!, orgService.id);

        addToast(error?.response?.data ?? 'There was an error notifying SimCapture. Please try again later or contact support.', ErrorToastOptions);

        return Promise.reject();
      });
  };

  const createSubscription = ({form, orgService}: { form: CreateLicenseForm; orgService: OrganizationService }) => {
    if (!requireSubscription) {
      return Promise.resolve({form, orgService});
    }

    // Finally, let's create a subscription
    const newSubscription: SubscriptionCreation = {
      autoRenew: !form.subscription.isTrial && !!form.subscription.autoRenew,
      renewalPeriodInMonths: !form.subscription.isTrial && !!form.subscription.autoRenew ? +(form.subscription.renewalPeriod ?? '0') : 0,
      startDate: dateOnlyMidnightUtc(form.subscription.startDate)!,
      expirationDate: !form.subscription?.tier?.enableUnlimitedDuration ? dateOnlyMidnightUtc(form.subscription.expirationDate) : undefined,
      status:
        form.subscription.startDate > new Date()
          ? form.subscription.isTrial
            ? SubscriptionStatus.DraftTrial
            : SubscriptionStatus.Draft
          : form.subscription.isTrial
            ? SubscriptionStatus.Trial
            : SubscriptionStatus.Active,
      paymentType: PaymentType.Invoice,

      organizationServiceId: orgService.id,
      serviceId: service?.id!,
      planId: form.subscription.plan?.id!,
      planTierId: form.subscription.tier?.id!,
      poNumber: form.billingDetails.orderNumber,
      meteorDomain: '',
    };

    // Set max subscription instances if tier allows it
    if (form.subscription.tier?.individualLicensing) {
      newSubscription.maxSubscriptionInstances = form.subscription.numberOfLicenses;
    }

    // Set meteor domain for subscription
    if (domainValidationRequired(service?.id)) {
      newSubscription.meteorDomain = form.subscription.meteorDomain!.toLowerCase();
    }

    return Api.CreateSubscription(savedOrganization?.id!, newSubscription)
      .then(() => Promise.resolve({form, orgService}))
      .catch(() => {
        addToast(t('There was an error adding organization service subscription.'), ErrorToastOptions);

        return Promise.reject();
      });
  };

  const onCreateLicenseDone = (value: CreateLicenseForm) => {
    // Let's start license creation
    setSubmitting(true);

    return createOrganizationService(value)
      .then(createServiceInvitation)
      .then(createSubscription)
      .then(notifyExternalApis)
      .then(() => addToast(t('Organization service added.'), SuccessToastOptions))
      .then(() => navigate("../"))
      .finally(() => setSubmitting(false));
  };

  const retrieveService = (abort: AbortController) => {
    return Api.GetService(match.id!, abort.signal)
      .then((service) => {
        setService(service);
        const ownerRoles = service.serviceRoles?.filter((r) => r.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ID?.toLowerCase());
        setRoles(ownerRoles);
        setRequireSubscription(!!service?.availablePlans?.length);
        if (ownerRoles?.length === 1) {
          setValue("general.role", ownerRoles[0]);
        }

        return service;
      })
      .catch(() => {
        if (!abort.signal.aborted) {
          addToast(t("Service could not be fetched."), ErrorToastOptions);

          return Promise.resolve({} as Service);
        }
        return Promise.reject();
      })
  }

  const retrieveSubscription = (service: Service, abort: AbortController) => {

    return Promise.all([Api.GetOrganizationService(savedOrganization.id, orgServiceId!, abort.signal),
      Api.GetSubscription(savedOrganization.id!, subscriptionId!, abort.signal)])
      .then(([orgService, subscription]) => {
        const plan = service?.availablePlans?.filter(a => a.id.toLowerCase() == subscription.plan.id.toLowerCase())[0];
        const tier = plan?.tiers?.filter(a => a.id.toLowerCase() == subscription.tier.id.toLowerCase())[0];
        reset({
          general: {
            owner: getOwners(orgService),
          },
          billingDetails: {
            ...savedOrganization,
            paymentType: PaymentType.Invoice,
          },
          subscription: {
            trialExpirationDate: subscription.trialExpirationDate,
            isTrial: [SubscriptionStatus.DraftTrial, SubscriptionStatus.Trial].includes(subscription.status),
            numberOfLicenses: subscription.maxSubscriptionInstances,
            plan: plan,
            tier: tier,
            meteorDomain: orgService.meteorDomain,
            startDate: new Date(),
            expirationDate: undefined,
            duration: undefined,
            autoRenew: subscription.autoRenew,
            renewalPeriod: subscription.renewalPeriodInMonths?.toString(),
          }
        });

        isReactivating.current = true;
      })
      .catch(() => {
        if (!abort.signal.aborted) {
          addToast(t("Subscription could not be fetched."), ErrorToastOptions);

          return Promise.resolve();
        }
        return Promise.reject();
      });
  }

  /**
   * Load passed service data from the OrgDir.
   */
  useEffect(() => {
    const abort = new AbortController();

    const action = retrieveService(abort);
    if (orgServiceId && subscriptionId)
      action.then((service) => retrieveSubscription(service, abort));

    action.catch(() => navigate("../")).finally(() => setLoading(false));

    return () => abort.abort();
  }, []);

  useEffect(() => {
    if (!selfServiceServices || !countryConfigurations || !service) return;

    const country = savedOrganization?.address?.country?.codeAlpha2 || '';
    setIsSalesforce(countryConfigurations?.[country]?.useSalesforce)
    setIsSelfService(selfServiceServices?.includes(service!.id));
  }, [selfServiceServices, countryConfigurations, service]);

  return (
    <>
      {loading && (
        <LoadingContainer>
          <LoadingIndicator/>
        </LoadingContainer>
      )}
      {!loading && (
        <>
          <BackButton size={Size.Small} onClick={() => navigate('../license-management')}>
            {t('Back to License management')}
          </BackButton>

          <FormProvider {...methods}>
            <Wrapper>
              <HeaderWrapper>
                <Subtitle>{t('Create new license')}</Subtitle>
                <h6>{service?.name}</h6>
                <p>{t('CREATE_LICENSE_TEXT')}</p>
              </HeaderWrapper>

              <GeneralSubscriptionPropertiesCard organization={savedOrganization} roles={roles}/>
              {requireSubscription && <SubscriptionOptionsCard isSelfService={isSelfService}
                                                               isSalesforce={isSalesforce}
                                                               service={service}/>}
              {!isTrial && requireSubscription && (
                <OrganizationBillingDetailsCard countries={countries}
                                                savedOrganization={savedOrganization}
                                                setSavedOrganization={setSavedOrganization}/>
              )}

              <ConfirmWrapper>
                <h5>{t('Confirm order')}</h5>
                <Checkbox id="confirmOrder" select={(selected) => setTermsAndConditions(selected)}
                          selected={termsAndConditions} label={t('CONFIRM_CHECKBOX_TEXT')}/>
              </ConfirmWrapper>

              <ButtonsWrapper>
                <Button size={Size.Large} variant={'tertiary'} onClick={() => navigate('../license-management')}>
                  {t('Cancel')}
                </Button>
                <Button
                  size={Size.Large}
                  variant={'primary'}
                  loading={submitting || validating}
                  disabled={!termsAndConditions || isSelfService && !isTrial}
                  onClick={() => {
                    setValidating(true);
                    handleSubmit(
                      () => {
                        setValidating(false);
                        setShowConfirmModal(true);
                      },
                      () => setValidating(false),
                    )();
                  }}>
                  {t('Create license')}
                </Button>
              </ButtonsWrapper>
            </Wrapper>
          </FormProvider>

          <ConfirmCreateLicenseModal
            invitationWarning={service!.id.toLowerCase() != process.env.REACT_APP_SIMCAPTURE_SERVICE_ID?.toLowerCase()}
            isOpen={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onSubmit={() => onCreateLicenseDone(getValues())}
            owner={licenseOwner?.[0]}
            submitting={submitting}
          />
        </>
      )}
    </>
  );
};

export default CreateNewLicensePage;
