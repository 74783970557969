/**
 * Import React Libraries.
 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

/**
 * Import third-party libraries.
 */
import { PageWidth, Table } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';

/**
 * Import custom types.
 */
import { Service } from '../../types';
import { TableColumn, TablePagination } from '@laerdal/life-react-components';

/**
 * Import custom components.
 */
import ServiceSearch from './ServiceSearch';

/**
 * Import custom functions.
 */
import Api from '../../utils/api';
import {TableSortingDirection, TableSortProps} from '@laerdal/life-react-components/dist/Table/TableTypes';
import styled from "styled-components";

const StyledPageWidth = styled(PageWidth)`
    display: flex !important;
    flex-direction: column !important;
    gap: 32px !important;

    table .right  {
      text-align: right;
    }
`;

/**
 * Add custom types.
 */
interface ServiceTableRow {
  id: string;
  name: string;
  activeLicenceCount: number;
  activeUserCount: number;
  invitedUserCount: number;
}

const ServicesWithLicenses = [
  process.env.REACT_APP_TEAMREPORTER_SERVICE_ID,
  process.env.REACT_APP_SCENARIO_CLOUD_SERVICE_ID,
  process.env.REACT_APP_SKILLREPORTER_SERVICE_ID
];

const ServiceList = () => {
  // Globally used states within the page
  const [pagination, setPagination] = useState<TablePagination>({
    from: 0,
    to: 0,
    currentPage: 1,
    total: 0,
    rowsPerPage: 10,
  });
  const [sortProps, setSortProps] = useState<TableSortProps | undefined>();
  const [sortDirection, setSortDirection] = useState<number>(0);
  const [sortBy, setSortBy] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { t } = useTranslation('Services');
  const [columns, setColumns] = useState<TableColumn[]>([
    {
      key: 'name',
      name: t('Name'),
      sortable: true,
    },
    {
      key: 'totalInstancesCount',
      name: t('Total instances'),
      sortable: true,
      justify: 'right'
    },
    {
      key: 'activeLicenceCount',
      name: t('Active licences'),
      sortable: true,
      justify: 'right'
    },
    {
      key: 'activeUserCount',
      name: t('Active users'),
      sortable: true,
      justify: 'right'
    },
    {
      key: 'invitedUserCount',
      name: t('Invited users'),
      sortable: true,
      justify: 'right'
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);
  const location = useLocation();

  /**
   * Sets up search parameters for the page.
   */
  useEffect(() => {
    setupSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  /**
   * Sets up search parameters for the page.
   */
  useEffect(() => {
    // include statistics
    Api.GetServices(true)
      .then((list: Service[]) => setServices(list))
      .finally(() => setLoading(false));
  }, []);

  /**
   * Does all required setup and retrieves initial rows.
   */
  const setupSearchParams = (): void => {
    // Let's get search params
    const searchParams = new URLSearchParams(location.search);

    // Let's update query if we have it in the url
    if (searchParams.get('query')) {
      setQuery(searchParams.get('query')!);
    }

    // Let's update rows per page if we have it in the url
    if (searchParams.get('rowsPerPage')) {
      setPagination({ ...pagination, rowsPerPage: parseInt(searchParams.get('rowsPerPage')!) });
    }

    // Let's update sort direction and sort by
    if (searchParams.get('sortDirection') && searchParams.get('sortBy')) {
      setSortDirection(parseInt(searchParams.get('sortDirection')!));
      setSortBy(parseInt(searchParams.get('sortBy')!));
    }

    // Retrieve the rows
    getNewRows(
      searchParams.get('query') ? searchParams.get('query')! : query,
      searchParams.get('from') ? parseInt(searchParams.get('from')!) : pagination.from,
      searchParams.get('currentPage') ? parseInt(searchParams.get('currentPage')!) : pagination.currentPage,
      searchParams.get('rowsPerPage') ? parseInt(searchParams.get('rowsPerPage')!) : pagination.rowsPerPage,
      searchParams.get('sortBy') ? parseInt(searchParams.get('sortBy')!) : sortBy,
      searchParams.get('sortDirection') ? parseInt(searchParams.get('sortDirection')!) : sortDirection,
    );
  };

  /**
   * Does all required pre-requisites and updates the search parameters for the context.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param newSortBy - An enum value indicating the sort by column.
   * @param newSortDirection - An enum value indicating the sort direction.
   */
  const updateSearchParams = (search: string, from: number, page: number, newRowsPerPage: number, newSortBy?: number, newSortDirection?: number): void => {
    const params = new URLSearchParams();

    // Let's add query if it is set
    search ? params.append('query', search) : params.delete('query');

    // Let's add from
    from ? params.append('from', from.toString()) : params.delete('from');

    // Let's add current page
    page ? params.append('currentPage', page.toString()) : params.delete('currentPage');

    // Let's add rows per page
    newRowsPerPage ? params.append('rowsPerPage', newRowsPerPage.toString()) : params.delete('rowsPerPage');

    // Let's add sort by
    newSortBy !== undefined ? params.append('sortBy', newSortBy.toString()) : params.delete('sortBy');

    // Let's add rows per page
    newSortDirection !== undefined ? params.append('sortDirection', newSortDirection.toString()) : params.delete('sortDirection');

    // Let's append context
    navigate({ pathname: '/service', search: params.toString() });
  };

  /**
   * Retrieves new rows from the API.
   * @param search - Search string to use for the row retrieval.
   * @param from - From which row to retrieve the data.
   * @param page - Current page in the table.
   * @param newRowsPerPage - New amount of rows per page.
   * @param sortBy - An enum value indicating the sort by column.
   * @param sortDirection - An enum value indicating the sort direction.
   */
  const getNewRows = (search: string, from: number, page: number, newRowsPerPage?: number, newSortBy?: number, newSortDirection?: number) => {
    // Assign temporary rows per page based on the global variable
    const tmpRowsPerPage = newRowsPerPage ? newRowsPerPage : pagination.rowsPerPage;

    // Assign temp sort by and sort direction
    const tmpSortBy = newSortBy !== undefined ? newSortBy : sortBy;
    const tmpSortDirection = newSortDirection !== undefined ? newSortDirection : sortDirection;

    // Let's update search parameters
    updateSearchParams(search, from, page, tmpRowsPerPage, tmpSortBy, tmpSortDirection);
    assignServices(page * tmpRowsPerPage - tmpRowsPerPage + 1, page * tmpRowsPerPage, page, search, tmpRowsPerPage, tmpSortBy, tmpSortDirection);
  };

  /**
   * Assign retrieved rows and update pagination. (Client side)
   * @param services - All services.
   * @param from - From which row data was retrieved.
   * @param to - Till which row data was retrieved.
   * @param page - Current page in the table.
   * @param rowsPerPage - New rows per page that needs to be assigned.
   */
  const assignServices = (from: number, to: number, page: number, search: string, rowsPerPage: number, _sortBy?: number, _sortDirection?: number) => {
    // Let's pull out the new rows data
    const newRows = services
      .map((record: Service) => {
        return {
          id: record.id,
          name: record.name,
          activeLicenceCount: ServicesWithLicenses.some(x => x?.toLowerCase() == record.id.toLowerCase()) ? (record.activeLicenceCount ?? 0) : 'N/A',
          activeUserCount: record.activeUserCount ?? 0,
          invitedUserCount: record.invitedUserCount ?? 0,
          totalInstancesCount: record.totalInstancesCount ?? 0
        };
      })
      .filter((s) => !search || s.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
      .sort((a, b) => {
        if (_sortBy === 0) {
          return (_sortDirection ? -1 : 1) * (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0);
        } else if (_sortBy === 1) {
          return (_sortDirection ? -1 : 1) * (a.totalInstancesCount - b.totalInstancesCount);
        } else if (_sortBy === 2) {
          return (_sortDirection ? -1 : 1) * ((a.activeLicenceCount == 'N/A' ? -1 : Number(a.activeLicenceCount)) - (b.activeLicenceCount == 'N/A' ? -1 : Number(b.activeLicenceCount)));
        } else if (_sortBy === 3) {
          return (_sortDirection ? -1 : 1) * (a.activeUserCount - b.activeUserCount);
        } else if (_sortBy === 4) {
          return (_sortDirection ? -1 : 1) * (a.invitedUserCount - b.invitedUserCount);
        }
         else {
          return 0;
        }
      })
      .slice(from - 1, Math.min(to, services.length));

    // Let's update pagination
    setPagination({
      from: from < 0 ? 0 : from,
      to: to > services.length ? services.length : to,
      currentPage: page,
      total: services.length,
      rowsPerPage,
    });

    // Let's assign new rows
    setRows(newRows);
  };

  /**
   * Navigate to the next page.
   */
  const onNextPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage < pagination?.total!) {
      // Let's get new rows
      getNewRows(query, pagination?.from! + pagination?.rowsPerPage, pagination?.currentPage! + 1);
    }
  };

  /**
   * Navigate to the previous page.
   */
  const onPreviousPage = () => {
    // Let's check if we can navigate
    if (pagination?.currentPage! * pagination?.rowsPerPage - pagination?.rowsPerPage >= 0) {
      // Let's get new rows
      getNewRows(query, pagination?.from! - pagination?.rowsPerPage, pagination?.currentPage! - 1);
    }
  };

  /**
   * Updates rows per page and retrieves new data.
   * @param newRowsPerPage - New rows per page value set in the table.
   */
  const onRowsPerPageChange = (newRowsPerPage: number) => {
    // Let's retrieve fresh data
    getNewRows(query, 1, 1, newRowsPerPage);
  };

  /**
   * Navigates to the organization details page.
   * @param row - Row which was clicked in the table.
   */
  const onRowClick = (row: ServiceTableRow) => {
    navigate(`/service/${row.id}`);
  };

  /**
   * Searches the table based on the entered query.
   * @param searchQuery - Search query based on which to search for data.
   */
  const onSearchRows = (searchQuery: string) => {
    // Let's update the query
    setQuery(searchQuery);

    // Let's retrieve updated data
    getNewRows(searchQuery, 1, 1, pagination?.rowsPerPage);
  };

  /**
   * Applies sorting for the table.
   * @param key - Column key to which sorting should be applied.
   * @param direction - Direction in which to sort data.
   */
  const onApplySorting = (key: string, direction?: TableSortingDirection): void => {
    let sortBy = 0;
    let sortDirection = 0;
    
    if (!!key && !!direction){
      // Let's retrieve sort by and sort direction
      sortBy = columns.findIndex((column: TableColumn) => column.key === key);
      sortDirection = direction === 'asc' ? 0 : 1;
    }

    // Set sort by.
    setSortBy(sortBy);

    // Set sort direction
    setSortDirection(sortDirection);

    // Let's search
    getNewRows(query, pagination?.from!, pagination?.currentPage, pagination?.rowsPerPage, sortBy, sortDirection);
  };

  return (
    <StyledPageWidth $useMaxWidth={true} $maxWidth={1600}>
      <h1>Services</h1>
      <ServiceSearch onSearch={(query: string) => onSearchRows(query)} initialValue={new URLSearchParams(location.search).get('query')!} />
      <Table
        sortProps={sortProps}
        columns={columns}
        rows={rows}
        border={true}
        remoteOperations={true}
        pagination={pagination}
        onRowsPerPageChange={(newRowsPerPage: number) => onRowsPerPageChange(newRowsPerPage)}
        onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => onApplySorting(key, direction)}
        onNextPageClick={() => onNextPage()}
        onPreviousPageClick={() => onPreviousPage()}
        selectable={true}
        onSelectionChange={(row: ServiceTableRow) => onRowClick(row)}
        showLoadingIndicator={loading}
      />
    </StyledPageWidth>
  );
};

export default ServiceList;
